<template>
  <v-card class="scrollable-container mt-3 card-size pl-3" flat style="width: 100%;">
    <v-row class=" pl-2 px-6">
      <v-col class="mt-3" cols="6">
        <h3 class="page-title">CREATE ROUTE PLAN</h3>
        <h5 class="light-gray-text">Create route plan according to your requirements.</h5>
        <v-card-title>

        </v-card-title>
      </v-col>
      <v-col class="px-4 mt-5" cols="6">
        <v-row class="tool-bar-style">
          <v-btn class="red-text" size="x-large" variant="text" @click="go">
            <v-icon>mdi-file-document</v-icon>&nbsp;&nbsp;CREATE OUTLETS DATA
          </v-btn>
          <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
          <v-btn class="gray-text" size="x-large" variant="text" @click="go()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-card class="px-2 pt-2 mx-1 mb-3 leads-details-card" elevation="3">
      <h5 class="card-two-title px-3 mt-2 mb-4">CREATE ROUTE DETAILS</h5>
      <v-divider></v-divider>
      <!-- data form -->
      <v-row style="padding-left: 10px; padding-right: 10px;">
        <!-- left column -->
        <v-col cols="12" md="6" style=" margin-top: 10px; padding-right: 20px;">
          <v-card class="pt-2 card-height-fixed" flat>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-col cols="11">
                <v-text-field
                    v-model="routeName"
                    ref="routeNameField"
                    :rules="[v => isClearing || !!v || 'Route Name is required']"
                    class="text-field-margin text-black"
                    label="Route Name *"
                    persistent-placeholder
                    placeholder="Type name here"
                    variant="outlined"
                />


                <v-select
                    v-model="routeCategory"
                    ref="routeCategoryField"
                    :items="routeCategories"
                    :rules="routeCategoryRules"
                    clearable
                    class="text-field-margin text-black"
                    item-title="category_name"
                    item-value="category_name"
                    label="Route Category *"
                    placeholder="Select category"
                    required
                    variant="outlined"
                    @update:modelValue="fetchOutlets"
                ></v-select>
                <v-select
                    v-model="province"
                    ref="provinceField"
                    :items="provinces"
                    :loading="mDistrictLoading"
                    :rules="provinceRules"
                    label="Province *"
                    placeholder="Select province"
                    required
                    clearable
                    variant="outlined"
                    @update:modelValue="onProvinceChange"
                ></v-select>

                <v-select
                    v-model="district"
                    ref="districtField"
                    :items="districts"
                    :loading="mDistrictLoading"
                    :rules="districtRules"
                    label="District"
                    clearable
                    placeholder="Select district"
                    variant="outlined"
                    @update:modelValue="onDistrictChange"
                ></v-select>

                <v-select
                    v-model="dsd"
                    ref="dsdField"
                    :items="dsdList"
                    :loading="mDSDLoading"
                    :rules="dsdRules"
                    label="DSD"
                    clearable
                    placeholder="Select DSD"
                    variant="outlined"
                    @update:modelValue="onDsdChange"
                ></v-select>

                <v-select
                    v-model="gnd"
                    ref="gndField"
                    :items="gndList"
                    :rules="gndRules"
                    label="GND"
                    placeholder="Select GND"
                    variant="outlined"
                ></v-select>


                <v-date-input
                    v-model="assignDate"
                    ref="assignDateField"
                    :min="new Date().toISOString().split('T')[0]"
                    :rules="assignDateRules"
                    color="red"
                    label="Assign Date *"
                    max-width="580"
                    persistent-placeholder
                    placeholder="Select a Date"
                    required
                    variant="outlined"
                ></v-date-input>
              </v-col>
            </v-form>
          </v-card>
        </v-col>
        <!-- right column -->
        <v-col cols="12" md="6" style=" margin-top: 10px; padding-right: 20px;">
          <v-card class="pt-2 card-height-fixed" flat>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-col cols="11">
                <v-autocomplete
                    :items="filteredOutlet"
                    class="text-field-margin text-black"
                    item-title="outlet_name"
                    item-value="id"
                    label="Outlets *"
                    multiple
                    placeholder="Select outlets"
                    required
                    variant="outlined"

                >
                  <!-- eslint-disable-next-line vue/valid-v-slot -->
                  <template v-slot:item="{ item }">
                    <v-row style="height: 10vh;font-size: 12px ">
                      <v-col cols="12" md="2">
                        <v-switch v-model="item.selected" class="ml-4" color="blue"
                                  @change="handleSwitchChange(item)"></v-switch>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: 10px">
                        <div>{{ item.raw.outlet_name }}</div>
                        <div style="font-size: 10px; color: red">outlet num : {{ item.raw.id }}</div>
                      </v-col>
                      <!-- <v-col  cols="12" md="4" style="margin-top: 10px">
                        <v-chip
                            v-if="item.raw.outlet_status === 'ALLOWED'"
                            color="green"
                            style="height: 20px; width: 140px; font-size: 12px; background: #aee8b1"
                            variant="outlined"
                        >
                          ALLOWED
                          <v-icon class="justify-end ml-8">mdi-check</v-icon>
                        </v-chip>
                        <v-chip
                            v-if="item.raw.outlet_status === 'NOT ALLOWED'"
                            class="bg-red-lighten-4"
                            color="red"
                            style="height: 20px; width: 140px; font-size: 12px"
                            variant="outlined"
                        >
                          NOT ALLOWED
                          <v-icon class="justify-end ml-4">mdi-close</v-icon>
                        </v-chip>
                        <v-chip
                            v-if="item.raw.outlet_status === 'NEED APPROVAL'"
                            color="orange"
                            style="height: 20px; width: 140px; font-size: 12px; background: lightgoldenrodyellow"
                            variant="outlined"
                        >
                          NEED APPROVAL
                          <v-icon class="justify-end ml-4">mdi-help-circle-outline</v-icon>
                        </v-chip>
                      </v-col> -->
                    </v-row>
                  </template>
                </v-autocomplete>

                <v-autocomplete
                    v-model="assignAgent"
                    :items="filteredAgent"
                    ref="assignAgentField"
                    clearable
                    :rules="assignAgentRules"
                    class="text-field-margin text-black"
                    item-title="name"
                    item-value="id"
                    label="Assign Agent *"
                    persistent-placeholder
                    placeholder="Select agent"
                    required
                    style="font-size: 10px"
                    variant="outlined"
                >
                  <template v-slot:item="{ props, item }">
                    <v-list-item v-bind="props">
                      <div style=" font-size: 12px; padding-bottom: 5px; margin-bottom: 5px">
                        <div style="color: red">Reg num: {{ item.raw.regNum }}</div>
                      </div>
                    </v-list-item>
                  </template>
                </v-autocomplete>


                <!--              <v-autocomplete-->
                <!--                  v-model="assignAgent"-->
                <!--                  :items="agentDataOptions"-->
                <!--                  class="text-field-margin text-black"-->
                <!--                  item-title="name"-->
                <!--                  item-value="id"-->
                <!--                  label="Assign Agent"-->
                <!--                  persistent-placeholder-->
                <!--                  placeholder="Select agent"-->
                <!--                  style="font-size: 10px"-->
                <!--                  variant="outlined"-->
                <!--              >-->
                <!--&lt;!&ndash;                 eslint-disable-next-line vue/valid-v-slot&ndash;&gt;-->
                <!--&lt;!&ndash;                <template v-slot:item=" { item } " >&ndash;&gt;-->
                <!--&lt;!&ndash;                  <div style="margin-left: 10px;font-size: 12px; padding: 5px; margin-bottom: 5px">&ndash;&gt;-->
                <!--&lt;!&ndash;                    <div>{{ item.raw.name }}</div>&ndash;&gt;-->
                <!--&lt;!&ndash;                    <div style="color: red">Reg num: {{ item.raw.number }}</div>&ndash;&gt;-->
                <!--&lt;!&ndash;                  </div>&ndash;&gt;-->
                <!--&lt;!&ndash;                </template>&ndash;&gt;-->
                <!--                <template v-slot:item="{ props, item }">-->
                <!--                <v-list-item-->
                <!--                    v-bind="props"-->
                <!--                >-->
                <!--                  <div style="margin-left: 10px;font-size: 12px; padding: 5px; margin-bottom: 5px">-->
                <!--                  <div></div>-->
                <!--                  <div style="color: red">Reg num: {{ item.raw.registration_num }}</div>-->
                <!--                </div>-->
                <!--                </v-list-item>-->
                <!--                </template>-->
                <!--              </v-autocomplete>-->

                <v-divider></v-divider>
                <h5 class="px-3 py-3">SELECT ROUTE WITH OUTLET LIST</h5>
                <h6 class="px-3 pb-3 light-gray-text">The following table shows all the outlets related to the
                  above-defined route.</h6>
                <v-card>
                  <v-card style="width: 100%;">
                    <!-- search -->
                    <v-row>
                      <v-col cols="12" md="10">
                        <v-text-field
                            v-model="search"
                            class="text-black"
                            label="Search"
                            persistent-placeholder
                            placeholder="Search outlet"
                            style="margin-top: 18px; margin-left: 60px"
                            variant="outlined"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-divider></v-divider>
                  <!-- table -->
                  <v-card style="width: 100%; padding: 30px;">
                    <v-data-table
                        :headers="headers"
                        :items="filteredOutlets"
                        class="data-table"
                        style="font-size: 10px; overflow-y: auto; max-height: 100%;"
                    >
                      <template v-slot:[`item.action`]="{ item }">
                        <v-btn class="bg-red-lighten-3" icon size="x-small" @click="handleMinesSwitchChange(item)">
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-card>
              </v-col>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- footer -->

      <v-divider class="divider-two-style"></v-divider>
      <v-row class="margin-bottom-fix mt-2 mr-8">
        <v-spacer></v-spacer>
        <v-btn :disabled="loading" class="red-background-text mr-3" variant="outlined" @click="submitForm"> CREATE
          ROUTE
        </v-btn>
        <v-btn class="gray-background-text" variant="outlined" @click="cancelPageOutlet()">CANCEL</v-btn>
      </v-row>
    </v-card>
  </v-card>

  <!-- Snackbar -->
  <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="3000">
    {{ snackbarMessage }}
  </v-snackbar>

  <!-- Loading Screen -->
  <!--  <v-dialog v-model="loading" hide-overlay persistent width="300">-->
  <!--    <v-card color="grey" dark>-->
  <!--      <v-card-text>-->
  <!--        <span>Loading...</span>-->
  <!--        <v-progress-circular color="white" indeterminate></v-progress-circular>-->
  <!--      </v-card-text>-->
  <!--    </v-card>-->
  <!--  </v-dialog>-->

  <v-overlay v-model="loading" :scrim="false" class="centered-overlay">
    <v-progress-circular
        color="green"
        indeterminate
        size="64"
    ></v-progress-circular>
  </v-overlay>


</template>

<script>
/* eslint-disable */
import axios from 'axios';
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isClearing: false,
      valid: false,
      loading: false,
      snackbar: false,
      selectedOutlet: [],
      selectedOutletData: null,
      assignDate: null,
      menu: false,
      menuPosition: {x: 0, y: 0},
      routeName: '',
      routeCategory: '',
      province: '',
      district: '',
      dsd: '',
      gnd: '',
      outlets: [],
      assignAgent: '',
      search: '',
      routeCategories: [],
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
      mDistrictLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      outletOptions: [],
      filteredOutlet: [],
      filteredAgent: [],
      agentDataOptions: [],
      headers: [
        {text: 'Actions', value: 'action', sortable: false, title: 'ACTION'},
        {text: 'Category', value: 'route_category', title: 'Category'},
        {text: 'Outlet Name', value: 'outlet_name', title: 'Outlet Name'},
        {text: 'Address', value: 'address', title: 'Address'},
      ],
      routes: [],
      excludedCategories: [],
      categoryIds: [],

      // routeNameRules: [(v) => !!v || 'Route Name is required'],
      // routeCategoryRules: [(v) => !!v || 'Route Category is required'],
      // provinceRules: [(v) => !!v || 'Province is required'],
      // districtRules: [(v) => !!v || 'District is required'],
      // dsdRules: [(v) => !!v || 'DSD is required'],
      // gndRules: [(v) => !!v || 'GND is required'],
      // assignDateRules: [(v) => !!v || 'Assign Date is required'],
      // assignAgentRules: [(v) => !!v || 'Assign Agent is required'],
      routeCategoryRules: [v => this.isClearing || !!v || 'Route Category is required'],
      provinceRules: [v => this.isClearing || !!v || 'Province is required'],
      districtRules: [v => this.isClearing || !!v || 'District is required'],
      dsdRules: [v => this.isClearing || !!v || 'DSD is required'],
      gndRules: [v => this.isClearing || !!v || 'GND is required'],
      assignDateRules: [v => this.isClearing || !!v || 'Assign Date is required'],
      assignAgentRules: [v => this.isClearing || !!v || 'Assign Agent is required'],

      snackbarMessage: '',
      snackbarColor: '',

    };
  },

  methods: {

    onProvinceChange(value) {
      this.district = null; // Clear district when province changes
      this.dsd = null;      // Clear DSD when province changes
      this.gnd = null;      // Clear GND when province changes

      this.isClearing = true;
      this.assignAgent = '';
      this.selectedOutlet = [];

      // Reset validation states for all fields
      const fields = [
        this.$refs.assignAgentField,
      ];

      fields.forEach(field => {
        if (field && typeof field.resetValidation === 'function') {
          field.resetValidation();
        }
      });
      this.filterOutlets({ province: value });
      this.filterAgents({ province: value });
      this.getAllDistrictAccProv(value);
    },
    onDistrictChange(value) {
      this.dsd = null;      // Clear DSD when district changes
      this.gnd = null;      // Clear GND when district changes
      this.isClearing = true;
      this.assignAgent = '';
      this.selectedOutlet = [];

      // Reset validation states for all fields
      const fields = [
        this.$refs.assignAgentField,
      ];

      fields.forEach(field => {
        if (field && typeof field.resetValidation === 'function') {
          field.resetValidation();
        }
      });
      this.filterOutlets({ district: value });
      this.filterAgents({ district: value });
      this.getAllDsd(value);

    },
    onDsdChange(value) {
      this.gnd = null;      // Clear GND when DSD changes
      this.filterOutlets({ dsd: value });
      this.filterAgents({ dsd: value });
      this.getAllGnd(value);
    },

    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'province'}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mDistrictLoading = false;
            }
          })
          .catch(() => {
            this.mDistrictLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'district', value: province}, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });

    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'dsd', value: district}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;

      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'gnd', value: dsd}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGNDLoading = false;
            }
          })
          .catch(() => {
            this.mGNDLoading = false;
          });
    },
    async submitForm() {
      // List of fields with their labels and values
      const fieldsToValidate = [
        {value: this.routeName, label: 'Route Name', rules: [v => !!v || 'Route Name is required']},
        {value: this.routeCategory, label: 'Route Category', rules: [v => !!v || 'Route Category is required']},
        {value: this.province, label: 'Province', rules: [v => !!v || 'Province is required']},
        {value: this.assignDate, label: 'Assign Date', rules: [v => !!v || 'Assign Date is required']},
        {value: this.assignAgent, label: 'Assign Agent', rules: [v => !!v || 'Assign Agent is required']},
        { value: this.selectedOutlet, label: 'Selected Outlet', rules: [v => v.length > 0 || 'At least one outlet must be selected'] },
      ];

      // Loop through each field and validate
      for (const field of fieldsToValidate) {
        for (const rule of field.rules) {
          const errorMessage = rule(field.value);
          if (errorMessage !== true) {
            // Show error in snackbar for the first invalid field
            this.snackbarMessage = `${field.label}: ${errorMessage}`;
            this.snackbarColor = 'error';
            this.snackbar = true;
            return; // Stop validation on the first error
          }
        }
      }

      // If all fields are valid, proceed with form submission
      try {
        await this.createRoute();
        this.snackbarMessage = 'Form submitted successfully!';
        this.snackbarColor = 'success';
        this.snackbar = true;

        // Optionally reload or redirect
        window.location.reload();
      } catch (error) {
        // Handle submission error
        this.snackbarMessage = 'Failed to submit the form. Please try again.';
        this.snackbarColor = 'error';
        this.snackbar = true;
      }
    },

    async createRoute() {
      if (!this.validateForm()) {
        return;
      }
      this.loading = true;
      const userId = localStorage.getItem('userId');
      const routeData = {
        userId: userId,
        route_name: this.routeName,
        route_category: this.routeCategory,
        province: this.province,
        district: this.district,
        dsd: this.dsd,
        gnd: this.gnd,
        assign_date: new Date(new Date(this.assignDate).setDate(new Date(this.assignDate).getDate() + 1)).toISOString().split('T')[0],
        visited_date: new Date(new Date(this.assignDate).setDate(new Date(this.assignDate).getDate() + 1)).toISOString().split('T')[0],
        assigner_id: userId,
        agent_id: this.assignAgent,
        selected_outlets: this.selectedOutlet,
      };

      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/save`, routeData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        // this.selectedOutletData = null,
        //     this.assignDate = null,
        //     this.routeName = '',
        //     this.routeCategory = '',
        //     this.province = '',
        //     this.district = '',
        //     this.dsd = '',
        //     this.gnd = '',
        //     this.outlets = [],
        //     this.assignAgent = '',
        this.snackbarMessage = 'Route created successfully!';
        this.snackbarColor = 'success';
        this.snackbar = true;
        console.log('route save', response);
      } catch (error) {
        this.snackbarMessage = 'Failed to create route';
        this.snackbarColor = 'error';
        this.snackbar = true;
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    validateForm() {
      if (!this.$refs.form.validate()) {
        this.snackbarMessage = 'Please fill in all required fields!';
        this.snackbarColor = 'error';
        this.snackbar = true;
        return false;
      }
      return true;
    },

    handleSwitchChange(item) {
      if (window.ResizeObserver) {
        const ro = new ResizeObserver(() => {
        });
        ro.observe(document.body);
      }
      if (item.selected) {
        this.selectedOutletData = item;

        // Find the corresponding route category
        const category = this.routeCategories.find(cat => cat.category_name === this.routeCategory);

        // Push the selected outlet with route_category_id into the selectedOutlet array
        this.selectedOutlet.push({
          outlet_id: item.raw.id,
          route_category: this.routeCategory,
          outlet_name: item.raw.outlet_name,
          address: item.raw.outlet_address,
          status: item.raw.outlet_status,
          visited_date: new Date(this.assignDate).toISOString().split('T')[0],
          route_category_id: category ? category.id : null // Assign category id or null if not found
        });

        console.log('Selected Outlets:', this.selectedOutlet);
      } else {
        this.selectedOutletData = null;

        // Use outlet_id to filter out the deselected item
        this.selectedOutlet = this.selectedOutlet.filter(outlet => outlet.outlet_id !== item.raw.id);

        console.log('Updated Selected Outlets after removal:', this.selectedOutlet);
      }
    },
    handleMinesSwitchChange(item) {
      if (window.ResizeObserver) {
        const ro = new ResizeObserver(() => {
        });
        ro.observe(document.body);
      }
      // Find the index of the item in the selectedOutlet array
      const selectedOutletIndex = this.selectedOutlet.findIndex(outlet => outlet.id === item.id);

      if (selectedOutletIndex !== -1) {
        // Remove the item from selectedOutlet array
        this.selectedOutlet.splice(selectedOutletIndex, 1);

        // Turn off the corresponding switch in outletOptions
        const outletToDeselect = this.outletOptions.find(outlet => outlet.id === item.id);
        if (outletToDeselect) {
          outletToDeselect.selected = false;
        }

        this.outletOptions.forEach(outlet => {
          if (outlet.category === item.category) {
            outlet.selected = false;
          }
        });

        // If the category of the item is in the excludedCategories array, remove it
        const isCategoryExcluded = this.excludedCategories.includes(item.category);
        if (isCategoryExcluded) {
          this.excludedCategories = this.excludedCategories.filter(category => category !== item.category);
        }
      }
    },

    fetchMainCategories() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/routes/categories`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.routeCategories = response.data;
            console.log("API Response category list:", response.data);
          })
          .catch(error => {
            console.error('Error fetching main categories:', error);
          });

    },
    fetchOutlets() {
      console.log('Category selected:', this.routeCategory);

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      // Determine the API endpoint based on the routeCategory
      const apiEndpoint = this.routeCategory === 'COLLECTION'
          ? `${process.env.VUE_APP_API_BASE_URL}/api/outlet/view/CollectionManagement`
          : `${process.env.VUE_APP_API_BASE_URL}/api/outlet/view`;

      axios.post(apiEndpoint, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
          .then(response => {
            // Filter the response data to include only outlets with outlet_status = 'ALLOWED'
            const allowedOutlets = response.data.filter(outlet => outlet.outlet_status === 'ALLOWED');

            // Assign filtered data to outletOptions
            this.outletOptions = allowedOutlets;

            console.log("Filtered API Response:", allowedOutlets);
          })
          .catch(error => {
            console.error('Error fetching outlets:', error);
          });
    },

    filterOutlets(filters) {
      // Log the current filter inputs for debugging
      console.log("Filters applied:", filters);

      // Apply filtering based on the provided filters
      this.filteredOutlet = this.outletOptions.filter(outlet => {
        return (!filters.province || outlet.province === filters.province) &&
            (!filters.district || outlet.district === filters.district) &&
            (!filters.dsd || outlet.dsd === filters.dsd);
      });
    },

    filterAgents(filters) {
      console.log("Agent Filters applied:", filters);

      this.filteredAgent = this.agentDataOptions.filter(agent => {
        return (!filters.province || agent.province === filters.province) &&
            (!filters.district || agent.district === filters.district) &&
            (!filters.dsd || agent.dsd === filters.dsd);
      });
    },

    fetchAllAgents() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/agentUser`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.excludedCategories = response.data.data;
            console.log("API Response:", response.data.data);

            // Check if response.data is an array
            if (Array.isArray(this.excludedCategories)) {
              this.excludedCategories.forEach(agent => {
                this.agentDataOptions.push({
                  id: agent.id,
                  name: agent.name,
                  regNum: agent.registration_num,
                  district: agent.district,
                  province: agent.province,
                  dsd: agent.dsd,
                });
              });
            } else {
              // If it's a single object
              this.agentDataOptions.push({
                id: this.excludedCategories.id,
                name: this.excludedCategories.name,
                regNum: this.excludedCategories.registration_num,
              });
            }

            console.log("API Response ex:", this.excludedCategories);
            console.log('Agent Options:', this.agentDataOptions);
          })
          .catch(error => {
            console.error('Error fetching main categories:', error);
          });
    },

    go() {
      this.$router.push('/create-outlet');
    },
    // cancel() {
    //   this.$router.push('/dashboard');
    // },

    cancelPageOutlet() {
      // Set the clearing flag to bypass validation
      this.isClearing = true;

      // Reset all form data
      this.routeName = '';
      this.routeCategory = '';
      this.province = '';
      this.district = '';
      this.dsd = '';
      this.gnd = '';
      this.assignDate = null;
      this.assignAgent = '';
      this.selectedOutlet = [];

      // Reset validation states for all fields
      const fields = [
        this.$refs.routeNameField,
        this.$refs.routeCategoryField,
        this.$refs.provinceField,
        this.$refs.districtField,
        this.$refs.dsdField,
        this.$refs.gndField,
        this.$refs.assignAgentField,
      ];

      fields.forEach(field => {
        if (field && typeof field.resetValidation === 'function') {
          field.resetValidation();
        }
      });

      // Allow a brief delay to ensure the fields are reset
      setTimeout(() => {
        this.isClearing = false; // Re-enable validation
      }, 50);
    },
  },

  mounted() {
    this.getAllProvinces();
    this.fetchOutlets();
    this.fetchMainCategories();
    this.fetchAllAgents();
  },
  watch: {
    menu(newValue) {
      console.log('Menu state changed:', newValue);
    },
    'province'(newValue) {
      this.getAllDistrictAccProv(newValue);
    },
    'district'(newValue) {
      this.getAllDsd(newValue);
    },
    'dsd'(newValue) {
      this.getAllGnd(newValue);
    },
    // 'routeCategory'(newValue) {
    //   this.selectedOutlet.push(newValue);
    // },


  },
  created() {

  },
  computed: {
    filteredOutlets() {
      return this.selectedOutlet.filter((sale) => {
        if (!sale) return false;
        console.log(sale);

        // Check if the sale matches the search query
        // Return true if both conditions are met
        return !this.search ||
            (sale.route_category && sale.route_category.toLowerCase().includes(this.search.toLowerCase())) ||
            (sale.outlet_name && sale.outlet_name && sale.outlet_name.toLowerCase().includes(this.search.toLowerCase())) ||
            (sale.address && sale.address.toLowerCase().includes(this.search.toLowerCase())) ||
            (sale.sales_amount && sale.sales_amount.toString().includes(this.search));
      });
    }


  },

};
/* eslint-enable */
</script>


<style scoped>


.card-height-fixed {
  height: 100%;
  overflow: auto;
  max-height: 63vh;
}

.centered-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.leads-details-card {
  height: fit-content;
  padding-bottom: 20px;
  margin-bottom: 10px;
  overflow-x: hidden;
  max-height: 80vh;
  font-size: 1rem;
  color: black;
}


.red-text {
  color: #b00d0d;
}

.card-two-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: #757575;
}

.centered-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollable-container {
  overflow-y: auto; /* Enables vertical scrolling */
  max-height: 80vh; /* Adjust as per your layout requirements */
}
</style>