<template>
  <v-card style="height: 100%; width: 100%">

    <div style="display: flex; justify-content: center;">
      <v-card class="pa-3 mt-5" style="width: 93%;">
      <v-icon class="ml-8" >mdi-view-dashboard
      </v-icon>
      <v-label class="ml-10">UPDATE ROUTE PLAN DETAILS</v-label>
    </v-card>
    </div>

    <div style="display: flex; justify-content: center;">
      <v-card class="pa-2 mt-5 mb-5" style="width: 93%; height: 100%; margin-bottom: 10px; max-height: 77vh; overflow-y: auto">
        <v-row class="first_row ma-0 pa-0 ">
          <v-col class="secondC textCenter" cols="12">

            <div class="image card-h4-title">
              <v-img :src="imageSrc"></v-img>
            </div>

            <div class="mt-2 ml-6">
              <v-row>
                <h3 class="dataCard">Agent name: </h3>
                <h3 class="dataCard text-red">&nbsp; {{ agentName }}</h3>
              </v-row>
              <v-row class="mt-6">
                <h5>Mobile : &nbsp; {{mobileNumber }}</h5>
              </v-row>
              <v-row class="mt-6">
                <v-chip class="mt-3 pa-3" color="green" size="small" style="width: 200px" text-color="white"
                        variant="outlined">
                  <v-icon color="green">mdi-check-circle-outline</v-icon>
                  ROUTE STATES - <span></span><span
                    style="font-size: 15px; margin-left: 4px">{{ routeStates }}</span></v-chip>

                <v-divider class="mx-3" vertical></v-divider>
              </v-row>

            </div>
            <v-spacer></v-spacer>
            <div class="justify-right align-right ">
              <v-row>
              <v-btn class="editB red-light-text" color="red" icon rounded size="small" variant="outlined" width="200" @click="createRoute"> UPDATE ROUTE DATA
              </v-btn>
              <v-btn class="bg-grey-lighten-2 ml-3" color="black" icon  rounded size="small" variant="outlined" width="150" @click="cancell">CANCEL
              </v-btn>
              </v-row>
            </div>
          </v-col>
        </v-row>
    <v-divider></v-divider>

    <v-row class="second_row ">
      <v-col cols="12" md="6">
        <v-row class="textfield">
          <v-text-field
              v-model="routeName"
              label="Route Name"
              variant="outlined"
          ></v-text-field>
          <v-select
              v-model="routeCategory"
              :items="routeCategories"
              class="text-field-margin text-black"
              item-title="category_name"
              label="Route Category"
              persistent-placeholder
              placeholder="Select category"
              readonly
              variant="outlined"
          ></v-select>
          <v-text-field
              v-model="province"
              label="Province"
              readonly
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="district"
              label="District"
              readonly
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="dsd"
              label="DSD"
              readonly
              variant="outlined"
          ></v-text-field>
          <v-text-field
              v-model="gnd"
              label="GND"
              readonly
              variant="outlined"
          ></v-text-field>
        </v-row>
      </v-col>

      <v-col cols="12" md="6">
        <v-row class="textfield2">
          <v-date-input
              v-model="assignDate"
              :disabled="canEditSelectField"
              color="red"
              label="Assign Date"
              max-width="590"
              persistent-placeholder
              variant="outlined"
          ></v-date-input>
          <v-card-title>
            <v-row align="center" justify="space-between">
              <v-col cols="auto">
                <h4>OUTLET LIST</h4>
              </v-col>
              <v-col cols="auto">
                <v-btn  v-if="OverallStatus !== 'Visited'" class="bg-red mt-3 mr-2" @click="opensendReminderDialog() ">
                  SEND REMINDER
                </v-btn>
              </v-col>
            </v-row>
            <v-card-subtitle>
              <h6>The following table shows all the outlets related to the above-mentioned route</h6>
            </v-card-subtitle>
          </v-card-title>

          <v-card class="tableCard" style=" overflow-y: auto; height: 100%; max-height: 34vh">
            <v-col cols="12" style="height: 100%;">
              <v-data-table
                  :headers="headers"
                  :items="outletData"
                  style="height: 100%; overflow-y: auto; font-size: 10px"

              >
                <template v-slot:[`item.route_category`]="{ item }">
                  <div style="color: red">{{ item.route_category }}</div>
                </template>

                <template v-slot:[`item.outletName`]="{ item }">
                  <div>
                    <div>{{ item.outletName }}</div>
                    <div style="color: red">Outlet num: {{ item.id }}</div>
                  </div>
                </template>

                <template v-slot:[`item.visited_date`]="{ item }">
                  <v-btn v-if="item.status === 'Not Visited'" color="red" height="20px" icon rounded="lg"
                         style="font-size: x-small" width="80px" @click="reasonBox(item)">REASON
                  </v-btn>
                  <v-card-text v-if="item.status === 'Visited'" class="red--text">{{ item.visited_date }}</v-card-text>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <div>
                    <div>
                      <v-chip v-if="item.status === 'Visited'" color="green"
                              style="height: 20px; width:100px; font-size: x-small" variant="outlined">
                        VISITED
                        <v-icon class="justify-end ml-7">mdi-check</v-icon>
                      </v-chip>

                      <v-chip v-if="item.status === 'Not Visited'" color="red"
                              style="height: 20px; width:100px; font-size: x-small" variant="outlined">
                        NOT VISITED
                        <v-icon class="justify-end ml-3">mdi-close</v-icon>
                      </v-chip>
                    </div>
                  </div>
                </template>

              </v-data-table>
            </v-col>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
      </v-card>
    </div>
    <v-row>

    </v-row>

    <!--    reminder dialog-->
    <v-dialog v-model="reminderDialog" class="solid-dialog d-flex" height="80vh" width="40vw">
      <v-card class="pa-6">
        <v-row dense style="background-color: #d9d9d9;">
          <v-col cols="12" md="10">
            <v-card-title class="headline" style="font-size: 17px">SEND REMINDER</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-btn class="mt-2 mr-2" icon size="x-small" @click="reminderDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-4 mb-4"></v-divider>
        <v-row style="padding: 10px">
          <v-col>
            <v-text-field
                v-model="routeName"
                label="Route Name"
                readonly
                style="color: #BB0000;"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="outletName"
                label="Outlet Name"
                readonly
                style="color: #BB0000;"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="outletAddress"
                label="Outlet Address"
                readonly
                style="color: #BB0000;"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="admin.userName"
                label="Assignee"
                readonly
                style="color: #BB0000;"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="admin.mobileNum"
                label="Mobile"
                readonly
                style="color: #BB0000;"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="admin.email"
                label="Email"
                readonly
                style="color: #BB0000;"
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="note"
                label="Note"
                style="color: #BB0000;"
                variant="outlined"
            ></v-text-field>

          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row>
          <v-col class="d-flex justify-end " cols="12" md="12" @click="reminderDialog = false">
            <v-btn class="mt-3 bg-red-lighten-3" color="red" size="large" variant="outlined" @click="reminderSend">
              SEND
            </v-btn>
            <v-btn class="mt-3 ml-3 bg-grey-darken-3" color="gray" size="large" variant="outlined"
                   @click="reminderDialog = false">
              CANCEL
            </v-btn>
          </v-col>
        </v-row>

      </v-card>

    </v-dialog>


    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" max-height="500" max-width="500" persistent>
      <v-card class="pa-5">
        <v-row dense style="background-color: #d9d9d9;">
          <v-col cols="12" md="10">
            <v-card-title class="headline">DELETE OUTLET LIST</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-btn class="mt-2 mr-2" icon size="x-small" @click="deleteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-4"></v-divider>
        <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this outlet list?</v-card-text>
        <v-card-text class="font-weight-medium">
          This outlet list record will be deleted immediately. Once deleted, it can no longer be used for any purpose. but you can add a new outlet list under the above title.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="toggleCard">DELETE</v-btn>
          <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteDialog = false">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="reasonDialog" max-height="690" max-width="400" persistent>
      <v-card class="pa-1">
        <v-row class="rowClr mb-1 mt-3 mx-3" dense style="background-color: #a09e9e">
          <v-col cols="12" md="9">
            <v-card-title>AGENT MESSAGE</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end pt-3 pr-3" cols="12" md="3">
            <v-btn icon size="x-small" @click="reasonDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-3"></v-divider>
        <v-card>
          <v-card-text>
            <v-textarea
                v-model="notVisitedReason"
                class="mb-0 mt-3 mx-3"
                density="compact"
                label="Not Visited Reason"
                placeholder="Not Visited yet"
                readonly
                style="height: auto; width: auto"
                variant="outlined"
            ></v-textarea>
            <v-divider></v-divider>
            <v-card-actions class="mr-1">
              <v-spacer></v-spacer>
              <v-btn class="bg-grey-lighten-2" variant="outlined" @click="reasonDialog = false">CANCEL</v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>

      </v-card>
    </v-dialog>

    <v-dialog v-model="sendReminderDialog" max-height="690" max-width="400" persistent>
      <v-card class="pa-1">
        <v-row class="rowClr mb-1 mt-3 mx-3" dense  style="background-color: #a09e9e">
          <v-col cols="12" md="9">
            <v-card-title>SEND REMINDER</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end pt-3 pr-3" cols="12" md="3">
            <v-btn icon size="x-small" @click="sendReminderDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-3"></v-divider>
        <v-card>
          <v-card-text>
            <v-text-field
                v-model="routeName"
                class="mb-0 mt-3 mx-3"
                density="compact"
                label="Route Name"
                placeholder="Route Name"
                required
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="assdineeDetails.name"
                class="mb-0 mx-3"
                density="compact"
                label="Assignee"
                placeholder="Assignee"
                required
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="agentDetails.mobile_number[0].mobile_number"
                :rules="[v => !!v || 'Outlet Address is required']"
                class="mb-0 mx-3"
                density="compact"
                label="Mobile"
                placeholder="Mobile"
                required
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="agentDetails.email"
                :rules="[v => !!v || 'Email is required']"
                class="mb-0 mx-3"
                density="compact"
                label="Email"
                placeholder="Type Email Here"
                required
                variant="outlined"
            ></v-text-field>
            <v-textarea
                v-model="senderNote"
                :rules="[v => !!v || 'Note is required']"
                class="mb-0 mx-3"
                density="compact"
                label="Note"
                placeholder="Type Note Here"
                required
                rows="3"
                variant="outlined"
            ></v-textarea>
            <v-divider></v-divider>
            <v-card-actions class="mr-1">
              <v-spacer></v-spacer>
              <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="sendReminder">SEND</v-btn>&nbsp;
              &nbsp;
              <v-btn class="bg-grey-lighten-2" variant="outlined" @click="sendReminderDialog = false">CANCEL</v-btn>
            </v-card-actions>


          </v-card-text>
        </v-card>

      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackAlert.snackbar"
        :color="snackAlert.snackColor"
        timeout="5000"
    >
      {{ snackAlert.snackMessage }}
    </v-snackbar>

    <v-snackbar
        v-model="snackbar"
        :color="snackbarColor"
        :timeout="3000"
        location="top-right"
        outlined
    >
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
            icon
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay v-model="loading" :scrim="false" class="centered-overlay">
      <v-progress-circular
          color="green"
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>

  </v-card>
</template>

<script>
// import {EventBus} from "@/plugins/event-bus";
import axios from "axios";
import {mapGetters} from "vuex";
import {EventBus} from "@/plugins/event-bus";

export default {
  name: "Route_Plan.vue",
  data() {
    return {
      snackAlert: {
        snackbar: false,
        snackColor: '',
        snackMessage: ''
      },
      notVisitedReason: "",
      reasonDialog: false,
      sendReminderDialog: false,
      OverallStatus: '',
      snackbarMessage: '',
      snackbarColor: '',
      snackbarReminder : false,
      loading :false,
      snackbar: false,
      deleteDialog: false,
      itemToDelete: null,
      closeDialog: false,
      editDialog: false,
      reminderDialog: false,
      showFirstCard: true,
      showFirstIcon: true,
      fetchData: '',
      routeStates: '',
      agentName: '',
      routeName: '',
      routeCategory: '',
      province: '',
      district: '',
      dsd: '',
      gnd: '',
      assignDate: new Date(),
      visitedDate: '',
      userImage: '',
      mobileNumber: '',
      outletName: '',
      outletAddress: '',
      email: '',
      agentId: '',
      note: 'You should visit this place as soon as possible and report the information',
      routeCategories: [],
      headers: [
        {text: 'Category', value: 'route_category.category_name', title: 'Category'},
        {text: 'Outlet Name', value: 'outlet_name', title: 'Outlet Name'},
        {text: 'Outlet Address', value: 'address', title: 'Outlet Address'},
        {text: 'States', value: 'status', title: 'States'},
        {text: 'Visit Date', value: 'visited_date', title: 'Visit Date'},
      ],
      routeProducts: [],
      outletData: [],
      admin: {
        userName: '',
        mobileNum: '',
        email: '',
      },

      agentDetails: "",
      assdineeDetails: "",
      senderNote: '',
      reason:'',

    };
  },

  methods: {
    reasonBox(item){
      this.reasonDialog = true;
      this.notVisitedReason = item.my_route_outlet.
          not_visited_reason;

      console.log('reason item', item);
    },

    sendReminder() {
      // Show loading snackbar
      this.snackAlert.snackbar = true;
      this.snackAlert.snackColor = 'info';
      this.snackAlert.snackMessage = 'Sending reminder...';

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("routeName", this.routeName);
      formData.append("assignee", this.agentName);
      formData.append("email", this.agentDetails.email);
      formData.append("senderNote", this.senderNote);

      axios.post(`/api/sendReminder`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(() => {
            // Show success snackbar
            this.snackAlert.snackColor = 'success';
            this.snackAlert.snackMessage = 'Reminder sent successfully.';

            // Close dialog
            this.sendReminderDialog = false;
            console.log('Reminder sent successfully');
          })
          .catch(error => {
            // Show error snackbar
            this.snackAlert.snackColor = 'error';
            this.snackAlert.snackMessage = 'Failed to send reminder. Please try again.';
            console.error('Error sending reminder:', error);
          });
    },

    opensendReminderDialog() {
      this.sendReminderDialog = true;
    },
    reminderBox(item) {
      this.reminderDialog = true;

      const userName = localStorage.getItem('userName');
      const email = localStorage.getItem('email');
      const mobile = localStorage.getItem('mobileNumbers');


      this.outletName = item.outlet_name;
      this.outletAddress = item.address;
      this.admin.email = email;
      this.admin.mobileNum = mobile;
      this.admin.userName = userName;

    },
    snackbarM() {
      this.snackbar = false;
      EventBus.emit('routeRecodeManagementButtonClick2');
    },
    cancell() {
      EventBus.emit('routeRecodeManagementButtonClick2');
      window.location.reload();
    },
    toggleCard() {
      this.showFirstCard = !this.showFirstCard;
      this.showFirstIcon = !this.showFirstIcon;
      this.deleteDialog = false;

      const ids = this.outletData.map(outlet => outlet.id);
      console.log('ids array', ids);

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/route/outlets/delete`, {ids, userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.error('Error deleting route:', error);
          });
    },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;

    },
    deleteItem() {
      // Implement your delete item logic here
      this.sales = this.sales.filter(sale => sale !== this.itemToDelete);
      this.deleteDialog = false;
      this.itemToDelete = null;
    },
    exportPDF() {
      //  functionality to export table data as PDF
    },

    closeD() {
      this.viewDialog = false;
      this.editDialog = false;
    },
    reminderSend() {
      this.loading = true;

      const userId = localStorage.getItem('userId');

      const routeData = {
        route_name: this.routeName,
        outlet_name: this.outletName,
        outlet_address: this.outletAddress,
        assignee: userId,
        mobile_number: this.mobileNumber,
        email: this.email,
        note: this.note,
      };

      axios.post('/api/route/reminder', {routeData, userId})
          .then(response => {
            console.log('reminder send successfully:', response.data);
            this.snackbarMessage = 'reminder send successfully';
            this.snackbarColor = 'success';
            this.snackbar = true;
            this.loading = false;
          })
          .catch(error => {
            console.error('Error adding reminder:', error);
            this.snackbarMessage = 'reminder send Unsuccessfully';
            this.snackbarColor = 'error';
            this.snackbar = true;
          })

    },

    fetchOutletRecodeData() {

      this.OverallStatus = this.sharedDataRoutes.overallStatus;
      console.log('eeeeeeeee:', this.OverallStatus);

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataRoutes.id;

      console.log('Shared Data:', this.sharedDataRoutes);
      console.log('fk', id);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
          .then(response => {
            console.log("API Response all:", response.data.route);
            if (response.data.route) {

              this.routeProducts = Array.isArray(response.data.route) ? response.data.route : [response.data.route];
              console.log("this.outletProducts:", this.routeProducts);

              if (this.routeProducts.length > 0) {
                const route = this.routeProducts[0];
                console.log("data",route);
                this.agentId = route.agent.id;
                this.agentName = route.agent.name;
                this.routeName = route.route_name;
                this.routeCategory = route.route_category;
                this.province = route.province;
                this.district = route.district;
                this.dsd = route.dsd;
                this.gnd = route.gnd;
                this.assignDate = route.assign_date ? new Date(route.assign_date) : null;
                this.visitedDate = route.visited_date;
                this.routeStates = route.route_status;
                this.userImage = route.agent.user_image;

                this.mobileNumber = route.agent.mobile_number.length > 0 ? route.agent.mobile_number[0].mobile_number : null;

                this.admin.userName = route.assigner.name;
                this.admin.email = route.assigner.email;
                // this.admin.mobileNum = route.assigner.mobile_number.length > 0 ? route.assigner.mobile_number[0].mobile_number : null;

                console.log("sahan", this.mobileNumber)

              }
              console.log('main', this.agentName)

            } else {
              this.routeProducts = [];
            }
          })
          .catch(error => {
            console.log(error);
            this.routeProducts = [];
          });
    },
    async createRoute() {
      this.loading = true;

      const userId = localStorage.getItem('userId');

      const id = this.sharedDataRoutes.id;
      console.log('Shared Data:', this.sharedDataRoutes);
      console.log('fk', id);

      const routeData = {
        route_name: this.routeName,
        route_category: this.routeCategory,
        assign_date: new Date(this.assignDate).toISOString().split('T')[0],
        assigner_id: userId,
        agent_id: this.agentId,
        province: this.province,
        district: this.district,
        dsd: this.dsd,
        gnd: this.gnd,
        userId: userId,
        id: id,


      };
      try {
        const token = localStorage.getItem('token');
        console.log("route is ", routeData);
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/save`, routeData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });
        console.log(response);
        this.snackbarMessage = 'Successfully Update Route';
        this.snackbarColor = 'success';
        this.snackbar = true;
        this.loading = false;
        setTimeout(() => {
          window.location.reload();
        }, 2000);

      } catch (error) {
        this.snackbarMessage = 'Unsuccessfully create route';
        this.snackbarColor = 'error';
        this.snackbar = true;
      }finally {
        this.loading = false;
        setTimeout(() => {
          EventBus.emit('routeRecodeManagementButtonClick2');
        }, 3000);
      }
    },

    fetchOutletData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = this.sharedDataRoutes.id;

      console.log('Shared Data:', this.sharedDataRoutes);
      console.log('fk', id);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      })
          .then(response => {

            const data = response.data;
            this.agentDetails = data.route.agent;
            this.assdineeDetails = data.route.assigner;

            console.log('fk Assignee', this.assdineeDetails);
            console.log('fk data', this.agentDetails);

            if (data && data.route && data.route.selected_outlets) {
              this.outletData = data.route.selected_outlets;
              console.log("API Response outlet list:", this.outletData);

              // Extracting notVisitedReason from the first outlet's my_route_outlet
              const firstOutlet = this.outletData[0]; // Adjust index if necessary
              if (firstOutlet && firstOutlet.my_route_outlet) {
                this.notVisitedReason = firstOutlet.my_route_outlet.not_visited_reason || "No reason provided";
              } else {
                this.notVisitedReason = "No reason provided";
              }

            } else {
              console.log("Selected outlets data not found in the response");
            }
          })
          .catch(error => {
            console.log(error);
          });
    },
    fetchMainCategories() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/routes/categories`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.routeCategories = response.data;
            console.log("API Response category list:", response.data);
          })
          .catch(error => {
            console.error('Error fetching main categories:', error);
          });

    },

  },


  computed: {
    ...mapGetters(['sharedDataRoutes']),
    imageSrc() {
      return `${process.env.VUE_APP_API_BASE_URL}/SFA/public/${this.userImage}`;
    },
    canEditSelectField() {
      return this.outletData.every(outlet => {
        return outlet.status === "Visited" && outlet.date && outlet.date instanceof Date;
      });
    }

  },
  created() {
    this.fetchOutletRecodeData();

    // EventBus.on('viewAgentDetails',(data) =>{
    //   console.log('sahan', data.Id);
    // });
  },

  // beforeUnmount() {
  //   EventBus.off('viewAgentDetails', fetchData =>{
  //     console.log('pppppp', fetchData);
  //   });
  // },
  mounted() {
    this.fetchOutletRecodeData();
    this.fetchOutletData();
    this.fetchMainCategories();
  },

};
</script>


<style scoped>


.red--text {
  color: red;
}


.pdfC {
  display: flex;
  margin-top: 40px;
}

.editB {
  display: flex;
  margin-right: 10px;
}

.image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden; /* Ensure the image does not overflow the div */
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
}

.rounded-circle {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire div */
  display: block;
}


.textCenter {
  text-align: center;
}


.textfield {
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-left: 50px;
  margin-top: 20px;
}

.textfield2 {
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-left: 50px;
  margin-top: 20px;
}

.action {
  margin-left: 10px;
  display: flex;
  justify-content: right;
}

.first_row {
  display: flex;
  justify-content: flex-end;

}

.second_row {
  margin-right: 50px;
}

.secondC {
  display: flex;

}


.tableCard {
  height: 31vh;
}

.centered-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.centered-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.red-text {
  color: #b00d0d;
}
.red-light-text {
  background-color: #f1cccc;
  color: #b00d0d;
}
.red-white-text {
  background-color: #b00d0d;
  color: white;
}
.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}
.green-text {
  color: #057b0a;
  background-color: #aee8b1;
}
.image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}

.dataCard {
  display: flex;
  justify-content: left;
}
.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: black;
}
.first_row {
  display: flex;
  justify-content: flex-end;

}

</style>
