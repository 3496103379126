<template>
  <v-overlay v-model="centerLoading" :scrim="false" class="centered-overlay">
    <v-progress-circular
      color="green"
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>

  <v-card class="mt-3 card-size pl-3" flat>
    <v-row class="pl-2 pr-6">
      <v-col class="mt-3" cols="6">
        <h3 class="page-title">USER ROLES</h3>
        <h5 class="light-gray-text">User roles & their permissions registered in the system.</h5>
        <v-card-title>
          
        </v-card-title>
      </v-col>
      <v-col class="px-4 mt-5" cols="6">
        <v-row class="tool-bar-style">
            <v-btn class="red-text" size="x-large" variant="text"  @click="createUser()">
              <v-icon>mdi-account-plus</v-icon>&nbsp;&nbsp;CREATE USER
            </v-btn>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-card class="px-2 pt-2 mx-1 mb-6 leads-details-card" elevation="3">
      <h5 class="card-h4-title px-3 mt-2 mb-4">USERS ROLES
        <div class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-btn class="red-light-text mr-3" variant="outlined" @click="exportPDF">
            <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          
          <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
            <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </div>
      </h5>
      <v-divider></v-divider>  
      <v-row class="px-3 pt-9">
        <v-col cols="4">
          <v-select
              v-model="userCatagory"
              :items="userCatagaryItems"
              clearable
              item-title="name"
              item-value="name"
              label="User Catagory"
              multiple
              placeholder="User Type"
              variant="outlined"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
              v-model="userRoleTypeFilter"
              :items="userTypes"
              clearable
              item-title="name"
              item-value="name"
              label="User Role Type"
              multiple
              placeholder="User Type"
              variant="outlined"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
              v-model="search"
              append-inner-icon="mdi-magnify"
              clearable
              hide-details
              label="Search"
              placeholder="Search"
              variant="outlined"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-3 pt-3">
        <v-card class="mt-3 mx-3 card-size"  flat >
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            class="data-table dataTable"
            item-value="name"
          >
            <template v-slot:[`item.roleType`]="{ item }">
              <span class="red-text">{{ item.roleType }}</span>
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <span class="description-text">{{ item.description }}</span>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
              <span>{{ new Date(item.created_at).toISOString().split('T')[0] }}</span>
            </template>


            <template v-slot:[`item.action`]="{ item }">
              <v-row>
                <v-btn
                    v-if="permissions.write"
                class="mr-3 bg-blue-lighten-5 viewbtn"
                      color="green"
                    variant="outlined"
                    @click="openEditDialog(item)"
                >
                  <v-icon>mdi-check-circle</v-icon>&nbsp;  Edit
                </v-btn>
                <v-btn
                    v-if="permissions.delete"
                    class="bg-red-lighten-5"
                    color="red"
                    icon
                    rounded
                    size="small"
                    variant="outlined"
                    @click="confirmDelete(item)"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </v-row>
            </template>
           
          </v-data-table> 
          <!-- <v-row>
            <v-col cols="12" class="text-right">
              <v-pagination
                v-model="page"
                :length="totalPages"
                @input="fetchProducts"
              ></v-pagination>
            </v-col>
          </v-row> -->
        </v-card>         
      </v-row>
    </v-card>
    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteUserListDialog" max-height="500" max-width="500" persistent>
      <v-card class="pa-1">
        <v-row class="rowClr ma-1" dense>
          <v-col cols="12" md="9">
            <v-card-title class="headline">DELETE USER ROLE</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end pt-3 pr-3" cols="12" md="3">
            <v-btn icon size="x-small" @click="deleteUserListDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-divider class="mt-3"></v-divider>

        <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this user role ?</v-card-text>
        <v-card-text class="textspace">
          This user role will be deleted immediately. Once deleted, it can no longer be used in any purpose.
        </v-card-text>
        <v-divider class="mb-3"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>&nbsp; &nbsp;
          <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteUserListDialog = false">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="edit_user_role_dialog" fullscreen>
      <v-card class="card-size">
        <v-toolbar class="pr-5 mb-5" color="white" dense>
          <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" dark variant="elevated" @click="edit_user_role_dialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-card class="pa-3 mx-6">
            <v-icon>mdi-view-dashboard
            </v-icon>
            <v-label class="ml-6">UPDATE USER ROLE</v-label>
            </v-card>
            <v-card class="pa-3 mx-6 mt-3">
              <v-row class="first_row ma-0 pa-0 ">
                <v-col class="secondC textCenter" cols="12" >
                <v-spacer></v-spacer>
                <div class="justify-right align-right ml-6">
                  <v-btn class="bg-red-lighten-4" color="red" size="large" variant="outlined" @click="updateUserRole()">UPDATE USER ROLE</v-btn>&nbsp; &nbsp;
                  <v-btn class="bg-grey-lighten-2" size="large" variant="outlined" @click="edit_user_role_dialog = false">CANCEL</v-btn>
                </div>
                </v-col>
              </v-row>
              <v-divider class="mt-3"></v-divider>
              <v-row class="row-width-full align-center">
                <v-col cols="6">
                  <v-row class="rowClrT mx-3 mb-3" dense>
                    <v-col cols="1">
                      <v-card-text><v-icon color="orange" size="x-large">mdi-alert</v-icon></v-card-text>
                    </v-col>
                    <v-col cols="11">
                      <v-card-text>These user roles help you grant permissions and assign related functions to users in the system.</v-card-text>
                    </v-col>
                  </v-row>


                  <v-row class="mb-3 mt-6 mx-0">
                    <v-col cols="4">
                      <p style="font-size: 13px;"><b>SELECT USER CATEGORY</b></p>
                    </v-col>

                    <v-col cols="4">
                      <v-checkbox
                        v-model="selectedUser"
                        :value="'Web User'"
                        color="red"
                        hide-details
                        label="WEB USER"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="4">
                      <v-checkbox
                        v-model="selectedUser"
                        :value="'Mobile User'"
                        color="red"
                        hide-details
                        label="MOBILE USER"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                <v-row class="mb-3 mt-6 mx-0">
                  <v-col cols="12">
                    <v-text-field
                      v-model="roleForm.roleType"
                      :items="userTypes"
                      label="Role type *"
                      placeholder="Select user role type"
                      required
                      variant="outlined"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mb-3 mt-3 mx-3">
                    <v-textarea
                      v-model="roleForm.description"
                      label="Role Description *"
                      placeholder="Type user description here"
                      required
                      rows="2"
                      variant="outlined"
                    ></v-textarea>
                </v-row>
              </v-col>
             
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>

  

   <!-- Snackbar to show notifications -->
   <v-snackbar
      v-model="snackAlert.snackbar"
      :color="snackAlert.snackColor"
      :right="snackAlert.snackBarRight"
      :top="snackAlert.snackBarTop"
      timeout="3000"
    >
      {{ snackAlert.snackMessage }}
      
    </v-snackbar>
  </v-card>
</template>
    
<script>
  import axios from 'axios';
  import jsPDF from 'jspdf';
  import 'jspdf-autotable';
  import * as XLSX from "xlsx";
  import {getPermissions} from "@/plugins/permissions";
  export default {
    name: 'UserRoleList',
    data() {
      return {
        centerLoading:false,
        search: '',
        headers: [
          { text: 'USER CATEGORY', value: 'category', title: 'USER CATEGORY' },
          { text: 'Role Type', value: 'role_type', title: 'Role Type' },
          { text: 'Creation Date', value: 'created_at', title: 'Creation Date' },
          { text: 'Description', value: 'description', title: 'Description' },
          // { text: 'Permission Level', value: 'permissionLevel', title: 'Permission Level' },
          { text: 'Action', value: 'action', sortable: false, title: 'Action' }
        ],
        userCatagory:'',
        userCatagaryItems:['Web User','Mobile User'],
        userTypesItem:[],
        userRoleTypeFilter:'',

        isEditMode: false,
        roleForm: {
          id: null,
          roleType: '',
          description: '',
          permissions: [],
          dashboard:[]
        },
        items: [],
        selectedUser:"",

        relatedValue: null,

         // Pagination
        itemsPerPage: 10,
        page: 1,
        totalPages: 0,

        //dialog status
        deleteUserListDialog: false,
        edit_user_role_dialog: false,
        // selectedItem: null,
        roleToDelete: null,

        userRoles: [],
        userTypes: [],
        selectedItem: {
          id: null,
          user_role_id: null,
          description: '',
          dashboard: [],
        },
        deleteItemId: null,

        permissionsItems: [],
        permissions: {},

        snackAlert: {
          snackbar: false, // Snackbar visibility
          snackColor: "", // Snackbar color
          snackMessage: "", // Snackbar message
          snackBarRight: false, // Snackbar position right
          snackBarTop: true,  // Snackbar position top
        },
      };
    },
    mounted() {
      const dashboardId = 4;
      this.permissions = getPermissions(dashboardId);
      console.log("Permissions:", this.permissions);

      this.getAllUserRoles();
      this.getDashboardPermissions();
    },
    created() {
      this.fetchUserRoles();
    },
    computed: {
      filteredItems() {
        // Ensure search query is a string and convert to lowercase
        const query = this.search ? this.search.toLowerCase() : '';

        // Handle user category and role filters
        const selectedCategories = this.userCatagory || [];
        const selectedRoles = this.userRoleTypeFilter || [];

        return this.items.filter((item) => {
          // Match the search query
          const matchesQuery = query
            ? Object.keys(item).some((key) =>
                String(item[key] || '').toLowerCase().includes(query)
              )
            : true;

          // Match the selected user category
          const matchesCategory =
            selectedCategories.length === 0 ||
            selectedCategories.includes(item.category);

          // Match the selected user role
          const matchesRole =
            selectedRoles.length === 0 ||
            selectedRoles.includes(item.role_type);

          // Return true only if all conditions are met
          return matchesQuery && matchesCategory && matchesRole;
        });
      }
    },


    methods: {

      showSnackbar(message, color) {
        this.snackAlert.snackbar = true;
        this.snackAlert.snackMessage = message;
        this.snackAlert.snackColor = color;
      },

      exportExcel() {
        // Map filteredItems to prepare table data with clear headers
        const tableData = this.filteredItems.map((item) => ({
          'User Category': item.category || 'N/A', // Add default value for empty fields
          'User Role': item.role_type || 'N/A',
          'Creation Date': new Date(item.created_at).toISOString().split('T')[0], // Format the date
          'Description': item.description || 'N/A',
        }));

        // Create a worksheet from the table data
        const worksheet = XLSX.utils.json_to_sheet(tableData);

        // Adjust column width for better readability
        const columnWidths = [
          { wch: 20 }, // Width for 'User Category'
          { wch: 20 }, // Width for 'User Role'
          { wch: 15 }, // Width for 'Creation Date'
          { wch: 30 }, // Width for 'Description'
        ];
        worksheet['!cols'] = columnWidths;

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'User Role');

        // Save the workbook as an Excel file
        XLSX.writeFile(workbook, 'user_role_analysis.xlsx');
      },


      exportPDF() {
          const doc = new jsPDF();

          // Set the title for the document with enhanced styling
          doc.setFontSize(18);
          doc.setFont("helvetica", "bold");
          doc.text('User Role Analysis', 14, 20);

          // Subtitle or description
          doc.setFontSize(12);
          doc.setFont("helvetica", "normal");
          doc.text('This report provides an overview of user roles and their details.', 14, 28);

          // Prepare the data for the table
          const tableData = this.filteredItems.map((item, index) => [
            index + 1, // Serial number
            item.category || '-', // User category
            item.role_type || '-', // User role
            new Date(item.created_at).toISOString().split('T')[0], // Creation date
            item.description || '-', // Description
          ]);

          // Define the columns for the table
          const tableColumns = [
            '#', 
            'User Category', 
            'User Role', 
            'Creation Date', 
            'Description'
          ];

          // AutoTable plugin to create a table with enhanced styling
          doc.autoTable({
            startY: 35, // Start position for the table
            head: [tableColumns],
            body: tableData,
            theme: 'grid',
            headStyles: {
              fillColor: [100, 149, 237], // Cornflower blue
              textColor: [255, 255, 255], // White text
              fontSize: 10,
              halign: 'center',
              fontStyle: 'bold',
            },
            bodyStyles: {
              fontSize: 9,
              textColor: [0, 0, 0],
              halign: 'left',
              valign: 'middle',
            },
            alternateRowStyles: {
              fillColor: [240, 248, 255], // Alice blue for alternate rows
            },
            columnStyles: {
              0: { cellWidth: 10, halign: 'center' }, // Serial number column
              1: { cellWidth: 40 }, // User category column
              2: { cellWidth: 40 }, // User role column
              3: { cellWidth: 30, halign: 'center' }, // Creation date column
              4: { cellWidth: 60 }, // Description column
            },
            margin: { top: 35 }, // Margin for the table
          });

          // Footer with generation date
          const pageHeight = doc.internal.pageSize.height; // Get page height
          doc.setFontSize(10);
          doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, pageHeight - 10);

          // Save the PDF with a specific file name
          doc.save('user_role_analysis.pdf');
        },


      getDashboardPermissions() {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        axios.post(`/api/getDashboard?userId=${userId}`, 
          {}, 
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the Authorization header with the Bearer token
            }
          })
          .then(response => {
            const dashboards = response.data.data;
            this.permissionsItems = dashboards.map(dashboard => ({
              id: dashboard.id,
              premission_dashboard: dashboard.premission_dashboard,
              isOn: false,
              permissions: { read: false, write: false, delete: false },
            }));
            console.log('Dashboard permissions:', this.permissionsItems);
          })
          .catch(error => {
            console.error('Error fetching dashboard permissions:', error);
          });
      },

      getAllUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token'); 

      axios.post('/api/getUserRolesType', {
          userId: userId
      }, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      })
      .then(response => {
          let usrRole = response.data.data;
          this.userTypes = usrRole.map(item => {
              return {
                  id: item.id,
                  name: item.role_type
              };
          });
          console.log('User role types:', this.userTypes);
      })
      .catch(error => {
          console.error('Error fetching user roles:', error);
      });
    },
    
      async fetchUserRoles() {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token'); 
        try {
          const response = await axios.post('/api/allCreatedUserRoles', {
              userId: userId
          }, {
              headers: {
                  'Authorization': `Bearer ${token}`
              }
          });
          if (response.data.success === "Get Dashboard successfully") {
            const roles = response.data.data;
            console.log('Roles data:', roles); // Log the roles data
            

            // this.items = roles.map(role => ({
            //   roleType: role.userrole ? role.userrole.role_type : 'N/A',
            //   creationDate: new Date(role.created_at).toLocaleDateString(),
            //   description: role.description || 'No description',
            //   // permissionLevel: this.formatPermissionLevel(role.dashboard)
            //   dashboard: role.dashboard || [],
            //   id: role.id,
            // }));

            this.items = roles;
             // Sort the data by created_at in descending order (newest first)
            // this.items = roles.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map(role => ({
            //   roleType: role.userrole ? role.userrole.role_type : 'N/A',
            //   creationDate: new Date(role.created_at).toLocaleDateString(),
            //   description: role.description || 'No description',
            //   // permissionLevel: this.formatPermissionLevel(role.dashboard)
            //   dashboard: role.dashboard || [],
            //   id: role.id,
            // }));

            this.totalPages = Math.ceil(this.items.length / this.itemsPerPage);
          }
        } catch (error) {
          console.error('Error fetching user roles:', error);
          // Optionally handle errors or show user feedback
        }
      },

      formatPermissionLevel(dashboard) {
        if (dashboard.length === 0) return 'No Permissions';

        const permissions = dashboard.map(d => {
          let perms = [];
          if (d.read_premission) perms.push('Read');
          if (d.write_premission) perms.push('Write');
          if (d.delete_premission) perms.push('Delete');
          return perms.join(' / ');
        });

        return `Permissions for: ${permissions.join(', ')}`;
      },

      backPageRoute(){
        this.$router.back();
      },
     
      createUser(){
        this.$router.push({ path: '/user-registration' });
      },

      handleSwitchChange(permission) {
        if (!permission.isOn) {
          // Reset the permissions when the switch is turned off
          permission.permissions.read = false;
          permission.permissions.write = false;
          permission.permissions.delete = false;
        } else {
          // Optionally, you can enable default permissions here if needed
          // For example, you might want to enable read by default when the main switch is turned on
          permission.permissions.read = true;
        }
      },

      // handlePermissionChange(item, permissionType) {
      //   // You can perform additional logic here if needed
      //   // For example, you can ensure that if the 'delete' permission is enabled, 'read' and 'write' must also be enabled.
      //   if (permissionType === 'delete' && item.permissions.delete) {
      //     item.permissions.read = true;
      //     item.permissions.write = true;
      //   }

      //   // If both 'read' and 'write' are disabled, ensure 'delete' is also disabled
      //   if (!item.permissions.read && !item.permissions.write) {
      //     item.permissions.delete = false;
      //   }
      // },

      handlePermissionChange(permission, permissionType) {
        // Check if 'delete' permission is being modified
        if (permissionType === 'delete') {
          if (permission.permissions.delete) {
            // Enable 'read' and 'write' if 'delete' is enabled
            permission.permissions.read = true;
            permission.permissions.write = true;
          } else {
            // Ensure 'read' and 'write' are disabled if 'delete' is turned off
            if (!permission.permissions.read && !permission.permissions.write) {
              permission.permissions.delete = false;
            }
          }
        }

        // Check if 'read' or 'write' permissions are being modified
        if (permissionType === 'read' || permissionType === 'write') {
          if (!permission.permissions.read && !permission.permissions.write) {
            // Disable 'delete' if both 'read' and 'write' are turned off
            permission.permissions.delete = false;
          }
        }
      },


      getPermissionLevel(dashboard) {
        let hasRead = false;
        let hasWrite = false;
        let hasDelete = false;

        dashboard.forEach(dash => {
          if (dash.read_premission) hasRead = true;
          if (dash.write_premission) hasWrite = true;
          if (dash.delete_premission) hasDelete = true;
        });

        if (hasRead && hasWrite && hasDelete) {
          return 'All Permissions';
        } else if (hasRead && hasWrite) {
          return 'Permissions for Read and Write';
        } else if (hasRead) {
          return 'Permission for Read';
        } else if (hasWrite) {
          return 'Permission for Write';
        } else if (hasDelete) {
          return 'Permission for Delete';
        } else {
          return 'No Permissions';
        }
      },

      getPermissionColor(dashboard) {
        let hasRead = false;
        let hasWrite = false;
        let hasDelete = false;

        dashboard.forEach(dash => {
          if (dash.read_premission) hasRead = true;
          if (dash.write_premission) hasWrite = true;
          if (dash.delete_premission) hasDelete = true;
        });

        if (hasRead && hasWrite && hasDelete) {
          return 'red';
        } else if (hasRead && hasWrite) {
          return 'green';
        } else if (hasRead || hasWrite || hasDelete) {
          return 'blue';
        } else {
          return 'grey';
        }
      },
      getPrimaryPermission(dashboard) {
        let permission = this.getPermissionLevel(dashboard);
        if (permission === 'All Permissions') {
          return 'All Permissions';
        } else if (permission.startsWith('Permissions for')) {
          return permission.split(' ')[2];
        } else {
          return permission;
        }
      },
      getSecondaryPermission(dashboard) {
        let permission = this.getPermissionLevel(dashboard);
        if (permission === 'All Permissions') {
          return 'Read, Write, Delete';
        } else if (permission.startsWith('Permissions for')) {
          return permission.split(' ').slice(-2).join(' ');
        } else {
          return null;
        }
      },

    confirmDelete(item) {
      this.selectedItem = item;
      this.deleteItemId = item.id;
      this.deleteUserListDialog = true;
    },

    async deleteItem() {
      this.centerLoading = true;
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const userRoleId = this.deleteItemId;

      // console.log('Deleting item with ID:', userDiscriptionId);
      console.log('Deleting item with token:', token);
      console.log('Deleting item with userId:', userId);

      try {
        const response = await axios.post(`/api/user/delete-role`, {
          userId,
          userRoleId
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        console.log('API Response:', response.data); // Log API response

        if (response.data.success) {
          // Close the dialog and reset deletion state
          this.deleteUserListDialog = false;
          this.deleteItemId = null;
          this.centerLoading = false;
          this.showSnackbar("User role deleted successfully!", "success");

          // Refresh the data table
          await this.fetchUserRoles();
        } else {
          console.error('Failed to delete the user role:', response.data);
          this.showSnackbar("Failed to delete user role.", "error");
        }
      } catch (error) {
        console.error('Error deleting user role:', error);
        this.deleteUserListDialog = false;
        this.showSnackbar("Error deleting user role.", "error");
      }
      this.centerLoading = false;
    },
   
  
    // openEditDialog(item) {
    //   this.isEditMode = true;
    //   this.selectedItem = item;
    //   // this.roleForm = {
    //   //     id: item.id,
    //   //     roleType: item.roleType,
    //   //     description: item.description,
    //   //   };
    //   this.edit_user_role_dialog = true;
    //   console.log('Editing item:', item);
    // },

    // openEditDialog(item) {
    //   this.isEditMode = true;
    //   this.selectedItem = item;

    //   // Assuming you have a form object to hold the edit data
    //   this.roleForm = {
    //     id: item.id,
    //     roleType: item.roleType,
    //     description: item.description,
    //     permissions: item.dashboard.map(dashboardItem => ({
    //       dashboardId: dashboardItem.dashboard_id,
    //       permissionDashboard: dashboardItem.userdashboard.premission_dashboard,
    //       readPermission: dashboardItem.read_premission,
    //       writePermission: dashboardItem.write_premission,
    //       deletePermission: dashboardItem.delete_premission,
    //     })),
    //   };

    //   this.edit_user_role_dialog = true;
    //   console.log('Editing item:', this.roleForm);
    // },

    openEditDialog(item) {
      this.isEditMode = true;
      this.selectedItem = item;

      this.edit_user_role_dialog = true;

      this.roleForm.roleType = item.role_type;
      this.roleForm.description = item.description;
      this.selectedUser = item.category;
      console.log('Editing item:', item);
      console.log('Role Form:', this.roleForm);
    },

    updateUserRole() {

      this.centerLoading = true;
      const userId = parseInt(localStorage.getItem('userId'), 10);
      const token = localStorage.getItem('token');

      // Map the permissionsItems to the required format
      // const dashboards = this.roleForm.permissions
      //   .filter(item => item.isOn) // Only include dashboards where the switch is ON
      //   .map(item => ({
      //     dashboardId: item.id, // Assuming item.id represents the dashboard ID
      //     readPremission: item.permissions.read,
      //     writePremission: item.permissions.write,
      //     deletePremission: item.permissions.delete,
      //   }));

      
      const payload = {
        userId,
        userRoleId: this.selectedItem.id,
        // userRoleId: this.selectedItem.user_role_id,
        // userRoleId: this.selectedItem.user_role_id || this.roleForm.userRoleId,
        userRoleType: this.roleForm.roleType,
        userRoleDescription: this.roleForm.description, // Correctly bind description
        userRoleCategory:this.selectedUser,
        // accessDashboard: dashboards, // Use the merged dashboards array
        
      };
      // Print the payload as a formatted JSON string
      console.log('Payload to be sent:', JSON.stringify(payload, null, 2));

      // Convert the payload to a JSON string for sending in the request
      const jsonPayload = JSON.stringify(payload);

      // Log the payload being sent
      console.log('Payload being sent:', jsonPayload);

      axios.post('/api/user/edit-role', payload, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        console.log('User role created:', response.data);
        this.fetchUserRoles();
        this.showSnackbar("User role permission Updated successfully!", "success");
        this.edit_user_role_dialog = false;
        this.centerLoading = false;
        // Handle successful creation (e.g., display a success message, reset form)
      })
      .catch(error => {
        console.error('Error creating user role:', error);
        // Handle error (e.g., display an error message)
        this.centerLoading = false;
      });
    },
    }
  };
</script>

<style scoped>
.centered-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .v-card-title {
    font-weight: bold;
  }
  .page-title {
    font-weight: bold;
  }
  .light-gray-text{
    color: #a09e9e !important;
  }
  .tool-bar-style {
    display: flex;
    justify-content: end;
  }
  .card-h4-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    color: black;
  }
  .red-text {
    color: #b00d0d;
  }
  .divider-vertical{
    color: #3c3b3b !important;
  }
  .red-light-text {
    background-color: #f1cccc;
    color: #b00d0d;
  }
  .red-white-text {
    background-color: #b00d0d;
    color: white;
  }
  .green-light-text {
    background-color: #aee8b1;
    color: #057b0a;
  }
  .green-text {
    color: #057b0a;
    background-color: #aee8b1;
  }
  .gray-icon-button {
    color: gray;
    background-color: #e2e2e2;
  }
  .data-table {
    width: 100%;
  }
  .description-text {
    display: block;
    width: 350px; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
  .card-size{
    width: 100% !important;
    padding: 0;
    margin-bottom: 0;
  }
  /* .leads-details-card{
    height: 100%;
  } */

  .rowClr{
    background-color: #d9d9d9;
  }
  .rowClrT{
    background-color: #d9d9d9;
    margin-top: -60px;
  }
  .textspace{
    margin-top: -20px;
  }
  .multiline-chip {
    display: flex;
    flex-direction: column; /* Ensure the divs stack vertically */
    white-space: normal; /* Allow text to wrap normally */
    padding: 8px; /* Adjust padding for a better look */
    line-height: 1.2em; /* Adjust line height for spacing between rows */
  }
  .secondary-permission {
    color: black; /* Set the secondary permission text color to black */
  }
  .secondary-permission-wrapper {
    text-align: center;
  }

  .first_row{
    display: flex;
    justify-content: flex-end;

  }

  .secondC{
    display: flex;

  }

  .textCenter {
    text-align: center;
  }
  .leads-details-card{
    /* height: 100%; */
    overflow-y: scroll;
    margin-bottom: 20px;
    max-height: 550px;
    overflow-x: hidden !important;
  }
  /* .card-size{ */
    /* width: 100%;
    height: 100%;
    padding: 0;
    margin-bottom: 0;
  } */
  .divider-two-style{
    margin-top: -5px;
  }
  .margin-bottomn-fix{
    margin-top: 0px;
  }
  .align-center {
    align-items: center;
  }
  .card-hight-fixed{
    height: 100%;
    overflow: auto;
    max-height: 54vh;
  }
  .row-width-full{
    width: 100%;
  }
  .viewbtn{
      font-size: 12px;
    }
    :deep(.dataTable) th, td{
      border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
      flex: 1;
      text-align: center;
    }

    :deep(.dataTable) td, td{
      border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
      flex: 1;
    }

    :deep(.dataTable) {
      border: white;
      overflow-x: hidden !important;
    }

    :deep(.dataTable )  tr,  th:last-child {
      border-right: none; /* Removes right border from the last cell in each row and header */
    }
    :deep(.dataTable )  tr,  td:last-child {
      border-right: none; /* Removes right border from the last cell in each row and header */
    }
    .margin-up-down{
      margin-top: -40px;
      margin-bottom: -45px;
    }
    .margin-up-down-two{
      margin-top: -10px;
      margin-bottom: -40px;
    }
</style>
    