<template>
  <v-card class="mt-3 main-card pl-3" variant="flat">
    <v-row class="pl-2 pr-6">

      <v-col class="mt-3" cols="6">
        <h3 class="page-title">OUTLET LISTS</h3>
        <h5 class="light-gray-text">Analyse the created outlet list using a variety of parameters.</h5>
        <v-card-title>

        </v-card-title>
      </v-col>

      <v-col class="px-4 mt-5" cols="6">
        <v-row class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
          <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>

    </v-row>

    <v-card class="px-2 pt-2 mb-3 pb-2 mx-1 leads-details-card" elevation="3">
      <h5 class="card-h4-title px-3 mt-2 mb-4">OUTLET DATA ANALYSIS
        <div class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-btn class="red-light-text mr-3" variant="outlined" @click="exportPDF">
            <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>

          <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
            <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </div>
      </h5>

      <v-divider class="pb-6 w-100"></v-divider>

      <v-data-table
          :headers="headers"
          :items="filteredRoutes"
          :items-per-page="5"
          :search="search"
          class="data-table"
          style="padding-left: 25px; padding-right: 10px;  padding-bottom: 30px; height: fit-content; margin-bottom: 20px "
      >
        <template v-slot:[`item.outlet_status`]="{ item }">
          <div style="padding: 10px">
            <div style="padding-bottom: 3px">
              <v-chip v-if="item.outlet_status === 'ALLOWED'" class="green-light-text"
                      label style="height: 20px; width:140px; font-size: 12px;">
                ALLOWED
                <v-icon class="justify-end ml-12">mdi-check</v-icon>
              </v-chip>

              <v-chip v-if="item.outlet_status === 'NOT ALLOWED'" class="bg-red-lighten-5" color="red" label
                      style="height: 20px; width:140px; font-size: 12px;  ">
                NOT ALLOWED
                <v-icon class="justify-end ml-6">mdi-close</v-icon>
              </v-chip>

              <v-chip v-if="item.outlet_status === 'NEED APPROVAL'" class="bg-yellow-lighten-5" color="orange" label
                      style="height: 20px; width:140px; font-size: 12px;">
                NEED APPROVAL
                <v-icon class="justify-end ml-4">mdi-help-circle-outline</v-icon>
              </v-chip>
            </div>


            <div>
              <v-btn style="height: 30px; width:140px; font-size: 9px; background: darkgrey"
                     @click="updateStates(item)">UPDATE STATUS
              </v-btn>
            </div>
          </div>
        </template>


        <template v-slot:top>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                  v-model="selectedOutlet"
                  :items="outletTypes"
                  append-inner-icon="mdi-close"
                  chips
                  item-title="outlet_name"
                  item-value="id"
                  label="Outlet Type"
                  multiple
                  variant="outlined"
                  @click:append-inner="clearOutlet"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                  v-model="selectedOutletStatus"
                  :items="outletSates"
                  append-inner-icon="mdi-close"
                  chips
                  item-title="outlet_status"
                  item-value="outlet_status"
                  label="Outlet Status"
                  multiple
                  variant="outlined"
                  @click:append-inner="clearOutletStatus"
              ></v-select>
            </v-col>

          </v-row>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-row>
            <v-col cols="12" md="4">
              <v-btn
                  v-if="permissions.read"
                  class="mr-1 bg-blue-lighten-5 "
                  color="blue"
                  icon
                  rounded="lg"
                  size="small"
                  variant="outlined"
                  width="120"
                  @click="viewItem(item)"
              >
                <v-icon>mdi-check-circle</v-icon>
                view
              </v-btn>
            </v-col>
            <v-col class="mr-0 d-flex justify-end" cols="12" md="3">
              <v-btn
                  v-if="permissions.write"
                  class="editB bg-green-lighten-5"
                  color="green"
                  icon
                  rounded
                  size="small"
                  variant="outlined"
                  @click="editItem(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
            <v-col class="ml-0" cols="12" md="3">
              <v-btn
                  v-if="permissions.delete"
                  class="bg-red-lighten-5"
                  color="red"
                  icon
                  rounded
                  size="small"
                  variant="outlined"
                  @click="confirmDelete(item)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <!--        update states dialog-->

    <v-dialog v-model="updateDialog" class="solid-dialog d-flex" height="85%" width="32%">
      <v-card class="pa-6 " style="overflow-y: auto">
        <v-row dense style="background-color: #d9d9d9;">
          <v-col cols="12" md="10">
            <v-card-title class="headline" style="font-size: 17px">UPDATE STATUS</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-btn class="mt-2 mr-2" icon size="x-small" @click="updateDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-4 mb-4"></v-divider>
        <v-row style="padding: 10px">
          <v-col>
            <v-text-field
                v-model="updateDetails.outlet_name"
                class="reminder"
                label="Outlet Name"
                readonly
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="updateDetails.owner_name"
                class="reminder"
                label="Owner Name"
                readonly
                variant="outlined"
            ></v-text-field>
            <v-text-field
                v-model="updateDetails.mobile_number"
                class="reminder"
                label="Mobile Number"
                readonly
                variant="outlined"
            ></v-text-field>
            <v-select
                v-model="selectOS"
                :items="outletSates"
                class="reminder"
                item-title="outlet_status"
                item-value="outlet_status"
                label="Outlet Status"
                variant="outlined"
            ></v-select>
            <v-text-field
                v-model="reason"
                class="reminder"
                label="Reason"
                variant="outlined"
            ></v-text-field>

          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row>
          <v-col class="d-flex justify-end " cols="12" md="12" @click="updateDialog = false">
            <v-btn class="mt-3 bg-red-lighten-4" color="red" size="large" variant="outlined"
                   @click="reminderSend(updateDetails.id)">
              UPDATE
            </v-btn>
            <v-btn class="mt-3 ml-3 bg-grey-darken-3" color="gray" size="large" variant="outlined"
                   @click="updateDialog = false">
              CANCEL
            </v-btn>
          </v-col>
        </v-row>

      </v-card>

    </v-dialog>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" max-height="500" max-width="500" persistent>
      <v-card class="pa-5">
        <v-row dense style="background-color: #d9d9d9;">
          <v-col cols="12" md="10">
            <v-card-title class="headline">DELETE OUTLET DATA</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-btn class="mt-2 mr-2" icon size="x-small" @click="deleteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-4"></v-divider>
        <v-card-text class="text-grey-darken-2">
          <v-icon class="mr-2" color="orange">mdi-alert</v-icon>
          Do you want to delete this outlet record ?
        </v-card-text>
        <v-card-text class="font-weight-medium">
          This outlet record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>
          <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteDialog = false">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="view_Dialog" class="solid-dialog" fullscreen persistent>
      <v-card class="card-size">
        <v-toolbar class="pr-5 mb-5" color="white" dense>
          <v-toolbar-title>
            <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" dark variant="elevated" @click="closeD">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row>
          <OutletDetails/>
        </v-row>
      </v-card>
    </v-dialog>


    <v-dialog v-model="editDialog" class="solid-dialog" fullscreen persistent>
      <v-card class="card-size">
        <v-toolbar class="pr-5 mb-5" color="white" dense>
          <v-toolbar-title>
            <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" dark variant="elevated" @click="closeD">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row>
          <OutletDetailsEdit/>
        </v-row>
      </v-card>
    </v-dialog>

  </v-card>
  <v-snackbar
      v-model="snackbar"
      color="success"
      location="top-right"
      outlined
      timeout="3000"
  >
    UPDATE SUCCESSFULLY.
    <template v-slot:action="{ attrs }">
      <v-btn
          icon
          v-bind="attrs"
          @click="snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>

  <v-snackbar
      v-model="Snackbar"
      :color="snackbarColor"
      :timeout="3000"
      location="top-right"
      outlined
  >
    {{ snackbarMessage }}

    <template v-slot:action="{ attrs }">
      <v-btn
          icon
          v-bind="attrs"
          @click="Snackbar = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>

  <v-overlay v-model="loading">
    <v-progress-circular class="centered-progress" indeterminate size="64"></v-progress-circular>
  </v-overlay>

</template>

<script>
import axios from 'axios';
import {EventBus} from '@/plugins/event-bus';
import OutletDetails from "@/components/OutletManagement/OutletDetails.vue";
import OutletDetailsEdit from "@/components/OutletManagement/OutletDetailsEdit.vue";
import {mapActions} from "vuex";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import {getPermissions} from "@/plugins/permissions";

export default {
  name: "OutletList",
  components: {
    OutletDetails,
    OutletDetailsEdit
  },
  data() {
    return {
      Snackbar: false,
      snackbarMessage: '',
      snackbarColor: '',
      loading: false,
      snackbar: false,
      outletName: '',
      ownerName: '',
      mobileNumber: '',
      reason: '',
      search: '',
      deleteDialog: false,
      updateDialog: false,
      itemToDelete: null,
      view_Dialog: false,
      editDialog: false,
      showSwitches: false,
      SalesSwitch: false,
      CollectionsSwitch: false,
      selectOS: '',
      selectedOutlet: [],
      outletTypes: [],
      selectedOutletStatus: [],
      outletSates: ['ALLOWED', 'NOT ALLOWED', 'NEED APPROVAL'],
      headers: [
        {text: 'OUTLET TYPE', value: 'outlet_type.outlet_name', title: 'OUTLET TYPE'},
        {text: 'OUTLET NAME', value: 'outlet_name', title: 'OUTLET NAME'},
        {text: 'OUTLET STATUS', value: 'outlet_status', title: 'OUTLET STATUS'},
        {text: 'OUTLET ADDRESS', value: 'outlet_address', title: 'OUTLET ADDRESS'},
        {text: 'Actions', value: 'action', sortable: false, title: 'ACTION'},
      ],
      routes: [], // Initially empty, will be filled by API call
      updateDetails: [],
      id: '',
      permissions: {},
    };
  },
  methods: {
    ...mapActions(['updateSharedDataOutlet']),

    updateStates(item) {
      this.selectOS = item.outlet_status
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const id = item.id;

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/${id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.updateDetails = response.data;
            console.log("API Response:", response.data);
            this.updateDialog = true;
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });
    },

    fetchRoutes() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/view`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.routes = response.data;
            console.log("API Response:", response.data);
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });
    },
    fetchOutletType() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.outletTypes = response.data;
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });

    },
    // fetchOutletStates() {
    //   const token = localStorage.getItem('token');
    //   const userId = localStorage.getItem('userId');
    //
    //   axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/status/view`, {userId}, {
    //     headers: {
    //       'Authorization': `Bearer ${token}`,
    //       'Content-Type': 'application/json'
    //     }
    //   })
    //       .then(response => {
    //         this.outletSates = response.data;
    //       })
    //       .catch(error => {
    //         console.error('Error fetching routes:', error);
    //       });
    //
    // },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    deleteItem() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/delete/${this.itemToDelete.id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

          .then(() => {
            this.routes = this.routes.filter(route => route.id !== this.itemToDelete.id);
            this.deleteDialog = false;
            this.itemToDelete = null;
            this.Snackbar = true;
            this.snackbarMessage = "Outlet was deleted successfully "
            this.snackbarColor = "success"
          })
          .catch(error => {
            this.Snackbar = true;
            this.snackbarMessage = error.message;
            this.snackbarColor = "error"
            console.error('Error deleting route:', error);
          });
    },
    exportPDF() {
      const doc = new jsPDF();

      // Set the title for the document
      doc.text('Outlet Details', 14, 10);

      // Prepare the data for the table
      const tableData = this.filteredRoutes.map((item, index) => [
        index + 1,
        item.outlet_type.outlet_name,
        item.outlet_name,
        item.owner_name,
        item.mobile_number,
        item.outlet_status,
        item.outlet_address,
        item.dsd,
        item.gnd,
      ]);

      // Define the columns for the table
      const tableColumns = ['#', 'Outlet Type', 'Outlet Name', 'Owner Name', 'Mobile Number', 'Outlet Status', 'Outlet Address', 'DSD', 'GND'];

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
      });

      // Save the PDF with a name
      doc.save('outlet_details.pdf');

    },
    exportExcel() {
      const tableData = this.filteredRoutes.map((item, index) => ({
        '#': index + 1,
        'ID': item.id,
        'Outlet Type': item.outlet_type.outlet_name,
        'Outlet Type ID': item.outlet_type.id,
        'Outlet Name': item.outlet_name,
        'Outlet Owner Name': item.owner_name,
        'Outlet Mobile Number': item.mobile_number,
        'Outlet Status': item.outlet_status,
        'Outlet Address': item.outlet_address,
        'Outlet District': item.district,
        'Outlet Province': item.province,
        'Outlet DSD': item.dsd,
        'Outlet GND': item.gnd,
        'Outlet Latitude': item.latitude,
        'Outlet Longitude': item.longitude,
        'Created At': item.created_at,
      }));

      // Create worksheet and workbook
      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Outlet Details');

      // Set column widths for better readability
      const columnWidths = [
        {wch: 5},   // For column "#"
        {wch: 10},  // For column "ID"
        {wch: 15},  // "Outlet Type"
        {wch: 15},  // "Outlet Type ID"
        {wch: 20},  // "Outlet Name"
        {wch: 20},  // "Outlet Owner Name"
        {wch: 15},  // "Outlet Mobile Number"
        {wch: 15},  // "Outlet Status"
        {wch: 30},  // "Outlet Address"
        {wch: 15},  // "Outlet District"
        {wch: 15},  // "Outlet Province"
        {wch: 10},  // "Outlet DSD"
        {wch: 10},  // "Outlet GND"
        {wch: 15},  // "Outlet Latitude"
        {wch: 15},  // "Outlet Longitude"
        {wch: 20},  // "Created At"
      ];
      worksheet['!cols'] = columnWidths;

      // Optional: Add cell styling if using xlsx-style (e.g., bold header)
      const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({r: 0, c: C});
        if (worksheet[cellAddress]) {
          worksheet[cellAddress].s = {
            font: {bold: true},
            alignment: {vertical: 'center', horizontal: 'center'},
          };
        }
      }

      // Write to file
      XLSX.writeFile(workbook, 'outlet_details.xlsx');
    },

    viewItem(item) {
      this.id = item.id
      this.updateSharedDataOutlet(item.id);
      this.view_Dialog = true;

    },
    editItem(item) {
      // Functionality to edit item details
      this.updateSharedDataOutlet(item.id);
      this.editDialog = true;
    },
    closeD() {
      this.view_Dialog = false;
      this.editDialog = false;
    },
    outletManagementButtonClick() {
      // Logic to execute when the second component button is clicked
      this.editDialog = true;
      this.view_Dialog = false;
    },
    backPageRoute() {
      this.$router.push('/dashboard');
    },
    o2() {
      this.editDialog = false;
      this.view_Dialog = false;
      this.routes = this.routes.filter(route => route.id !== this.id);
      this.id = null;
    },
    setupEventListeners() {
      EventBus.on('outletManagementButtonClick', this.outletManagementButtonClick);
      EventBus.on('outletManagementButtonClick2', this.o2);
    },
    removeEventListeners() {
      EventBus.off('outletManagementButtonClick', this.outletManagementButtonClick);
      EventBus.off('outletManagementButtonClick2', this.o2);
    },
    toggleSwitches() {
      this.showSwitches = !this.showSwitches;  // Toggle the visibility of the switches
    },
    reminderSend(id) {
      this.loading = true;

      console.log('xxxxxx', id)

      const userId = localStorage.getItem('userId');
      const outletData = {
        status: this.selectOS,
        reason: this.reason,
        userId: userId,
      };

      const token = localStorage.getItem('token');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/outlet/${id}/status`, outletData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            console.log('reminder send successfully:', response.data);
            this.fetchRoutes();
            this.snackbar = true;

          })
          .catch(error => {
            console.error('Error adding reminder:', error);

          })
          .finally(() => {
            this.loading = false;
          });
    },
    clearOutlet() {
      this.selectedOutlet = [];
    },
    clearOutletStatus() {
      this.selectedOutletStatus = [];
    }
  },
  mounted() {
    const dashboardId = 9;
    this.permissions = getPermissions(dashboardId);
    console.log("Permissions:", this.permissions);

    this.setupEventListeners();
    this.fetchRoutes();
    this.fetchOutletType();

  },
  beforeUnmount() {
    this.removeEventListeners();
  },
  computed: {
    filteredRoutes() {
      return this.routes.filter((route) => {

        if (window.ResizeObserver) {
          const ro = new ResizeObserver(() => {
          });
          ro.observe(document.body);
        }
        const withinDateRange =
            !this.dateRange ||
            (new Date(route.assignedDate) >= new Date(this.dates[0]) &&
                new Date(route.assignedDate) <= new Date(this.dates[1]));

        const matchesCategory =
            !this.selectedOutlet.length ||
            this.selectedOutlet.includes(route.outlet_type_id); // Assuming 'outletTypeId' is the field in your routes data.

        const matchesRouteStatus =
            !this.selectedOutletStatus.length ||
            this.selectedOutletStatus.includes(route.outlet_status); // Assuming 'outletStatus' is the field in your routes data.

        return withinDateRange && matchesCategory && matchesRouteStatus;
      });
    },

  }
}
</script>

<style scoped>


.main-card {
  width: 100%;
  height: 100%;
}

.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}

:deep(.data-table) th, td {
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  text-align: center;
}

:deep(.data-table) td, td {
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
}

:deep(.data-table) {
  border: white;
}

:deep(.data-table ) tr, th:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}

:deep(.data-table ) tr, td:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}


.reminder {
  color: #b00d0d;
}

.v-dialog > .v-overlay__content > .v-card, .v-dialog > .v-overlay__content > .v-sheet, .v-dialog > .v-overlay__content > form > .v-card, .v-dialog > .v-overlay__content > form > .v-sheet {
  overflow-y: hidden;
}

.centered-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


.leads-details-card {
  height: fit-content;
  padding-bottom: 20px;
  margin-bottom: 10px;
  overflow-x: hidden;
  overflow-y: scroll !important;
  max-height: 80vh;

}

.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: black;
}

.red-text {
  color: #b00d0d;
}

.red-light-text {
  background-color: #f1cccc;
  color: #b00d0d;
}

.red-white-text {
  background-color: #b00d0d;
  color: white;
}

.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}

.green-text {
  color: #057b0a;
  background-color: #aee8b1;
}

</style>