<script>
  import {mapState} from "vuex";
  import axios from 'axios';

  export default {
    data: () => ({
      drawer: true,      
      dialogLogout: false,
      group: null,
      activeSubMenu: null,
      menu: false,
      notificationDialog:false,
      userDetials:"",
      notification:[],
      accessDash:[],
      
    }),

    mounted() {
      this.checkDashboardPremission();
    },

    watch: {
      group () {
        this.drawer = false
      },
      
    },
     created(){
        this.getUserDetails();
        this.getAllNotification();
        // this.checkDashboardPremission();
    },
    computed: {
      ...mapState(['organizationSettings']),

      isVisible() {
        return (section, dashboardId = null) => {
          // Check if the section is enabled in Vuex
        //   const vuexSettings = this.organizationSettings?.[section];
        //   if (vuexSettings !== undefined) {
        //     if (vuexSettings !== '1') return false; // Not enabled in Vuex
        //   }

          // Check if the section is enabled in localStorage
          const localSettings = JSON.parse(localStorage.getItem('organizationSettings')) || {};
          if (localSettings[section] !== undefined) {
            if (localSettings[section] !== '1') return false; // Not enabled in localStorage
          }

          // New requirement: Check dashboard_id in accessDashboard
          if (dashboardId !== null) {
            return this.accessDash.some((item) => item.dashboard_id === dashboardId);
          }

          // Default: visible if no restrictions are found
          return true;
        };
      },

    },
    
    methods: {
        checkDashboardPremission(){
            const accessDashboard = JSON.parse(localStorage.getItem("accessDashboard"));
            console.log("No accessDashboard data found111.",accessDashboard);
            if (accessDashboard && Array.isArray(accessDashboard)) {
             const dashboards = accessDashboard;
             this.accessDash = dashboards;
            console.log('dash',this.accessDash);
            } else {
            console.log("No accessDashboard data found.");
            }
        },

        clearNotification(){

            const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('userId', userId); 

        axios.post('/api/delete/notifications/sale-collection', formData, {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (response.status === 200) {
            
            console.log('Clear',);

            } else {
            console.error("Error:", response.data.error);
            }
        })
        .catch(error => {
            console.error("Error fetching chart details:", error);
        });
           
        },

        getUserDetails(){

            const userId = localStorage.getItem('userId');
            const token = localStorage.getItem('token');

            const formData = new FormData();
            formData.append('userId', userId); 

            axios.post('/api/getUserDetailsIntoDashboard', formData, {
                headers: {
                'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                this.userDetials = response.data.data;
                console.log('pakoo',this.userDetials);

                } else {
                console.error("Error:", response.data.error);
                }
            })
            .catch(error => {
                console.error("Error fetching chart details:", error);
            });

            },

        getAllNotification(){

        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('userId', userId); 

        axios.post('/api/get/notifications/sale-collection', formData, {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (response.status === 200) {
            this.notification = response.data.data;
            console.log('notification',this.notification);

            } else {
            console.error("Error:", response.data.error);
            }
        })
        .catch(error => {
            console.error("Error fetching chart details:", error);
        });

        },

      async isLogOut() {

        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('userId', userId);
        try {
          // Send the logout request to the API
          await axios.post('/api/logout', formData, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          // Clear the token from local storage
          localStorage.removeItem('token');

          // Redirect to the login page
          window.location.href = '/login';
        } catch (error) {
          console.error('Error logging out:', error);
          // Handle the error (e.g., show a message or notification)
        }
      },

        // isLogOut() {
        //     window.location.href = '/login';
        // },

        homeReload(){
            window.location.href = '/dashboard';
        },

        toggleSubMenu(menu) {
            this.activeSubMenu = this.activeSubMenu === menu ? null : menu;
        },

        isActiveParentMenu(menu) {
            const route = this.$route.path;
            const subMenus = {
                A: ['/add-products', '/products-list'],
                B: ['/add-leads', '/leads-list'],
                C: ['/user-registration', '/users-list', '/create-user-role', '/user-role-list'],
                D: ['/live-tracking', '/tracking-history'],
                E: ['/create-routes', '/routes-list'],
                F: ['/create-outlet', '/outlet-list'],
            };
            return subMenus[menu] && subMenus[menu].includes(route);
        },
    },
  }
</script>

<template>
    <v-app>
        <v-card>
        <v-layout>
            <v-app-bar
            class="gray-text" dense prominent
            >
            <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer" ></v-app-bar-nav-icon>
    
            <v-toolbar-title class="mt-1">
                <img :src="require('@/assets/app_bar_logo.png')"  @click="homeReload()"/>
            </v-toolbar-title>
    
            <v-spacer></v-spacer>

            <!-- <v-btn icon="mdi-bell-outline" variant="text"></v-btn> -->

            <div class="text-center">
                <v-menu
                v-model="notificationDialog"
                :close-on-content-click="false"
                >
                    <template v-slot:activator="{ props }">
                        <v-btn class="mr-4" icon v-bind="props" variant="text">
                            <v-avatar>
                                <v-btn icon="mdi-bell-outline" variant="text"></v-btn>
                            </v-avatar>
                        </v-btn>
                    </template>

                    <v-card class="mt-4 " max-height="300px" max-width="300px" min-width="400px"  >
                        <v-row class="mt-2 ml-1">
                            <v-col class="d-flex justify-center align-center" cols="12">
                                
                                   <p>Notification</p> <v-spacer></v-spacer>
                                   <v-btn class="mr-2" color="red darken-2" @click="clearNotification">Clear Notification</v-btn>
                               
                            </v-col>

                            <v-col v-for="(message, index) in notification" :key="index" class="text-left" cols="12">
                                
                                    <h6 class="user-role spaced-content">{{ message.title }}</h6>
                                    <h6 class="user-time spaced-content text-right"> {{ message.created_at }} Ago</h6>
                                <v-divider></v-divider>
                               
                            </v-col>
                        </v-row>
                        <v-divider class="gray-text mt-6"></v-divider>

                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            dark
                            size="small"
                            variant="flat"
                            @click="notificationDialog = false"
                        >
                           Cancel
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </div>
    
            <!-- App bar icon & logout -->

            <div class="text-center">
                <v-menu
                v-model="menu"
                :close-on-content-click="false"
                >
                    <template v-slot:activator="{ props }">
                        <v-btn class="mr-4" icon v-bind="props" variant="text">
                            <v-avatar>
                                <img  :src="userDetials.user_image ? `http://sfa.backend.celata.org/SFA/public/${userDetials.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')" class="avatar-image"/>
                            </v-avatar>
                        </v-btn>
                    </template>

                    <v-card class="mt-4 no-scroll">
                        <v-row class="mt-2 ml-1">
                            <v-col class="d-flex justify-center align-center" cols="3">
                                <v-card-title>
                                    <v-avatar size="x-large">
                                        <img  :src="userDetials.user_image ? `http://sfa.backend.celata.org/SFA/public/${userDetials.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')" class="avatar-image"/>
                                    </v-avatar>
                                </v-card-title>
                            </v-col>

                            <v-col class="text-left" cols="9">
                                <h6  class="user-role spaced-content">{{ this.userDetials.user_type }}</h6>
                                <h3 class="spaced-content black-text">{{ this.userDetials.name }}</h3>
                                <h6 class="spaced-content gray-text">{{ this.userDetials.email }}</h6>
                                <!-- <h6 class="spaced-content gray-text">{{ this.userDetials.mobile_number[0].mobile_number }}</h6> -->
                            </v-col>
                        </v-row>
                        <v-divider class="gray-text mt-6"></v-divider>

                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            dark
                            size="small"
                            variant="flat"
                            @click="isLogOut()"
                        >
                            Sign out
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </div>


            </v-app-bar>

            <v-navigation-drawer v-model="drawer" :class="{'drawer-open': drawer, 'drawer-closed': !drawer}" app clipped>
                <v-divider></v-divider>
                <v-card class="mx-3 mt-3 drawer-content">
                    <v-list background dense density="compact" nav rounded>

                        <!-- Dashboard -->
                        <v-list-item v-if="isVisible('Dashboard', 1)" active-class="red-background" link to="/dashboard">
                                <v-list-item-icon >
                                    <v-icon >mdi-home-outline</v-icon>
                                </v-list-item-icon>&nbsp;&nbsp;Dashboard
                        </v-list-item>

                        <!-- Product Management -->
                        <v-list-item v-if="isVisible('Product Management',2)"  :class="{ 'red-background': isActiveParentMenu('A') }" link @click="toggleSubMenu('A')">
                            
                            <v-row class="align-center justify-space-between">
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-list-item-icon >
                                    <v-icon>mdi-store-outline</v-icon>&nbsp;&nbsp;Product Management
                                    </v-list-item-icon>
                                </v-col>
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-col>
                            </v-row>

                        </v-list-item>
                        <v-list-item-group  v-if="activeSubMenu === 'A'">
                            <v-list-item link to="/add-products">
                                <v-list-item-icon >
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Add Products
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/products-list">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Products List
                                </v-list-item-icon>
                            </v-list-item>
                          <v-list-item link to="/products-category">
                            <v-list-item-icon >
                              <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Add Category
                            </v-list-item-icon>
                          </v-list-item>
                          <v-list-item link to="/category-list">
                            <v-list-item-icon >
                              <v-icon>mdi-chevron-right</v-icon> &nbsp;Category List
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>

                        <!-- Leads Management -->
                        <v-list-item v-if="isVisible('Leads Management', 3)"  :class="{ 'red-background': isActiveParentMenu('B') }" link @click="toggleSubMenu('B')">
                                
                            <v-row class="align-center justify-space-between">
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-list-item-icon>
                                    <v-icon>mdi-account-circle-outline</v-icon>&nbsp;&nbsp;Leads Management
                                    </v-list-item-icon>
                                </v-col>
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-col>
                            </v-row>

                        </v-list-item>

                        <v-list-item-group v-if="activeSubMenu === 'B'">
                            <v-list-item link to="/add-leads">
                                <v-list-item-icon >
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Add Leads
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/leads-list">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Leads List
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list-item-group>

                        <!-- User Management -->
                        <v-list-item v-if="isVisible('User Management', 4)"  :class="{ 'red-background': isActiveParentMenu('C') }" link @click="toggleSubMenu('C')">
                            
                            <v-row class="align-center justify-space-between">
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-list-item-icon>
                                    <v-icon>mdi-account-multiple-plus-outline</v-icon>&nbsp;&nbsp;User Management
                                    </v-list-item-icon>
                                </v-col>
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-col>
                            </v-row>

                        </v-list-item>
                        <v-list-item-group v-if="activeSubMenu === 'C'">
                            <v-list-item link to="/user-registration">
                                <v-list-item-icon >
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;User Registration
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/users-list">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Users List
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/create-user-role">
                                <v-list-item-icon >
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Create User Role
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/user-role-list">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;User Role List
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list-item-group>

                        <!-- Sales Management -->
                        <v-list-item  v-if="isVisible('Sales Management', 5)" active-class="red-background" link to="/sales-management">
                        <v-list-item-icon>
                            <v-icon >mdi-chart-line</v-icon> &nbsp;&nbsp;Sales Management
                        </v-list-item-icon>
                        </v-list-item>

                        <!-- Collection Management -->
                        <v-list-item  v-if="isVisible('Collection Management', 6)"  active-class="red-background" link to="/collection-management">
                        <v-list-item-icon>
                            <v-icon >mdi-folder-account-outline</v-icon>&nbsp;&nbsp;Collection Management
                        </v-list-item-icon>
                        </v-list-item>

                        <!-- Tracking Management -->
                        <v-list-item v-if="isVisible('Tracking Management', 7)" :class="{ 'red-background': isActiveParentMenu('D') }" link @click="toggleSubMenu('D')">
                            
                            <v-row class="align-center justify-space-between">
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-list-item-icon>
                                    <v-icon>mdi-map-marker-path</v-icon>&nbsp;&nbsp;Tracking Management
                                    </v-list-item-icon>
                                </v-col>
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-col>
                            </v-row>

                        </v-list-item>

                        <v-list-item-group v-if="activeSubMenu === 'D'">

                            <v-list-item link to="/live-tracking">
                                <v-list-item-icon >
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Live Tracking
                                </v-list-item-icon>
                            </v-list-item>
                          <v-list-item link to="/overall-tracking-history">
                            <v-list-item-icon >
                              <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Overall Tracking History
                            </v-list-item-icon>
                          </v-list-item>
                            <v-list-item link to="/tracking-history">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Route-Plan Tracking History
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/myroute-tracking-history">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;My-Route Tracking History
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list-item-group>

                        <!-- Route Management -->
                        <v-list-item v-if="isVisible('Route Management', 8)"  :class="{ 'red-background': isActiveParentMenu('E') }" link @click="toggleSubMenu('E')">
                            
                            <v-row class="align-center justify-space-between">
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-list-item-icon>
                                    <v-icon>mdi-transit-connection-variant</v-icon>&nbsp;&nbsp;Route Management
                                    </v-list-item-icon>
                                </v-col>
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-col>
                            </v-row>

                        </v-list-item>
                        <v-list-item-group v-if="activeSubMenu === 'E'">
                            <v-list-item link to="/create-routes">
                                <v-list-item-icon >
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Create Routes
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/routes-list">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Routes List
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list-item-group>

                        <!-- Organization Management -->
                        <v-list-item active-class="red-background" link to="/organization-management">
                        <v-list-item-icon>
                            <v-icon >mdi-office-building-outline</v-icon>&nbsp;&nbsp;Organization Management
                        </v-list-item-icon>
                        </v-list-item>

                        <!-- Outlet Management -->
                        <v-list-item v-if="isVisible('Outlet Management', 9)"  :class="{ 'red-background': isActiveParentMenu('F') }" link @click="toggleSubMenu('F')">
                            
                            <v-row class="align-center justify-space-between">
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-list-item-icon>
                                    <v-icon>mdi-store-outline</v-icon>&nbsp;&nbsp;Outlet Management
                                    </v-list-item-icon>
                                </v-col>
                                <v-col class="d-flex align-center" cols="auto">
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-col>
                            </v-row>

                        </v-list-item>
                        <v-list-item-group v-if="activeSubMenu === 'F'">
                            <v-list-item link to="/create-outlet">
                                <v-list-item-icon >
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Create Outlet
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item link to="/outlet-list">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon> &nbsp;&nbsp;Outlet List
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list-item-group>
                        
                        <!-- User Log -->
                        <v-list-item active-class="red-background" link to="/user-log">
                            <v-list-item-icon>
                                <v-icon >mdi-text-box-search-outline</v-icon> &nbsp;&nbsp;User Logs
                            </v-list-item-icon>
                        </v-list-item>

                    </v-list>

                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                <v-divider class="mx-3 mt-2"></v-divider>
                <v-card-text class="text-center">
                    Powered by CelataTech
                </v-card-text>
            </v-card>
            </v-navigation-drawer>
            
            <v-main :class="{'drawer-expanded': drawer}" class="full-height">
                <router-view/>
            </v-main>
        </v-layout>
        </v-card>

    </v-app>
  </template>

<style scoped>

.full-height {
  height: 100vh;
  overflow: hidden;
}

.red-background {
  background-color: #BB0000 !important;
  color: white !important;
}

.red-background .v-icon,
.red-background .v-list-item__title {
  color: white !important;
}

.drawer-open {
    width: auto !important;
    border: none !important;
    box-shadow: none !important;
}

.drawer-closed {
    width: 0 !important;
    border: none !important;
    box-shadow: none !important;
}

.drawer-content {
  display: flex;
  flex-direction: column;
  min-height: 96%;
  color: #757575;
  overflow-y: hidden;
}

.drawer-content::-webkit-scrollbar {
  width: 2px;
}

.drawer-content::-webkit-scrollbar-thumb {
  background: #BB0000;
  border-radius: 10px;
}

.drawer-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.drawer-expanded{
    margin-left: 39px;
    padding-right: 20px;
}
:deep(.v-navigation-drawer__content ) {
  overflow-y: auto;
  height: 100%;
  max-height: 90vh;

}
:deep(.v-navigation-drawer__content::-webkit-scrollbar) {
  width: 5px;
}

.gray-text{
    color: #757575 !important;
}



.user-role{
    color: #910404 !important;
}

.user-time{
    color: gray !important;
}


.black-text{
    color:#000000 !important;
}

.spaced-content {
    margin: 0px;
}

.no-scroll {
    overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.pa-0 {
  padding: 0;
}
.avatar-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

</style>