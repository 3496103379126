<template>
  <v-card class="mt-3 main-card pl-3" variant="flat">
    <v-row class="pl-2 pr-6">

      <v-col class="mt-3" cols="6">
        <h3 class="page-title">TOTAL COLLECTIONS</h3>
        <h5 class="light-gray-text">Analyse collection data in a variety of stores.</h5>
        <v-card-title>

        </v-card-title>
      </v-col>

      <v-col class="px-4 mt-5" cols="6">
        <v-row class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
          <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>

    </v-row>
    <v-card class="px-2 pt-2 mx-1 mb-1 mx-1 leads-details-card" elevation="3">
      <h5 class="card-h4-title px-3 mt-2 mb-4">COLLECTION ANALYSIS
        <div class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-btn class="red-light-text mr-3" variant="outlined" @click="exportPDF">
            <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>

          <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
            <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </div>
      </h5>

      <v-divider class="pb-6"></v-divider>
      <div style="overflow-x: auto">
        <v-data-table
            :headers="headers"
            :items="filteredCollections"
            :items-per-page="5"
            :search="search"
            class="dataTable"
            item-value="id"
        >
          <template v-slot:[`item.agent.name`]="{ item }">
            <div class="agent-info">
              <div>{{ item.agent?.name || 'N/A' }}</div>
              <div style="color: red">Reg num: {{ item.agent.registration_num }}</div>
            </div>
          </template>


          <template v-slot:[`item.route_name`]="{ item }">
            {{ item.route_name || 'N/A' }}
          </template>

          <template v-slot:[`item.sales_amount`]="{ item }">
            <div>
              <div>{{ item.sales_amount || 'N/A' }}</div>
              <v-chip
                  v-if="item.payment_status  === 'Partialy Payment'"
                  class="bg-green-lighten-5"
                  color="blue"
                  label
                  style="height: 20px; width:130px; font-size: 12px; font-weight: bold"
                  variant="outlined"
              >
                PARTIALLY PAID
                <v-icon class="justify-end ml-1">mdi-check</v-icon>
              </v-chip>

              <v-chip
                  v-if="item.payment_status  === 'Pending Payment'"
                  class="bg-orange-lighten-5"
                  color="orange"
                  label
                  style="height: 20px; width:150px; font-size: 12px; font-weight: bold"
                  variant="outlined"
              >
                PENDING PAYMENT
                <v-icon class="justify-end ml-1">mdi-clock-outline</v-icon>
              </v-chip>

              <v-chip
                  v-if="item.payment_status  === 'Full Payment'"
                  class="bg-green-lighten-5"
                  color="green"
                  label
                  style="height: 20px; width:130px; font-size: 12px; font-weight: bold"
                  variant="outlined"
              >
                PAID COMPLETE
                <v-icon class="justify-end ml-1">mdi-check</v-icon>
              </v-chip>

              <v-chip
                  v-if="item.payment_status  === 'Overdue'"
                  class="bg-red-lighten-5"
                  color="red"
                  label
                  style="height: 20px; width:130px; font-size: 12px; font-weight: bold"
                  variant="outlined"
              >
                OVERDUE
                <v-icon class="justify-end ml-1">mdi-alert</v-icon>
              </v-chip>
            </div>
          </template>


          <template v-slot:[`item.store`]="{ item }">
            <div class="column-content ">{{ item.store }}</div>
          </template>

          <template v-slot:[`item.updated_at`]="{ item }">
            <div class="column-content">{{ new Date(item.updated_at).toISOString().split('T')[0] }}</div>
          </template>

          <template v-slot:top>
            <v-row>
              <v-col cols="12" md="4">
                <v-date-input
                    v-model="dates"
                    color="red"
                    label="Date"
                    max-width="368"
                    multiple="range"
                    placeholder="Select Date Range"
                    variant="outlined"
                ></v-date-input>
              </v-col>

              <!--              <v-col cols="12" md="4">-->
              <!--                <v-select-->
              <!--                    v-model="selectedAgent"-->
              <!--                    :items="agentLists"-->
              <!--                    clearable-->
              <!--                    item-title="name"-->
              <!--                    item-value="name"-->
              <!--                    label="Agent"-->
              <!--                    placeholder="Select agent name"-->
              <!--                    variant="outlined"-->
              <!--                >-->
              <!--                  &lt;!&ndash; <template v-slot:item="{ item }">-->
              <!--                    <div>-->
              <!--                      <strong>{{ item.name }}</strong><br />-->
              <!--                      <small>{{ item.registration_num }}</small>-->
              <!--                    </div>-->
              <!--                  </template> &ndash;&gt;-->
              <!--                </v-select>-->
              <!--              </v-col>-->

              <v-col cols="4">
                <v-select
                    v-model="selectedAgent"
                    :items="agentLists"
                    clearable
                    item-title="text"
                    item-value="value"
                    label="Agent"
                    multiple
                    placeholder="Select agent name"
                    variant="outlined"
                ></v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                    v-model="selectedRoute"
                    :items="routeLists"
                    clearable
                    item-title="text"
                    item-value="value"
                    label="Route Name"
                    multiple
                    placeholder="Select route name"
                    variant="outlined"
                ></v-select>

              </v-col>


              <!--              <v-col cols="12" md="4">-->
              <!--                <v-select-->
              <!--                    v-model="selectDistrict"-->
              <!--                    :items="getAllDoneDistrict"-->
              <!--                    clearable-->
              <!--                    item-title="text"-->
              <!--                    item-value="value"-->
              <!--                    label="District"-->
              <!--                    placeholder="Select District"-->
              <!--                    variant="outlined"-->
              <!--                ></v-select>-->
              <!--              </v-col>-->

              <v-col cols="12" md="4">
                <v-select
                    v-model="selectDistrict"
                    :items="getAllDoneDistrict"
                    clearable
                    item-title="text"
                    item-value="value"
                    label="District"
                    multiple
                    placeholder="Select District"
                    variant="outlined"
                ></v-select>

              </v-col>

              <!--              <v-col cols="12" md="4">-->
              <!--                <v-select-->
              <!--                    v-model="selectDsd"-->
              <!--                    :items="getAllDsd"-->
              <!--                    clearable-->
              <!--                    item-title="text"-->
              <!--                    item-value="value"-->
              <!--                    label="DSD"-->
              <!--                    placeholder="Select Dsd"-->
              <!--                    variant="outlined"-->
              <!--                ></v-select>-->
              <!--              </v-col>-->

              <v-col cols="12" md="4">
                <v-select
                    v-model="selectDsd"
                    :items="getAllDsd"
                    clearable
                    item-title="text"
                    item-value="value"
                    label="DSD"
                    multiple
                    placeholder="Select Dsd"
                    variant="outlined"
                ></v-select>

              </v-col>

              <v-col cols="12" md="4">
                <!--                <v-select-->
                <!--                    v-model="selectGnd"-->
                <!--                    :items="getAllGnd"-->
                <!--                    clearable-->
                <!--                    item-title="text"-->
                <!--                    item-value="value"-->
                <!--                    label="GND"-->
                <!--                    placeholder="Select Gnd"-->
                <!--                    variant="outlined"-->
                <!--                ></v-select>-->
                <v-select
                    v-model="selectGnd"
                    :items="getAllGnd"
                    clearable
                    item-title="text"
                    item-value="value"
                    label="Gnd"
                    multiple
                    placeholder="Select Gnd"
                    variant="outlined"
                ></v-select>
              </v-col>

              <!--              <v-col cols="12" md="4">-->
              <!--                <v-select-->
              <!--                    v-model="selectedCollectionType"-->
              <!--                    :items="getCollectionType"-->
              <!--                    clearable-->
              <!--                    item-title="sub_category_name"-->
              <!--                    item-value="sub_category_name"-->
              <!--                    label="Collection Type"-->
              <!--                    placeholder="Select sub category"-->
              <!--                    variant="outlined"-->
              <!--                ></v-select>-->
              <!--              </v-col>-->

              <v-col cols="12" md="4">
                <v-select
                    v-model="selectedCollectionType"
                    :items="getCollectionType"
                    clearable
                    item-title="text"
                    item-value="value"
                    label="Collection Type"
                    multiple
                    placeholder="Select Type"
                    variant="outlined"
                ></v-select>

              </v-col>


              <v-col cols="12" md="4">
                <v-text-field
                    v-model="search"
                    clearable
                    label="Search"
                    placeholder="Search"
                    variant="outlined"
                    width="100%"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-row>
              <v-btn
                  v-if="permissions.read"
                  class="mr-3 bg-blue-lighten-5 viewbtn"
                  color="blue"
                  variant="outlined"
                  @click="viewItem(item)"
              >
                <v-icon>mdi-check-circle</v-icon>&nbsp;view&nbsp;
              </v-btn>
              <v-btn
                  v-if="permissions.write"
                  class="mr-3 bg-green-lighten-5 "
                  color="green"
                  icon
                  rounded
                  size="small"
                  variant="outlined"
                  @click="editItem(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                  v-if="permissions.delete"
                  class="bg-red-lighten-5"
                  color="red"
                  icon
                  rounded
                  size="small"
                  variant="outlined"
                  @click="confirmDelete(item)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-row>
          </template>
        </v-data-table>
      </div>
    </v-card>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" max-height="500" max-width="500" persistent>
      <v-card class="pa-5">
        <v-row dense style="background-color: #d9d9d9;">
          <v-col cols="12" md="10">
            <v-card-title class="headline">DELETE COLLECTION DATA</v-card-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-btn class="mt-2 mr-2" icon size="x-small" @click="deleteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-4"></v-divider>
        <v-card-text class="text-grey-darken-2">
          <v-icon class="mr-2" color="orange">mdi-alert</v-icon>
          Do you want to delete this collection record ?
        </v-card-text>
        <v-card-text class="font-weight-medium">
          This collection record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>
          <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteDialog = false">CANCEL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="view_Dialog" class="solid-dialog" fullscreen persistent>
      <v-card class="card-size">
        <v-toolbar class="pr-5 mb-5" color="white" dense>
          <v-toolbar-title>
            <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" dark variant="elevated" @click="closeD">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row>
          <Collection_Record/>
        </v-row>
      </v-card>
    </v-dialog>

    <!--  collection recode edit view component call to dialog box-->
    <v-dialog v-model="editDialog" class="solid-dialog" fullscreen persistent>
      <v-card class="card-size">
        <v-toolbar class="pr-5 mb-5" color="white" dense>
          <v-toolbar-title>
            <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" dark variant="elevated" @click="closeD">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row>
          <Collection_Record_Edit/>
        </v-row>
      </v-card>
    </v-dialog>

  </v-card>
</template>
<script>
import axios from 'axios';
import Collection_Record_Edit from "@/components/COLLECTION_MANAGEMENT/Collection_Record_Edit.vue";
import {EventBus} from "@/plugins/event-bus";
import {mapActions} from "vuex";
import Collection_Record from "@/components/COLLECTION_MANAGEMENT/Collection_Record.vue";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import _ from 'lodash';
import {getPermissions} from "@/plugins/permissions";


export default {
  components: {Collection_Record, Collection_Record_Edit},
  data() {
    return {

      filteredCollections: [], // Data displayed in the table
      routeLists: [], // Dropdown options for route names
      selectedRoute: [], // Selected routes from the dropdown

      agentLists: [],
      selectedAgent: '',
      getAllDoneDistrict: [],
      getAllDsd: [],
      getAllGnd: [],
      selectedCollectionType: "",
      getCollectionType: [],
      selectDsd: "",
      selectGnd: "",
      selectDistrict: '',

      menu: false,
      dates: [],
      dateRange: '',
      search: '',
      deleteDialog: false,
      itemToDelete: null,
      view_Dialog: false,
      editDialog: false,
      agentID: null,
      headers: [
        {text: 'Oder Id', value: 'order_id', title: 'ODER ID'},
        {text: 'Store Name', value: 'store_name', title: 'OUTLET NAME'},
        {text: 'Agent Name', value: 'agent.name', title: 'AGENT NAME'},
        {text: 'Quantity Sold', value: 'sales_amount', title: 'TOTAL COLLECTIONS'},
        {text: 'Quantity Sold', value: 'route_name', title: 'Route NAME'},
        {text: 'LAST PAID DATE', value: 'updated_at', title: 'LAST PAID DATE'},
        {text: 'LAST PAID DATE', value: 'sales_type', title: 'COLLECTION TYPE'},
        {text: 'Actions', value: 'action', sortable: false, title: 'ACTION'},
      ],
      collections: [],
      permissions: {},
    }
  },
  methods: {
    ...mapActions(['updateSharedDataCollection']),

    getPaymentStatusColor(status) {
      switch (status) {
        case 'Partialy Payment':
          return 'blue';
        case 'OVERDUE':
          return 'red';
        case 'PENDING PAYMENT':
          return 'orange';
        case 'PAID COMPLETE':
          return 'green';
        default:
          return 'grey'; // Default color for unexpected or null values
      }
    },

    // Get Agent List
    getAllAgentsDeteails() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`/api/agentUser`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {

            this.agentLists = response.data.data;
            console.log("API Response:", response.data.data);

          })
          .catch(error => {
            console.error("There was an error fetching the sales data!", error);
          });
    },


    fetchCollectionsData() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/collections`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            console.log("API Response collections:", response.data);
            this.collections = Array.isArray(response.data) ? response.data : [];

            console.log('data', this.collections);

            // Extract unique agent names
            const agents = _.uniqBy(this.collections, 'agent.name');
            this.agentLists = agents.map(agent => ({
              text: agent.agent.name,
              value: agent.agent.id
            }));

            const routes = _.uniqBy(this.collections, 'route_name');
            this.routeLists = routes
                .filter(route => route.route_name) // Exclude null or undefined route_name
                .map(route => ({
                  text: route.route_name,
                  value: route.route_name
                }));

            const districts = _.uniqBy(this.collections, 'district');
            this.getAllDoneDistrict = districts
                .filter(route => route.district) // Exclude null or undefined district
                .map(route => ({
                  text: route.district,
                  value: route.district
                }));

            const dsds = _.uniqBy(this.collections, 'dsd');
            this.getAllDsd = dsds
                .filter(route => route.dsd) // Exclude null or undefined district
                .map(route => ({
                  text: route.dsd,
                  value: route.dsd
                }));

            const gnds = _.uniqBy(this.collections, 'gnd');
            this.getAllGnd = gnds
                .filter(route => route.gnd) // Exclude null or undefined district
                .map(route => ({
                  text: route.gnd,
                  value: route.gnd
                }));

            const collectionTypes = _.uniqBy(this.collections, 'sales_type');
            this.getCollectionType = collectionTypes
                .filter(route => route.sales_type) // Exclude null or undefined district
                .map(route => ({
                  text: route.sales_type,
                  value: route.sales_type
                }));


            console.log("Agent lists:", this.agentLists);

            // Initially show all collections
            this.filteredCollections = [...this.collections];

            // Process districts, DSD, and GND
            // const selectableDistricts = _.groupBy(this.collections, 'district');
            // const selectableDsd = _.groupBy(this.collections, 'dsd');
            // const selectableGnd = _.groupBy(this.collections, 'gnd');

            // this.getAllDoneDistrict = Object.keys(selectableDistricts).map(district => ({
            //   text: district,
            //   value: district
            // }));

            // this.getAllDsd = Object.keys(selectableDsd).map(dsd => ({
            //   text: dsd,
            //   value: dsd
            // }));

            // this.getAllGnd = Object.keys(selectableGnd).map(gnd => ({
            //   text: gnd,
            //   value: gnd
            // }));

            console.log("District", this.getAllDoneDistrict);
            console.log("collections after assignment:", this.collections);
          })
          .catch(error => {
            console.error("There was an error fetching the collections data!", error);
            this.collections = [];
          });
    },
    updateDateRange() {
      if (this.dates.length === 2) {
        this.dateRange = `${this.dates[0]} - ${this.dates[1]}`;
      }
    },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    deleteItem() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/collections/delete/${this.itemToDelete.id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.collections = this.collections.filter(sale => sale.id !== this.itemToDelete.id);
            this.deleteDialog = false;
            this.itemToDelete = null;
          })
          .catch(error => {
            console.error("There was an error deleting the sale!", error);
          });
    },
    exportPDF() {
      const doc = new jsPDF();

      // Set the title for the document
      doc.text('Collection Analysis', 14, 10);

      // Prepare the data for the table
      const tableData = this.collections.map((item, index) => [
        index + 1,
        item.order_id,
        item.store_name,
        item.agent.name,
        item.sales_amount
      ]);

      // Define the columns for the table
      const tableColumns = ['#', 'Order ID', 'Store Name', 'Agent Name', 'Total Collections'];

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
      });

      // Save the PDF with a name
      doc.save('collection_analysis.pdf');

    },


    exportExcel() {
      const tableData = this.collections.map((item, index) => ({
        '#': index + 1,
        'Order ID': item.order_id,
        'Store Name': item.store_name,
        'Agent Name': item.agent.name,
        'Total Collections': item.sales_amount
      }));

      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Collections');


      XLSX.writeFile(workbook, 'collection_analysis.xlsx');
    },

    // extra function
    otherXL() {
      // Define headers
      const headers = ['Order ID', 'Store Name', 'Agent Name', 'Total Collections'];

      // Prepare the data
      const data = this.collections.map((item) => [
        item.order_id,
        item.store_name,
        item.agent.name,
        item.sales_amount,
      ]);

      // Combine headers and data
      const worksheetData = [headers, ...data];

      // Create a new worksheet
      const ws = XLSX.utils.aoa_to_sheet(worksheetData);

      // Apply bold font to header row
      const headerRange = XLSX.utils.decode_range("A1:D1");
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({r: 0, c: C});
        if (!ws[cellAddress]) continue;
        ws[cellAddress].s = {font: {bold: true}};
      }

      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Collections Data');

      // Write the workbook to a file
      XLSX.writeFile(wb, 'collection_analysis.xlsx');

    },

    viewItem(item) {
      // localStorage.removeItem('viewID');
      // localStorage.setItem('viewID', item.id);
      // EventBus.emit('viewAgentDetails', item);

      this.updateSharedDataCollection(item.id);
      this.view_Dialog = true;

    },
    editItem(item) {
      //  functionality to edit item details
      this.updateSharedDataCollection(item.id);
      this.editDialog = true;
    },
    closeD() {
      // localStorage.removeItem('viewID');
      this.view_Dialog = false;
      this.editDialog = false;
    },
    backPageRoute() {
      this.$router.push('/dashboard');
    },
    collectionsManagementButtonClick() {
      // Logic to execute when the second component button is clicked
      this.view_Dialog = false;
      this.editDialog = true;
    },
    s2() {
      this.editDialog = false;
      this.view_Dialog = false;
    },
    setupEventListeners() {
      EventBus.on('collectionsRecodeManagementButtonClick', this.collectionsManagementButtonClick);
      EventBus.on('collectionsRecodeManagementButtonClick2', this.s2);
    },
    removeEventListeners() {
      EventBus.off('collectionsRecodeManagementButtonClick', this.collectionsManagementButtonClick);
      EventBus.off('collectionsRecodeManagementButtonClick2', this.s2);
    }
  },
  mounted() {
    const dashboardId = 6;
    this.permissions = getPermissions(dashboardId);
    console.log("Permissions:", this.permissions);

    this.setupEventListeners();
    this.fetchCollectionsData();
    this.getAllAgentsDeteails();
    console.log('Headers:', this.headers);
    console.log('collections:', this.collections);
  },
  beforeUnmount() {
    this.removeEventListeners();
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    filteredCollections() {
      return this.collections.filter(collection => {
        const matchesAgent = this.selectedAgent.length === 0 ||
            (collection.agent && this.selectedAgent.includes(collection.agent.id));
        const matchesRoute = this.selectedRoute.length === 0 ||
            (collection.route_name && this.selectedRoute.includes(collection.route_name));
        const matchesDistrict = this.selectDistrict.length === 0 ||
            (collection.district && this.selectDistrict.includes(collection.district));
        const matchesDSD = this.selectDsd.length === 0 ||
            (collection.dsd && this.selectDsd.includes(collection.dsd));
        const matchesGND = this.selectGnd.length === 0 ||
            (collection.gnd && this.selectGnd.includes(collection.gnd));
        const matchCollectionType = this.selectedCollectionType.length === 0 ||
            (collection.sales_type && this.selectedCollectionType.includes(collection.sales_type));
        return matchesAgent && matchesRoute && matchesDistrict && matchesDSD && matchesGND && matchCollectionType;
      });
    },
    filteredRoutes() {
      return this.collections.filter((sale) => {
        if (!sale) return false;

        console.log(sale);

        // Check if the sale's created_at date is within the selected date range
        const withinDateRange =
            !this.dates.length ||
            (sale.created_at &&
                new Date(sale.created_at) >= new Date(this.dates[0]) &&
                new Date(sale.created_at) <= new Date(this.dates[1]));

        // Check if the sale matches the search query
        const matchesSearch =
            !this.search ||
            (sale.store_name && sale.store_name.toLowerCase().includes(this.search.toLowerCase())) ||
            (sale.agent && sale.agent.name && sale.agent.name.toLowerCase().includes(this.search.toLowerCase())) ||
            (sale.order_id && sale.order_id.toLowerCase().includes(this.search.toLowerCase())) ||
            (sale.order_id && sale.order_id.toString().includes(this.search)) ||
            (sale.sales_amount && sale.sales_amount.toString().includes(this.search));

        // Check Agent
        const agent = !this.selectedAgent || (sale.agent.name && sale.agent.name.toLowerCase().includes(this.selectedAgent.toLowerCase()))


        // Check District
        const district = !this.selectDistrict || (sale.district && sale.district.toLowerCase().includes(this.selectDistrict.toLowerCase()))

        // Check Dsd
        const dsd = !this.selectDsd || (sale.dsd && sale.dsd.toLowerCase().includes(this.selectDsd.toLowerCase()))

        // Check Gnd
        const gnd = !this.selectGnd || (sale.gnd && sale.gnd.toLowerCase().includes(this.selectGnd.toLowerCase()))

        // Return true if both conditions are met
        return withinDateRange && matchesSearch && agent && district && dsd && gnd;
      });
    }

  },
  watch: {
    selectedAgent(newAgents) {
      if (newAgents.length === 0) {
        // No agents selected, show all collections
        this.filteredCollections = [...this.collections];
      } else {
        // Filter collections by selected agents
        this.filteredCollections = this.collections.filter(collection =>
            newAgents.includes(collection.agent.id)
        );
      }
    },
    selectedRoute(newRoutes) {
      if (!newRoutes || newRoutes.length === 0) {
        // No routes selected, show all collections
        this.filteredCollections = [...this.collections];
      } else {
        // Filter collections by selected routes, ensuring route_name is not null
        this.filteredCollections = this.collections.filter(collection =>
            collection.route_name && newRoutes.includes(collection.route_name)
        );
      }
    },
    selectDistrict(newDistricts) {
      if (!newDistricts || newDistricts.length === 0) {
        // No routes selected, show all collections
        this.filteredCollections = [...this.collections];
      } else {
        // Filter collections by selected routes, ensuring route_name is not null
        this.filteredCollections = this.collections.filter(collection =>
            collection.district && newDistricts.includes(collection.district)
        );
      }
    },
    selectDsd(selectDsd) {
      if (!selectDsd || selectDsd.length === 0) {
        // No routes selected, show all collections
        this.filteredCollections = [...this.collections];
      } else {
        // Filter collections by selected routes, ensuring route_name is not null
        this.filteredCollections = this.collections.filter(collection =>
            collection.dsd && selectDsd.includes(collection.dsd)
        );
      }
    },
    selectGnd(selectGnd) {
      if (!selectGnd || selectGnd.length === 0) {
        // No routes selected, show all collections
        this.filteredCollections = [...this.collections];
      } else {
        // Filter collections by selected routes, ensuring route_name is not null
        this.filteredCollections = this.collections.filter(collection =>
            collection.gnd && selectGnd.includes(collection.gnd)
        );
      }
    },
    selectedCollectionType(selectedCollectionType) {
      if (!selectedCollectionType || selectedCollectionType.length === 0) {
        // No routes selected, show all collections
        this.filteredCollections = [...this.collections];
      } else {
        // Filter collections by selected routes, ensuring route_name is not null
        this.filteredCollections = this.collections.filter(collection =>
            collection.sales_type && selectedCollectionType.includes(collection.sales_type)
        );
      }
    },
    dates() {
      this.updateDateRange();
    }
  },
}
</script>

<style scoped>

.main-card {
  width: 100%;
  height: 100%;
  padding-right: 20px;
}

.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}


:deep(.dataTable) th, td {
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  text-align: center;
  max-width: 12vw;
}

:deep(.dataTable) td, td {
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  max-width: 12vw;
}

:deep(.dataTable) th:last-child {
  width: 18vw;
  max-width: 20vw;
}

:deep(.dataTable) td:last-child {
  width: 18vw;
  max-width: 20vw;
  padding-top: 10px;
  margin-top: 10px;
}

:deep(.dataTable) {
  border: black;
  overflow-x: hidden !important;
}

:deep(.dataTable ) tr, th:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}

:deep(.dataTable ) tr, td:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}


.v-dialog > .v-overlay__content > .v-card, .v-dialog > .v-overlay__content > .v-sheet, .v-dialog > .v-overlay__content > form > .v-card, .v-dialog > .v-overlay__content > form > .v-sheet {
  overflow-y: hidden;
}


.leads-details-card {
  height: 100%;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  max-height: 79vh;

}

.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: black;
}

.red-text {
  color: #b00d0d;
}

.red-light-text {
  background-color: #f1cccc;
  color: #b00d0d;
}

.red-white-text {
  background-color: #b00d0d;
  color: white;
}

.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}

.green-text {
  color: #057b0a;
  background-color: #aee8b1;
}

@media (max-width: 1366px) {
  .v-chip {
    height: auto !important; /* Allow height to adjust dynamically */
    width: auto !important; /* Let the width adjust */
    font-size: 10px !important; /* Reduce font size */
    padding: 4px 8px !important; /* Compact padding */
  }

  .v-chip .v-icon {
    font-size: 12px; /* Adjust icon size */
  }

  .item-sales-amount {
    font-size: 14px; /* Reduce font size of main text */
  }

  .agent-info {
    font-size: 14px; /* Adjust font size */
    padding: 5px 10px; /* Adjust padding */
  }

  .agent-info div {
    margin-bottom: 4px; /* Add margin between lines for better spacing */
  }

  .agent-info .registration-num {
    font-size: 12px; /* Smaller font size for registration number */
    color: red;
  }
}

@media (max-width: 1024px) {
  .v-chip {
    font-size: 8px !important; /* Further reduce font size for smaller widths */
  }

  .v-chip .v-icon {
    font-size: 12px; /* Smaller icons for very small screens */
  }

  .item-sales-amount {
    font-size: 12px; /* Further reduce text size */
  }

  .agent-info {
    font-size: 12px; /* Further reduce font size */
    padding: 4px 8px; /* Reduce padding */
  }

  .agent-info .registration-num {
    font-size: 10px; /* Smaller font size for reg number */
  }
}

.dataTable {
  padding-left: 25px;
  padding-right: 10px;
  padding-bottom: 30px;
  height: fit-content;
  margin-bottom: 20px;
}

.viewbtn {
  width: auto;
}

@media (max-width: 1366px) and (min-width: 768px) {
  .dataTable {
    font-size: 11px; /* Adjust font size for specific resolution */
  }

  .viewbtn {
    width: 20px; /* Narrow button for 1366x768 screen */
  }
}

</style>