<template>
  <v-overlay v-model="centerLoading" :scrim="false" class="centered-overlay">
    <v-progress-circular
        color="green"
        indeterminate
        size="64"
    ></v-progress-circular>
  </v-overlay>


  <v-card class="mt-3 card-size pl-3" flat
          style="max-height: 700px; overflow-y: scroll ; scrollbar-width: none; -ms-overflow-style: none; ">
    <v-row class="pl-2 pr-6">
      <v-col class="mt-3" cols="7">
        <h3 class="page-title">ADD PRODUCTS</h3>
        <h5 class="light-gray-text">Orders placed across your store</h5>
        <v-card-title>

        </v-card-title>
      </v-col>
      <v-col class="px-4 mt-5" cols="5">
        <v-row class="tool-bar-style">
          <v-btn class="red-text btn-border" size="x-large" variant="text" @click="addCategoryPageRoute()">
            <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;ADD CATEGORY
          </v-btn>
          <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
          <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-card class="px-2 pt-2 mx-1 mb-2 product-details-card" elevation="3">
      <h5 class="card-two-title px-3 mt-2 mb-4">ADD PRODUCTS DETAILS</h5>
      <v-divider></v-divider>

      <v-form ref="form">
        <v-row class="pt-6">
          <v-col class="px-6" cols="6">
            <v-card class="card-hight-fixed" flat>
              <v-col cols="11">
                <v-text-field
                    v-model="form.productTitle"
                    :rules="[v => !!v || 'Product title is required']"
                    class="mb-2"
                    label="Product Title *"
                    placeholder="Type title here"
                    required
                    variant="outlined"
                ></v-text-field>
                <!-- <p v-if="errorMessage" class="mt-0 mb-2" style="color: red;" type="error">
                  {{ errorMessage }}
                </p> -->
                <!-- <p style="color: gray">Product Description</p> -->


                <v-card flat width="100%">
                  <div class="d-flex justify-space-between pa-4 pb-0">
                    <!-- Text Formatting Buttons -->
                    <v-btn-toggle v-model="formatting" divided multiple variant="outlined">
                      <v-btn @click="applyFormat('italic')">
                        <v-icon icon="mdi-format-italic"></v-icon>
                      </v-btn>

                      <v-btn @click="applyFormat('bold')">
                        <v-icon icon="mdi-format-bold"></v-icon>
                      </v-btn>

                      <v-btn @click="applyFormat('underline')">
                        <v-icon icon="mdi-format-underline"></v-icon>
                      </v-btn>

                      <!-- Text Color Option -->
                      <v-menu
                          v-model="showColorPicker"
                          close-on-content-click
                          location="end"
                      >

                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on">
                            <div class="d-flex align-center flex-column justify-center">
                              <v-icon icon="mdi-format-color-text"></v-icon>
                              <v-sheet :color="selectedColor" height="4" tile width="26"></v-sheet>
                            </div>
                          </v-btn>
                        </template>

                        <!-- Color Picker -->
                        <v-color-picker
                            v-model="selectedColor"
                            flat
                            hide-canvas
                            @change="applyFormat('color')"
                        ></v-color-picker>
                      </v-menu>
                    </v-btn-toggle>

                    <!-- Text Alignment Buttons -->
                    <v-btn-toggle v-model="alignment" divided variant="outlined">
                      <v-btn @click="setAlignment('center')">
                        <v-icon icon="mdi-format-align-center"></v-icon>
                      </v-btn>

                      <v-btn @click="setAlignment('left')">
                        <v-icon icon="mdi-format-align-left"></v-icon>
                      </v-btn>

                      <v-btn @click="setAlignment('right')">
                        <v-icon icon="mdi-format-align-right"></v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </div>

                  <!-- Text Area -->
                  <v-sheet class="pa-4 text-center">
                    <!-- <v-textarea
                        v-model="lead.description"
                        class="mb-2"
                        label="Itineraries Description *"
                        placeholder="Write a description here..."
                        :style="textareaStyles"
                        variant="outlined"
                        :rules="[v => !!v || 'Description is required']"
                    ></v-textarea> -->
                    <v-textarea
                        v-model="form.productDescription"
                        :style="textareaStyles"
                        class="mb-2"
                        label="Product Description"
                        placeholder="Write a description here..."
                        variant="outlined"
                    ></v-textarea>
                  </v-sheet>
                </v-card>

                <v-card flat>
                  <p style="color: gray">Product Images (Maximum 5 images allowed)</p>
                  <div
                    class="drop-area"
                    @click="triggerFileInput"
                    @drop.prevent="onDrop"
                    @dragover.prevent="onDragOver"
                  >
                    <input
                      ref="fileInput"
                      accept="image/*"
                      class="hidden-file-input"
                      multiple
                      type="file"
                      @change="onFileChange"
                    />
                    <div
                      v-for="(image, index) in previewImages"
                      :key="index"
                      class="image-card-container"
                    >
                      <v-card class="image-card">
                        <v-img
                          :alt="'Image ' + (index + 1)"
                          :src="image"
                          class="image-preview"
                        ></v-img>
                        <v-btn
                          class="remove-icon"
                          icon
                          size="x-small"
                          @click.stop="removeImage(index)"
                        >
                          <v-icon color="red" size="small">mdi-delete</v-icon>
                        </v-btn>
                      </v-card>
                    </div>
                    <div v-if="previewImages.length === 0" class="placeholder-container">
                      <v-icon size="36">mdi-cloud-upload</v-icon>
                      <span>Drag your photo here OR click to browse from device</span>
                    </div>
                    <div v-if="previewImages.length >= 5" class="max-images-warning">
                      <span style="color: red;">Maximum 5 images allowed</span>
                    </div>
                  </div>
                </v-card>

              </v-col>
            </v-card>
          </v-col>
          <v-col class="px-6" cols="6">
            <v-card class="card-hight-fixed" flat>
              <v-col cols="11">
                <v-select
                    v-model="form.mainCategory"
                    :clearable="form.mainCategory !== null && form.mainCategory !== ''"
                    :items="mainCategories"
                    :rules="[v => !!v || 'Main category is required']"
                    class="mb-2"
                    item-title="name"
                    item-value="id"
                    label="Main Category *"
                    placeholder="Select main category"
                    required
                    variant="outlined"
                    @update:modelValue="fetchSubCategories()"
                />

                <v-select
                    v-model="form.subCategory"
                    :clearable="form.subCategory !== null && form.subCategory !== ''"
                    :items="subCategories"
                    :loading="productLoading"
                    :rules="[v => !!v || 'Sub category is required']"
                    class="mb-2"
                    item-title="sub_category_name"
                    item-value="sub_category_name"
                    label="Sub Category *"
                    placeholder="Select sub category"
                    required
                    variant="outlined"
                />
                <v-select
                    v-model="form.unit"
                    :items="units"
                    class=" mb-2 "
                    clearable
                    item-title="Unit"
                    item-value="name"
                    label="Unit"
                    required
                    variant="outlined"
                >
                </v-select>
                <v-text-field
                  v-model="form.size"
                  class="mb-2"
                  label="Number of Units"
                  placeholder="Enter the number of units"
                  variant="outlined"
                  :rules="[
                    v => !isNaN(Number(v)) || 'Number of Units must be a number',
                    v => Number(v) >= 0 || 'Number of Units must be 0 or higher'
                  ]"
                  type="number"
                >
                </v-text-field>


                
                <p>Selected Color: {{ getColorName(form.color) }}</p>
                <v-text-field
                    v-model="form.color"
                    class="mb-2"
                    label="Color"
                    variant="outlined"
                    @click="dialog = true"
                ></v-text-field>
                <v-dialog v-model="dialog" max-width="400" persistent>
                  <v-card class="dialog-card-style px-3">
                    <v-card-title>Select Color</v-card-title>
                    <v-card-text>
                      <v-color-picker
                          v-model="form.color"
                          v-model:mode="mode"
                      ></v-color-picker>
                      <v-select
                          v-model="mode"
                          :items="modes"
                          class="mt-6"
                          variant="outlined"
                      ></v-select>
                    </v-card-text>
                    <v-btn class="red-background-text-btn mx-auto" variant="outlined" @click="dialog = false">
                      <v-icon>mdi-check-circle</v-icon>&nbsp;&nbsp;&nbsp;&nbsp;ADD
                    </v-btn>
                  </v-card>
                </v-dialog>
                <v-text-field
                    v-model="form.price"
                    :rules="[v => !isNaN(Number(v)) || 'Price must be a number', v => Number(v) >= 0 || 'Price must be 0 or higher']"
                    class="mb-2"
                    label="Price *"
                    placeholder="Type price here"
                    required
                    variant="outlined"
                ></v-text-field>
                <v-text-field
                    v-model="form.discountRate"
                    :rules="[v => !isNaN(Number(v)) || 'Discount rate must be a number', v => Number(v) >= 0 || 'Discount rate must be 0 or higher', v => Number(v) < 100 || 'Discount rate must be less than 100']"
                    class="mb-2"
                    label="Discount Rate"
                    placeholder="Type discount rate here"
                    variant="outlined"
                ></v-text-field>
                <v-text-field
                    v-model="form.availableStock"
                    :rules="[v => !isNaN(Number(v)) || 'Stock must be a number', v => Number(v) >= 0 || 'Stock must be 0 or higher']"
                    class="mb-2"
                    label="Available Stock *"
                    placeholder="Type stock here"
                    required
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-card>

          </v-col>
        </v-row>
        <v-divider class="divider-two-style"></v-divider>
        <v-row class="margin-bottomn-fix mr-5 pa-3 mb-2">
          <v-spacer></v-spacer>
          <v-btn class="red-background-text mr-3" variant="outlined" @click="addProduct">ADD PRODUCTS</v-btn>
          <v-btn class="gray-background-text" variant="outlined" @click="backPageRoute()">CANCEL</v-btn>
        </v-row>
      </v-form>
    </v-card>
    <!-- Snackbar to show notifications -->
    <v-snackbar
        v-model="snackAlert.snackbar"
        :color="snackAlert.snackColor"
        :right="snackAlert.snackBarRight"
        :top="snackAlert.snackBarTop"
        timeout="3000"
    >
      {{ snackAlert.snackMessage }}
    </v-snackbar>

  </v-card>
</template>
<script>
import axios from 'axios';
import ntc from 'ntc';
// import { ref } from 'vue';

export default {
  name: 'AddProducts',
  data() {
    return {
      formatting: [],
      showColorPicker: false,
      selectedColor: '#000',
      alignment: '',
      textareaStyles: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        textDecoration: 'none',
        color: '#000',
        textAlign: 'left',
      },
      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },

      units: [
        {Unit: 'Grams  (g)', name: 'g'},
        {Unit: 'Kilograms  (kg)', name: 'kg'},
        {Unit: 'Liters  (l)', name: 'L'},
        {Unit: 'Milliliters  (ml)', name: 'ml'},
        {Unit: 'Centimeters  (cm)', name: 'cm'},
        {Unit: 'Meters  (m)', name: 'm'}
      ],
      mainCategories: [],
      checkProductDetails:[],
      productLoading: false,
      centerLoading: false,
      subCategories: [],
      form: {
        productTitle: '',
        unit: '',
        productDescription: '',
        main_category_name: '',
        mainCategory: null,
        subCategory: null,
        size: '',
        color: '#FFFFFF',
        price: '',
        discountRate: '',
        availableStock: '',
        images: []
      },
      previewImages: [],
      dialog: false,
      // mode: 'hsla',
      mode: 'RGB',
      // modes: ['hsla', 'rgba', 'hexa'],
      modes: ['RGB', 'HSL'],
      convertColor: '',
    };
  },


  methods: {
    chechalreadyHasProductName() {
      if (
          Array.isArray(this.checkProductDetails.product_title) &&
          this.checkProductDetails.product_title.includes(this.form.productTitle)
      ) {
        this.errorMessage = 'Product Name already exists';
      } else {
        this.errorMessage = '';
      }
    },


    fetchProducts() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");

      this.loading = true;

      const formData = new FormData();
      formData.append("userId", userId);

      axios.post('/api/getProductList',formData,{
        headers: {
              'Authorization': `Bearer ${token}`
          }

      })
        .then(response => {
          // Sort the data by created_at in descending order (newest first)
          this.checkProductDetails = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          this.loading =false;
          // this.items = response.data.data;
          console.log('productList', response.data.data);
          // this.subCategories = response.data.subCategories;
        })
        .catch(error => {
          console.error('Error fetching categories:', error);
          this.loading =false;
        });
      },

    applyFormat(format) {

      if (format === 'italic') {
        this.textareaStyles.fontStyle =
            this.textareaStyles.fontStyle === 'italic' ? 'normal' : 'italic';
      } else if (format === 'bold') {
        this.textareaStyles.fontWeight =
            this.textareaStyles.fontWeight === 'bold' ? 'normal' : 'bold';
      } else if (format === 'underline') {
        this.textareaStyles.textDecoration =
            this.textareaStyles.textDecoration === 'underline'
                ? 'none'
                : 'underline';
      } else if (format === 'color') {
        this.textareaStyles.color = this.selectedColor;
      }
    },

    setAlignment(align) {
      this.textareaStyles.textAlign = align;
    },

    getColorName(hex) {
      const color = ntc.name(hex);
      // this.convertColor = color[1];
      return color[1];
    },


    sizeValidation(value) {
      const sizePattern = /^\d+(\.\d+)?\s*(cm|centimeter|centimeters|mm|millimeter|millimeters|in|inches|m|meter|meters|ft|feet|other)$/i;
      return sizePattern.test(value.trim());
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onDragOver(event) {
      event.preventDefault();
    },
    // handleFiles(files) {
    //   const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    //   const fileArray = Array.from(files).filter(file => validImageTypes.includes(file.type));

    //   if (fileArray.length === 0) {
    //     alert('Only image files are allowed!');
    //     return;
    //   }

    //   this.form.images.push(...fileArray);
    //   this.previewImages.push(...fileArray.map(file => URL.createObjectURL(file)));
    // },
    // handleFiles(files) {
    //   const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    //   const fileArray = Array.from(files).filter(file => validImageTypes.includes(file.type));

    //   if (fileArray.length === 0) {
    //     alert('Only image files are allowed!');
    //     return;
    //   }

    //   // Save the images in the expected format
    //   fileArray.forEach(file => {
    //     this.form.images.push({ image: file.name }); // Store only the image filename
    //   });

    //   // Save the file previews for UI display
    //   this.previewImages.push(...fileArray.map(file => URL.createObjectURL(file)));
    // },

    fetchMainCategories() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/mainProduct', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            let sample = response.data.data;
            this.mainCategories = sample.map(item => {
              return {
                id: item.id,
                name: item.main_category
              };
            });
            console.log('Main categories:', this.mainCategories);
          })
          .catch(error => {
            console.error('Error fetching main categories:', error);
          });
    },
    fetchSubCategories() {
      // if (!this.form.mainCategory) return;
      this.productLoading = true;
      this.form.subCategory = null;
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const mainCatagoryId = this.form.mainCategory;
      console.log('Main Category ID:', this.form.mainCategory);

      axios.post(`/api/subcatagoryProduct`,
          {
            userId: userId,
            mainCatagoryId: mainCatagoryId
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })

          .then(response => {
            console.log('API Response:', response);
            this.subCategories = response.data.data;
            console.log('Subcategories:', this.subCategories);
            // Find and set the main category name
            const selectedCategory = this.mainCategories.find(cat => cat.id === mainCatagoryId);
            this.form.main_category_name = selectedCategory ? selectedCategory.name : '';
            console.log('Selected Main Category Name:', this.form.main_category_name);
            this.productLoading = false;

          })
          .catch(error => {
            console.error('Error fetching sub categories:', error);
            this.productLoading = false;
          });
    },

    // Handle file selection
    handleFiles(files) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const fileArray = Array.from(files).filter(file => validImageTypes.includes(file.type));
      const maxImages = 5;
      const remainingSlots = maxImages - this.previewImages.length;

      if (fileArray.length === 0) {
        this.showSnackAlert('error','Only image files (JPEG, PNG, GIF) are allowed!');
        return;
      }

      if (remainingSlots <= 0) {
        this.showSnackAlert('error',`You can only upload up to ${maxImages} images.`);
        return;
      }

      // Limit to remaining slots
      const filesToAdd = fileArray.slice(0, remainingSlots);

      // Update form data
      this.form.images.push(...filesToAdd);

      // Create image previews
      filesToAdd.forEach(file => {
        const imageUrl = URL.createObjectURL(file);
        this.previewImages.push(imageUrl);
      });
    },


    onFileChange(event) {
      const files = Array.from(event.target.files);
      this.handleFiles(files);
      event.target.value = ''; // Reset the file input
    },
    onDrop(event) {
      const files = Array.from(event.dataTransfer.files);
      this.handleFiles(files);
    },

    // Remove an image from the selected images
    removeImage(index) {
      this.form.images.splice(index, 1); // Remove the image object (containing filename) from form data
      this.previewImages.splice(index, 1); // Remove the preview image URL from the UI
    },

    // Helper method to convert a file to a Base64 string
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        if (file instanceof Blob) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64String = reader.result.split(',')[1]; // Remove the prefix
            resolve(base64String);
          };
          reader.onerror = error => reject(error);
        } else {
          reject(new Error('Invalid file type'));
        }
      });
    },

    // Validate and add the product
    async addProduct() {
      // Perform individual field validation
      if (!this.form.productTitle) {
        this.showSnackAlert('error', 'Product title is required.');
        return;
      }

      // if (!this.form.productDescription) {
      //   this.showSnackAlert('error', 'Product description is required.');
      //   return;
      // }

      if (!this.form.main_category_name) {
        this.showSnackAlert('error', 'Main category is required.');
        return;
      }

      if (!this.form.subCategory) {
        this.showSnackAlert('error', 'Sub-category is required.');
        return;
      }

      // if (!this.form.unit) {
      //   this.showSnackAlert('error', 'Unit is required.');
      //   return;
      // }

      // if (!this.form.size) {
      //   this.showSnackAlert('error', 'Size is required.');
      //   return;
      // }

      // if (!this.form.color) {
      //   this.showSnackAlert('error', 'Color is required.');
      //   return;
      // }

      if (!this.form.price || isNaN(this.form.price) || this.form.price <= 0) {
        this.showSnackAlert('error', 'Valid price is required.');
        return;
      }

      if (this.form.discountRate === null || this.form.discountRate < 0) {
        this.showSnackAlert('error', 'Discount rate must be 0 or greater.');
        return;
      }

      if (!this.form.availableStock || isNaN(this.form.availableStock) || this.form.availableStock < 0) {
        this.showSnackAlert('error', 'Available stock must be 0 or greater.');
        return;
      }

      if (!this.form.images || this.form.images.length === 0) {
        this.showSnackAlert('error', 'At least one product image is required.');
        return;
      }

      // Validation passed, proceed with submission
      this.centerLoading = true;
      const formData = new FormData();
      formData.append('productTitle', this.form.productTitle);
      formData.append('productDescription', this.form.productDescription);
      formData.append('mainCatagory', this.form.main_category_name);
      formData.append('subCatagory', this.form.subCategory);
      formData.append('unit', this.form.unit);
      formData.append('size', this.form.size);
      const colorName = this.getColorName(this.form.color);
      formData.append('color', colorName);
      formData.append('price', this.form.price);
      formData.append('discountRate', this.form.discountRate);
      formData.append('availableStaock', this.form.availableStock);
      formData.append('userId', localStorage.getItem('userId'));

      try {
        const base64Images = await Promise.all(
            this.form.images.map(file => this.convertToBase64(file))
        );
        const imagesJson = JSON.stringify(base64Images.map(image => ({image})));
        formData.append('productImage', imagesJson);

        const token = localStorage.getItem('token');

        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/addProduct`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data.success) {
          this.showSnackAlert('success', 'New Product Was Added Successfully.');
          this.centerLoading = false;
          window.location.reload();
          this.resetForm();
        } else {
          this.showSnackAlert('error', 'Failed to add product.');
          this.centerLoading = false;
        }
      } catch (error) {
        this.showSnackAlert('error', 'Error adding product. Please try again.');
        this.centerLoading = false;
        console.error('Error adding product:', error);
      }
    },

// Helper method to show snackbar alerts
    showSnackAlert(type, message) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackColor = type;
      this.snackAlert.snackMessage = message;
    },

    // Reset the form after successful product addition
    resetForm() {
      this.form = {
        productTitle: '',
        productDescription: '',
        main_category_name: '',
        subCategory: '',
        size: '',
        unit: '',
        color: '',
        price: '',
        discountRate: '',
        availableStock: '',
        images: []
      };
      this.previewImages = [];
    },
    addCategoryPageRoute() {
      this.$router.push({path: '/products-category'});
    },
    backPageRoute() {
      this.$router.back();
    },
    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    }

  },
  created() {
    this.fetchMainCategories();
    this.fetchProducts();
  },
};
</script>

<style scoped>

.page-title {
  font-weight: bold;
}

.light-gray-text {
  color: #a09e9e !important;
}

.red-text {
  color: #b00d0d;
}

.gray-text {
  color: #757575 !important;
}

.btn-border {
  border-block-end-color: #9a1313;
}

.card-size {
  width: 100%;
  padding: 0;
  margin-bottom: 0;
}

.tool-bar-style {
  display: flex;
  justify-content: end;
}

.divider-vertical {
  color: #3c3b3b !important;
}

.card-two-title {
  color: black;
}

.drop-area {
  border: 2px dashed #9a1313;
  padding: 20px;
  margin-top: 0px;
  position: relative;
  display: flex;
  flex-wrap: nowrap; /* Ensure no wrapping */
  cursor: pointer;
  max-width: 600px;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.hidden-file-input {
  display: none;
}

.image-card-container {
  margin: 0 8px 8px 0;
}

.image-card {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.image-preview {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.remove-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: white;
  border-radius: 50%; /* This will make the background round */
  padding: 4px; /* Adjust as needed to control the icon size */
}

.remove-icon .v-icon {
  color: red;
}

.placeholder-container {
  width: 100%;
  height: 120px;
  text-align: center;
  color: #9a1313;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.product-details-card {
  height: 100%;
}

.card-hight-fixed {
  height: 100%;
  overflow: auto;
  max-height: 61vh;
  /* scrollbar-width: none;
  -ms-overflow-style: none; */
}

.divider-two-style {
  margin-top: -5px;
}

.centered-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.margin-bottomn-fix {
  margin-top: 0px;
}

.red-background-text {
  background-color: #f0bcbc;
  color: #BB0000;
}

.red-background-text-btn {
  background-color: #f0bcbc;
  color: #BB0000;
  margin-top: -27px;
  margin-bottom: 10px;
  width: 80%;

}

.gray-background-text {
  background-color: rgb(202, 202, 202);
  color: black;
}

.dialog-card-style {
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
}

</style>
  