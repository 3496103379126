<template>
  <v-card class="mt-3 card-size pl-3" flat>
    <v-row class="pl-2 pr-6">
      <v-col class="mt-3" cols="7">
        <h3 class="page-title">CATEGORY LIST</h3>
        <h5 class="light-gray-text">Analyse product categories in your outlet.</h5>
        <v-card-title>

        </v-card-title>
      </v-col>
      <v-col class="px-4 mt-5" cols="5">
        <v-row class="tool-bar-style">
          <v-btn class="red-text btn-border" size="x-large" variant="text" @click="addProductsPageRoute()">
            <v-icon>mdi-plus-circle</v-icon>&nbsp;&nbsp;ADD PRODUCTS
          </v-btn>
          <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
          <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-card class="px-2 pt-2 mx-1 mb-4 product-details-card" >
      <h5 class="card-h4-title px-3 mt-2 mb-4">PRODUCT CATEGORIES LIST
        <div class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-btn class="bg-red-lighten-4 text-red mr-3" variant="outlined" @click="exportPDF">
            <v-icon class="text-red">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>

          <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
            <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </div>
      </h5>

      <v-divider></v-divider>
      <v-col cols="12">
        <v-data-table
            v-model:page="page"
            :headers="headers"
            :items="filteredItems"
            :items-per-page="itemsPerPage"
            class="data-table dataTable"
            @page="fetchProducts"
            @page-count="totalPages = $event"
        >

          <template v-slot:[`item.image_path`]="{ item }">
            <div class="product-column">
              <v-row>
                <v-col cols="5">
                  <v-img
                      v-if="item.image_path && item.image_path.length > 0"
                      :src="`http://sfa.backend.celata.org/${item.image_path}`"
                      aspect-ratio="1"
                      class="thumbnail-image"
                      @click="selectImage(`http://sfa.backend.celata.org/${item.image_path}`)"
                  ></v-img>

                </v-col>
                <v-col class="d-flex align-center" cols="7">
                  <span class="product-title">{{ item.product_title }}</span>
                </v-col>
              </v-row>
            </div>
          </template>

           
          <template v-slot:[`item.productsubcatogary`]="{ item }">
            <span v-if="item.productsubcatogary && item.productsubcatogary.length">
              {{ item.productsubcatogary.map(sub => sub.sub_category_name).join(', ') }}
            </span>
          </template>

          <!-- <template v-slot:[`item.main_category`]="{ item }">
            <span>{{ getCategoryName(item.main_category) }}</span>
          </template> -->


          <template v-slot:[`item.action`]="{ item }" >
            <v-row>
              <v-btn
                  v-if="permissions.read"
                  class="mr-3 bg-blue-lighten-5 viewbtn"
                  color="blue"
                  variant="outlined"
                  @click="viewItem(item)"
              >
                <v-icon>mdi-check-circle</v-icon>&nbsp;  view
              </v-btn>
              <v-btn
                  v-if="permissions.write"
                  class="mr-3 bg-green-lighten-5 "
                  color="green"
                  icon
                  rounded
                  size="small"
                  variant="outlined"
                  @click="editItem(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                  v-if="permissions.delete"
                  class="bg-red-lighten-5"
                  color="red"
                  icon
                  rounded
                  size="small"
                  variant="outlined"
                  @click="confirmDelete(item)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-row>
          </template>

          <template v-slot:top>
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                    v-model="selectedMainCategory"
                    :items="mainCategories"
                    clearable
                    item-title="name"
                    item-value="id"
                    label="Main Category"
                    placeholder="Select main category"
                    variant="outlined"
                    @update:modelValue="fetchSubCategories()"
                ></v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="selectedSubCategory"
                  :items="subCategories"
                  :loading="productLoading"
                  clearable
                  item-title="sub_category_name"
                  item-value="sub_category_name"
                  label="Sub Category"
                  multiple
                  placeholder="Select sub category"
                  variant="outlined"
                ></v-select>

              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                    v-model="search"
                    append-inner-icon="mdi-magnify"
                    clearable
                    hide-details
                    label="Search"
                    placeholder="Search Products"
                    variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>


        </v-data-table>
        <!-- <v-row>
        <v-col cols="12" class="text-right">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="fetchProducts"
          ></v-pagination>
        </v-col>
      </v-row> -->
      </v-col>
    </v-card>
  </v-card>
  <!-- Delete product confirmation dialog -->
  <v-dialog v-model="delete_product_Catagary_dialog" max-height="500" max-width="500" persistent>
    <v-card class="pa-1">
      <v-row class="rowClr ma-1" dense>
        <v-col cols="12" md="9">
          <v-card-title class="headline">DELETE PRODUCT CATAGORY</v-card-title>
        </v-col>
        <v-col class="d-flex justify-end pt-3 pr-3" cols="12" md="3">
          <v-btn icon size="x-small" @click="delete_product_Catagary_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider class="mt-3"></v-divider>

      <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this Catagary ?</v-card-text>
      <v-card-text class="textspace">
        This product Catagary will be deleted immediately. Once deleted, it can no longer be used in any purpose.
      </v-card-text>
      <v-divider class="mb-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>&nbsp; &nbsp;
        <v-btn class="bg-grey-lighten-2" variant="outlined" @click="delete_product_Catagary_dialog = false">CANCEL</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- View Products Dialog -->
  <v-dialog v-model="view_product_dialog" fullscreen>
    <v-card class="card-size">
      <v-toolbar class="pr-5 mb-5" color="white" dense>
        <v-toolbar-title>
          <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="#b00d0d" dark variant="elevated" @click="view_product_dialog = false">
          <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
        </v-btn>
      </v-toolbar>
      <v-divider class="divider-margin-dv"></v-divider>
      <v-row class="mt-0">
        <v-col cols="12" md="12">
          <v-card class="pa-3 mx-6">
            <v-icon>mdi-view-dashboard
            </v-icon>
            <v-label class="ml-6">UPDATE PRODUCT CATEGORY</v-label>
          </v-card>
          <v-card class="pa-3 mx-6 mt-3">
            <v-row class="ma-0">
              <v-col cols="10">
                <v-row>
                  <v-col cols="2">
                    <v-img
                        :src="selectedImage ? selectedImage : ''"
                        aspect-ratio="1"
                        class="product-image-view"
                    ></v-img>

                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2">
                <v-row class="text-right pa-3">
                  <v-spacer></v-spacer>
                  <v-btn
                      v-if="permissions.write"
                      class="mr-3 bg-green-lighten-5 "
                      color="green"
                      icon
                      rounded
                      size="small"
                      variant="outlined"
                      @click="editItem(product)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                      v-if="permissions.delete"
                      class="bg-red-lighten-5"
                      color="red"
                      icon
                      rounded
                      size="small"
                      variant="outlined"
                      @click="confirmDelete(product)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-6"></v-divider>
            <v-row>
              <v-col cols="6">

                <v-text-field
                    v-model="product.main_category"
                    class="px-3 red-text-field"
                    label="Main Category"
                    readonly
                    variant="outlined"
                ></v-text-field>

                <div v-for="(subCategory, index) in product.productsubcatogary" :key="subCategory.id" class="mb-2">
                  <v-text-field
                      v-model="subCategory.sub_category_name"
                      :label="'Sub Category ' + (index + 1)"
                      class="px-3 red-text-field"
                      readonly
                      variant="outlined"
                  ></v-text-field>
                </div>

                <v-textarea
                    v-model="product.category_desc"
                    class="px-3 red-text-field"
                    label="Product Description"
                    readonly
                    variant="outlined"
                ></v-textarea>
              </v-col>

            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>



  <!-- Edit products dialog -->
  <v-dialog v-model="edit_product_dialog" fullscreen>
    <v-card class="card-size">
      <v-toolbar class="pr-5 mb-5" color="white" dense>
        <v-toolbar-title>
          <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="#b00d0d" dark variant="elevated" @click="edit_product_dialog = false">
          <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;Close
        </v-btn>
      </v-toolbar>
      <v-divider class="divider-margin-dv"></v-divider>
      <v-row class="mt-0">
        <v-col cols="12" md="12">
          <v-card class="pa-3 mx-6">
            <v-icon>mdi-view-dashboard
            </v-icon>
            <v-label class="ml-6">UPDATE PRODUCT CATEGORY</v-label>
          </v-card>
          <v-card class="pa-3 mx-6 mt-3">
            <v-row class="ma-0">
              <v-col cols="10">
                <v-row>
                  <v-col cols="2">
                    <v-img
                        :src="selectedImage ? selectedImage : ''"
                        aspect-ratio="1"
                        class="product-image-view"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2">
                <v-row class="text-right pa-3">
                  <v-spacer></v-spacer>
                  <v-btn class="red-background-text" size="large" variant="outlined" @click="updateProduct">UPDATE PRODUCT</v-btn>
                </v-row>
              </v-col>
            </v-row>

            <v-divider class="mt-3 mb-6"></v-divider>
            <v-form ref="form">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                      v-model="product.main_category"
                      :rules="[v => !!v || 'Main category is required']"
                      class="px-3 mb-5 red-text-field"
                      label="Main Category *"
                      required
                      variant="outlined"
                  ></v-text-field>

                  <v-card flat style="height:auto; margin-bottom: 10px">
                      <div
                        class="drop-area"
                        @click="triggerFileInput"
                        @drop.prevent="onDrop"
                        @dragover.prevent="onDragOver"
                      >
                        <!-- File Input -->
                        <input
                          ref="fileInput"
                          accept="image/*"
                          class="hidden-file-input"
                          type="file"
                          @change="onFileChange"
                        />

                        <!-- Image Preview -->
                        <div v-if="previewImage" class="image-card-container">
                          <v-card class="image-card">
                            <v-img
                              :alt="'Image preview'"
                              :src="previewImage"
                              class="image-preview"
                            ></v-img>
                            <v-btn
                              class="remove-icon"
                              icon
                              size="x-small"
                              @click.stop="removeImage"
                            >
                              <v-icon color="red" size="small">mdi-delete</v-icon>
                            </v-btn>
                          </v-card>
                        </div>

                        <!-- Placeholder for Image Upload -->
                        <div
                          v-if="!previewImage"
                          class="placeholder-container"
                          style="height: 150px; background-color: #f0f0f0;"
                        >
                          <v-icon size="36">mdi-cloud-upload</v-icon>
                          <span>Drag your photo here OR click to browse from device</span>
                        </div>
                      </div>
                    </v-card>



                  <div v-for="(subCategories, index) in subCategory" :key="subCategories.id" class="mb-2">
                    <v-row class="mb-2">
                      <v-col cols="10">
                        <!-- Sub Category Text Field -->
                        <v-text-field
                            v-model="subCategories.sub_category_name"
                            :label="'Sub Category ' + (index + 1)"
                            class="px-3 red-text-field"
                            placeholder="Create sub category"
                            variant="outlined"
                        ></v-text-field>
                      </v-col>

                      <!-- Add/Remove Buttons -->
                      <v-col class="align-center justify-center" cols="1">
                        <!-- Add Button -->
                        <v-btn
                            class="green-text"
                            density="default"
                            icon="mdi-plus-thick"
                            variant="outlined"
                            @click="addSubCategory"
                        ></v-btn>
                      </v-col>
                      <v-col class="align-center justify-center" cols="1">
                        <!-- Remove Button (Visible if there is more than 1 sub-category) -->
                        <v-btn
                            v-if="subCategory.length > 0"
                            class="red-text-btn"
                            density="default"
                            icon="mdi-minus-thick"
                            variant="outlined"
                            @click="removeSubCategory(index)"
                        ></v-btn>
                      </v-col>
                    </v-row>
                  </div>


                </v-col>
                <v-col cols="6">
                  <v-card flat width="100%">
                <div class="d-flex justify-space-between pa-4 pb-0">
                  <!-- Text Formatting Buttons -->
                  <v-btn-toggle v-model="formatting" divided multiple variant="outlined">
                    <v-btn @click="applyFormat('italic')">
                      <v-icon icon="mdi-format-italic"></v-icon>
                    </v-btn>

                    <v-btn @click="applyFormat('bold')">
                      <v-icon icon="mdi-format-bold"></v-icon>
                    </v-btn>

                    <v-btn @click="applyFormat('underline')">
                      <v-icon icon="mdi-format-underline"></v-icon>
                    </v-btn>

                    <!-- Text Color Option -->
                    <v-menu
                        v-model="showColorPicker"
                        close-on-content-click
                        location="end"
                    >

                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">
                          <div class="d-flex align-center flex-column justify-center">
                            <v-icon icon="mdi-format-color-text"></v-icon>
                            <v-sheet :color="selectedColor" height="4" tile width="26"></v-sheet>
                          </div>
                        </v-btn>
                      </template>

                      <!-- Color Picker -->
                      <v-color-picker
                          v-model="selectedColor"
                          flat
                          hide-canvas
                          @change="applyFormat('color')"
                      ></v-color-picker>
                    </v-menu>
                  </v-btn-toggle>

                  <!-- Text Alignment Buttons -->
                  <v-btn-toggle v-model="alignment" divided variant="outlined">
                    <v-btn @click="setAlignment('center')">
                      <v-icon icon="mdi-format-align-center"></v-icon>
                    </v-btn>

                    <v-btn @click="setAlignment('left')">
                      <v-icon icon="mdi-format-align-left"></v-icon>
                    </v-btn>

                    <v-btn @click="setAlignment('right')">
                      <v-icon icon="mdi-format-align-right"></v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </div>

                <!-- Text Area -->
                <v-sheet class="pa-4 text-center">
                  <!-- <v-textarea
                      v-model="lead.description"
                      class="mb-2"
                      label="Itineraries Description *"
                      placeholder="Write a description here..."
                      :style="textareaStyles"
                      variant="outlined"
                      :rules="[v => !!v || 'Description is required']"
                  ></v-textarea> -->
                  <v-textarea
                    v-model="product.category_desc"
                    :style="textareaStyles"
                    class="px-3 mb-2 red-text-field"
                    label="Product Description *"
                    variant="outlined"
                  ></v-textarea>
                </v-sheet>
              </v-card>

                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>

    </v-card>
  </v-dialog>
  <!-- Snackbar to show notifications -->
  <v-snackbar
      v-model="snackAlert.snackbar"
      :color="snackAlert.snackColor"
      :right="snackAlert.snackBarRight"
      :top="snackAlert.snackBarTop"
      timeout="3000"
  >
    {{ snackAlert.snackMessage }}

  </v-snackbar>

</template>

<script>
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
import {getPermissions} from "@/plugins/permissions";

export default {
  components: {

  },
  name: 'CategoryLit',
  data() {
    return {

      
formatting: [],
        showColorPicker: false,
        selectedColor: '#000',
        alignment: '',
        textareaStyles: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        textDecoration: 'none',
        color: '#000',
        textAlign: 'left',
        },

      productLoading:false,
      subCategory:[],

      form: {
        images:'',
      },

      search: '',

      selectedMainCategory: null,
      selectedSubCategory: [],

      // Pagination
      itemsPerPage: 10,
      page: 1,
      totalPages: 0,


      headers: [
        { text: 'MAIN CATEGORY IMAGE', value: 'image_path', title: 'MAIN CATEGORY IMAGE', sortable: true },
        { text: 'MAIN CATEGORY', value: 'main_category', title: 'MAIN CATEGORY', sortable: true },
        { text: 'SUB CATEGORY', value: 'productsubcatogary', title: 'SUB CATEGORY', sortable: true },
        { text: 'DESCRIPTION', value: 'category_desc', title: 'DESCRIPTION', sortable: true },
        { text: 'ACTION', value: 'action', sortable: false, title: 'ACTION' },
      ],


      // Dialog state
      delete_product_Catagary_dialog: false,
      view_product_dialog: false,
      edit_product_dialog: false,

      selectedProductId: null,
      product: {},

      numericRule: value => !isNaN(parseFloat(value)) || 'Price must be a number',

      item:[],


      mainCategories: [],
      subCategories: [],

      // List of products
      items: [],

      // Additional fields
      product_image: [], // List of images for upload

      // Image handling
      previewImage: null,
      selectedImage: '',
      permissions: {},

      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },

      productcheck: null,

      dialog: false,
      mode: 'hsla',
      modes: ['hsla', 'rgba', 'hexa'],

    };
  },
  created() {
    // this.fetchCategories();
    this.fetchProducts();
    this.fetchMainCategories();
  },
  methods: {

    applyFormat(format) {

      if (format === 'italic') {
        this.textareaStyles.fontStyle =
            this.textareaStyles.fontStyle === 'italic' ? 'normal' : 'italic';
      } else if (format === 'bold') {
        this.textareaStyles.fontWeight =
            this.textareaStyles.fontWeight === 'bold' ? 'normal' : 'bold';
      } else if (format === 'underline') {
        this.textareaStyles.textDecoration =
            this.textareaStyles.textDecoration === 'underline'
                ? 'none'
                : 'underline';
      } else if (format === 'color') {
        this.textareaStyles.color = this.selectedColor;
      }
      },

      setAlignment(align) {
      this.textareaStyles.textAlign = align;
      },

    addSubCategory() {
      // Add a new sub-category object with an empty name
      this.subCategory.push({id: Date.now(), subcatagaryName: ''});
    },
    // Remove a sub-category
    removeSubCategory(index) {
      // Remove the sub-category at the specified index
      this.subCategory.splice(index, 1);
    },

    sizeValidation(value) {
      const sizePattern = /^\d+(\.\d+)?\s*(cm|centimeter|centimeters|mm|millimeter|millimeters|in|inches|m|meter|meters|ft|feet|other)$/i;
      return sizePattern.test(value.trim());
    },
    exportExcel() {
      const tableData = this.filteredItems.map((item) => ({
       
        'PRODUCT MAIN CATEGORY NAME': item.main_category || 'N/A',
        'SUBCATEGORY': item.productsubcatogary && item.productsubcatogary.length > 0
          ? item.productsubcatogary.map(sub => sub.sub_category_name).join(', ')
          : 'N/A',
        'DESCRIPTION': item.category_desc || 'N/A',
      }));

      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Products Catagory');


      XLSX.writeFile(workbook, 'products_catagary_analysis.xlsx');
    },


    exportPDF() {
      const doc = new jsPDF();

      // Set the title for the document
      doc.text('Product Catagory Details', 14, 10);

      // Prepare the data for the table
      const tableData = this.filteredItems.map((item, index) => [
        index + 1,
        item.main_category || 'N/A',
        // Join all subcategory names into a single string
        item.productsubcatogary && item.productsubcatogary.length > 0 
          ? item.productsubcatogary.map(sub => sub.sub_category_name).join(', ') 
          : 'N/A',
        item.category_desc || 'N/A',
      ]);

      // Define the columns for the table
      const tableColumns = ['#', 'MAIN CATEGORY','SUBCATAGARY','DESCRIPTION'];

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
      });

      doc.setFontSize(10);
      doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, doc.internal.pageSize.height - 10);

      // Save the PDF with a name
      doc.save('products_catagary_analysis.pdf');

    },


    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    fetchMainCategories() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/mainProduct', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            let sample = response.data.data;
            this.mainCategories = sample.map(item => {
              return {
                id: item.id,
                name: item.main_category
              };
            });
            console.log('Main categories:', this.mainCategories);
          })
          .catch(error => {
            console.error('Error fetching main categories:', error);
          });
    },


    fetchSubCategories() {
      // if (!this.product.main_category) return;

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      this.productLoading = true;
      this.selectedSubCategory = null;
      this.product.sub_category = null;
      let mainCatagoryId = this.selectedMainCategory || this.product.main_category
      // const mainCatagoryId = this.product.main_category || this.selectedMainCategory;
      console.log('Main Category ID:', this.product.main_category);
      console.log('Main Category ID t:', this.selectedMainCategory);

      axios.post(`/api/subcatagoryProduct`,
          {
            userId: userId,
            mainCatagoryId: mainCatagoryId
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })

          .then(response => {
            console.log('API Response:', response);
            this.subCategories = response.data.data;
            console.log('Subcategories:', this.subCategories);
            // Find and set the main category name
            const selectedCategory = this.mainCategories.find(cat => cat.id === mainCatagoryId);
            this.product.main_category_name = selectedCategory ? selectedCategory.name : '';
            this.selectedMainCategory = selectedCategory ? selectedCategory.name : '';
            this.productLoading = false;
            console.log('Selected Main Category Name:', this.product.main_category_name);
          })
          .catch(error => {
            console.error('Error fetching sub categories:', error);
            this.productLoading = false;
          });
    },

    getCategoryName(categoryId) {
      console.log('Looking up categoryId:', categoryId);
      if (typeof categoryId === 'string') {
        categoryId = parseInt(categoryId, 10); // Convert string ID to number if needed
      }
      const category = this.mainCategories.find(cat => cat.id === categoryId);
      console.log('Found category:', category);
      return category ? category.name : 'Unknown';
    },

    // async updateProduct() {
    //   // // Validation code
    //   // const isValid = this.$refs.product.validate();
    //   // if (!isValid) {
    //   //   this.snackAlert.snackbar = true;
    //   //   this.snackAlert.snackColor = 'error';
    //   //   this.snackAlert.snackMessage = 'Please fill out all required fields correctly.';
    //   //   return;
    //   // }
    //   // Access the form reference and validate it
    //   // const form = this.$refs.form;
    //   // const isValid = form.validate();
    //   //
    //   // if (!isValid) {
    //   //   // If the form is not valid, show an error message and return
    //   //   this.snackAlert.snackbar = true;
    //   //   this.snackAlert.snackColor = 'error';
    //   //   this.snackAlert.snackMessage = 'Please fill out all required fields correctly.';
    //   //   return;
    //   // }
    //   // Example: validate size before updating product
    //   // const sizeInput = this.product.size; // Adjust according to your product model
    //   // if (!this.sizeValidation(sizeInput)) {
    //   //   this.snackAlert.snackbar = true;
    //   //   this.snackAlert.snackColor = 'error';
    //   //   this.snackAlert.snackMessage = 'Invalid size format.';
    //   //   return;
    //   // }
    //   // if (!this.sizeValidation(product.size)) {
    //   //   this.snackAlert.snackbar = true;
    //   //   this.snackAlert.snackColor = 'error';
    //   //   this.snackAlert.snackMessage = 'Invalid size format';
    //   //     return;
    //   //   }
    //   const formData = new FormData();
    //   formData.append('mainCatagaryId', this.product.id);
    //   formData.append('mainCatagaryName', this.product.main_category);
    //   formData.append('mainCatagaryDescription', this.product.category_desc);
    //   formData.append('subCatagory', this.product.productsubcatogary);
    //   formData.append('userId', localStorage.getItem('userId'));
    //
    //
    //   console.log('Images array:', this.product.images);
    //
    //   // Convert images to Base64 and append them to FormData
    //   try {
    //
    //     // const base64Images = await Promise.all(
    //     //   this.product.product_image.map(file => this.convertToBase64(file))
    //     // );
    //
    //     // // Debug: log the Base64 images array
    //     // console.log('Base64 Images:', base64Images);
    //
    //     // const imagesJson = JSON.stringify(base64Images.map(image => ({ image })));
    //     // formData.append('productImage', imagesJson);
    //
    //     // const base64Images = await Promise.all(
    //     //     this.form.images.map(file => this.convertToBase64(file))
    //     // );
    //
    //     // // Debug: log the Base64 images array
    //     // console.log('Base64 Images:', base64Images);
    //
    //     // const imagesJson = JSON.stringify(base64Images.map(image => ({ image })));
    //     // formData.append('productImage', imagesJson);
    //
    //     // Convert new images to base64
    //     const base64Images = await Promise.all(
    //         this.images.map(file => this.convertToBase64(file))
    //     );
    //
    //     // Collect previously existing images
    //     const previousImages = this.product.product_image.map(img => ({
    //       image: img.image_url // Assuming the backend accepts URLs for existing images
    //     }));
    //
    //     // Combine previous images and new ones
    //     const allImages = [
    //       ...previousImages,  // Old images
    //       ...base64Images.map(image => ({ image })) // New images (converted to base64)
    //     ];
    //
    //     const imagesJson = JSON.stringify(allImages);
    //     formData.append('mainCatagaryImage', imagesJson);
    //
    //     for (let [key, value] of formData.entries()) {
    //       console.log(`${key}:`, value);
    //     }
    //
    //     // Send FormData to the API
    //     const token = localStorage.getItem('token');
    //     const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/updateCatagaryDetails`, formData, {
    //       headers: {
    //         'Authorization': `Bearer ${token}`,
    //         'Content-Type': 'multipart/form-data'
    //       }
    //     });
    //
    //     if (response.data.success) {
    //       this.snackAlert.snackbar = true;
    //       this.snackAlert.snackColor = 'success';
    //       this.snackAlert.snackMessage = 'Product updated successfully';
    //       this.view_product_dialog = false;
    //       this.edit_product_dialog = false;
    //       this.fetchProducts();
    //       this.product.main_category_name='';
    //       this.selectedMainCategory='';
    //     } else {
    //       this.snackAlert.snackbar = true;
    //       this.snackAlert.snackColor = 'error';
    //       this.snackAlert.snackMessage = 'Failed to update product';
    //       this.fetchProducts();
    //     }
    //   } catch (error) {
    //     this.snackAlert.snackbar = true;
    //     this.snackAlert.snackColor = 'error';
    //     this.snackAlert.snackMessage = 'Please fill out all required fields correctly';
    //     console.error('Error updating product:', error);
    //   }
    // },

    async updateProduct() {
      const formData = new FormData();
      formData.append('mainCatagaryId', this.product.id);
      formData.append('mainCatagaryName', this.product.main_category);
      formData.append('mainCatagaryDescription', this.product.category_desc);
      formData.append('userId', localStorage.getItem('userId'));

      // Transform subCategory array by renaming sub_category_name to subcatagaryName
      const transformedSubCategory = this.subCategory.map(sub => ({
        ...sub,
        subcatagaryName: sub.sub_category_name, // Rename the field
        sub_category_name: undefined // Remove original sub_category_name
      }));

      const subCatagoryJson = JSON.stringify(transformedSubCategory);
      formData.append('subCatagaryDetails', subCatagoryJson);
      console.log('Transformed subCategory:', subCatagoryJson);

      try {
        console.log('jjjjjjj', this.form.images)
        if (this.form.images ) {
          console.log('ssssss')
          if(this.form.images){
            const base64Images = await this.convertToBase64(this.form.images);
            formData.append('mainCatagaryImage', base64Images);
          }
          
        }

        for (let [key, value] of formData.entries()) {
          console.log(`${key}:`, value);
        }

        // Send FormData to the API
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/updateCatagaryDetails`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data.success) {
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'success';
          this.snackAlert.snackMessage = 'Product updated successfully';
          this.view_product_dialog = false;
          this.edit_product_dialog = false;
          this.fetchProducts();
          this.product.main_category_name = '';
          this.selectedMainCategory = '';
        } else {
          this.snackAlert.snackbar = true;
          this.snackAlert.snackColor = 'error';
          this.snackAlert.snackMessage = 'Failed to update product';
          this.fetchProducts();
        }
      } catch (error) {
        this.snackAlert.snackbar = true;
        this.snackAlert.snackColor = 'error';
        this.snackAlert.snackMessage = 'Please fill out all required fields correctly';
        console.error('Error updating product:', error);
      }
    },


    convertToBase64(file) {
      console.log('ffffffffffffff',file);
      return new Promise((resolve, reject) => {
        if (file instanceof Blob) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        } else {
          reject(new Error('Invalid file type'));
        }
      });
    },

    homeReload() {
      window.location.href = '/dashboard';
    },

    fetchProducts() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("userId", userId);

      axios.post('/api/getCatagaryDetails', formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }

      })
          .then(response => {
            // Sort the data by created_at in descending order (newest first)
            this.items = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            // this.items = response.data.data;
            console.log('Result', response.data.data);
            // this.subCategories = response.data.subCategories;
          })
          .catch(error => {
            console.error('Error fetching categories:', error);
          });
    },

    addProductsPageRoute() {
      this.$router.push({path: '/add-products'});
    },


    viewItem(item) {
      this.selectedProductId = item.id;
      this.product = {...item};
      this.selectedImage = `http://sfa.backend.celata.org/${item.image_path}` || '';
      this.previewImages = item.product_image || [];
      this.view_product_dialog = true;
      this.subCategory = item.productsubcatogary;
      console.log("View item:", item);
      console.log("View sub:", this.subCategory);
    },

    editItem(item) {
      this.edit_product_dialog = true;
      this.selectedProductId = item.id;
      this.product = {...item}; // Create a copy of the item
      this.selectedImage = `http://sfa.backend.celata.org/${item.image_path}` || '';

      this.previewImage = item.image_path
      ? `http://sfa.backend.celata.org/${item.image_path}`
      : '';

      // this.previewImages = item.product_image || [];
      // this.previewImages = this.selectedImage;
      
      // this.previewImages = item.product_image.map(img => img.image_url) || [];


      this.subCategory = item.productsubcatogary;
      this.form.images = [];
      
      console.log('Editing item:', item);
      console.log("Edit item:", item);
      console.log("View sub:", this.subCategory);


    },

    

    confirmDelete(item) {
      this.delete_product_Catagary_dialog = true;
      this.selectedProductId = item.id;
      console.log('Deleting item:', this.selectedProductId);
    },
    deleteItem() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');
      const ProductID = this.selectedProductId;
      console.log('delete id', ProductID);

      axios.post(`/api/deleteCatagaryDetails`, {
        userId: userId,
        mainCatagaryId: ProductID,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            // Handle success - remove the deleted item from the table data
            this.fetchProducts(); // Refresh the user list
            this.delete_product_Catagary_dialog = false; // Close the confirmation dialog
            this.view_product_dialog = false;
            console.log('product deleted successfully');
            this.showSnackbar("Product Category delete successfully!", "success");
          })
          .catch((error) => {
            console.log('Error deleting Product', error);
            this.delete_product_Catagary_dialog = false;
            this.fetchProducts();
          });
    },
    backPageRoute() {
      this.$router.back();
    },
    selectImage(image) {
      this.selectedImage = image;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      const file = event.target.files[0]; // Get the first file from the input
      if (file) {
        this.previewImage = URL.createObjectURL(file); // Set the preview image
        this.handleFiles(file); // Pass the file to handleFiles
      }
    },
    onDrop(event) {
      const file = event.dataTransfer.files[0]; // Get the first file from the drop
      if (file) {
        this.previewImage = URL.createObjectURL(file); // Set the preview image
        this.handleFiles(file); // Pass the file to handleFiles
      }
    },
    onDragOver(event) {
      event.preventDefault();
    },
    removeImage() {
      this.previewImage = null;
      this.$refs.fileInput.value = null;
    },

    handleFiles(file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

      if (!validImageTypes.includes(file.type)) {
        alert('Only image files are allowed!');
        return;
      }

      if (!(file instanceof File)) {
        console.error('Not a valid File object:', file);
        return;
      }

      this.form.images = file; // Store the single image
      console.log('image test',this.form.images);
    },
  },

  mounted() {
    const dashboardId = 2;
    this.permissions = getPermissions(dashboardId);
    console.log("Permissions:", this.permissions);

    if (this.product.product_image && this.product.product_image.length > 0) {
      this.selectedImage = this.product.product_image[0];
    }
  },

  computed: {
    oldPrice() {
      const price = parseFloat(this.product.price);
      const discountRate = parseFloat(this.product.discount_rate);
      if (discountRate > 0) {
        return (price / (1 - discountRate / 100)).toFixed(2);
      }
      return price.toFixed(2); // If no discount, old price is the current price
    },
    
    filteredItems() {
      return this.items.filter(product => {
        console.log('Filtering product:', product);

        // Matches main category
        const matchesMainCategory = !this.selectedMainCategory || product.main_category === this.selectedMainCategory;

        // Matches subcategories
        const matchesSubCategory = !this.selectedSubCategory ||
          this.selectedSubCategory.length === 0 ||
          (product.productsubcatogary && product.productsubcatogary.some(sub =>
            this.selectedSubCategory.includes(sub.sub_category_name)
          ));

        // Matches search criteria
        const matchesSearch = !this.search ||
        (product.main_category && product.main_category.toLowerCase().includes(this.search.toLowerCase())) ||
        (product.productsubcatogary && product.productsubcatogary.some(sub =>
          sub.sub_category_name && sub.sub_category_name.toLowerCase().includes(this.search.toLowerCase())
        ));
        
        // Debug log the filtering results
        console.log('Matches main category:', matchesMainCategory);
        console.log('Matches subcategory:', matchesSubCategory);
        console.log('Matches search:', matchesSearch);

        // Return filtered results
        return matchesMainCategory && matchesSubCategory && matchesSearch;
      });
    }




  },

};
</script>

<style scoped>
.red-background-text {
  background-color: #f0bcbc;
  color: #BB0000;
}

.gray-background-text {
  background-color: rgb(202, 202, 202);
  color: black;
}
.product-details-card{
  height: fit-content;
  padding-bottom: 20px;
  margin-bottom: 10px;
  overflow-x: hidden;
  overflow-y: scroll !important;
  max-height: 80vh;

}

.divider-margin-dv{
  margin-top: -20px;
}
.card-size{
  width: 100% !important;
  padding: 0;
  margin-bottom: 0;
}
.page-title {
  font-weight: bold;
}

.light-gray-text{
  color: #a09e9e !important;
}

.red-text {
  color: #b00d0d;
}

.black-text{
  color:#000000 !important;
}

.gray-text {
  color: #757575 !important;
}

.data-table {
  width: 100% !important;
}

.btn-border{
  border-block-end-color: #9a1313;
}
.tool-bar-style {
  display: flex;
  justify-content: end;
}

.divider-vertical{
  color: #3c3b3b !important;
}
.card-two-title{
  color: black;
}
.v-card-title {
  font-weight: bold;
}
.red-text {
  color: #b00d0d;
}
.red-light-text {
  background-color: #f1cccc;
  color: #b00d0d;
}
.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}
.green-text {
  color: #057b0a;
  background-color: #aee8b1;
}

.product-image {
  width: 70px;
  height: 70px;
  margin-left: 10px;
  padding: 4px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  border-width: 4px;
  box-shadow: #757575;
  background-color: white;
  margin: 10px;
}

.product-image-view {
  width: 150px;
  height: 150px;
  margin-left: 0px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  border-width: 1px;
  box-shadow: #dcdcdc;
  background-color: white;
}

.thumbnail-image {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  border-width: 1px;
  box-shadow: #dcdcdc;
  background-color: white;
}

.drop-area {
  border: 2px dashed #9a1313;
  padding: 20px;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  gap: 10px;
}

.hidden-file-input {
  display: none;
}

.image-card-container {
  margin: 0 8px 8px 0;
}

.image-card {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.image-preview {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between image cards */
}

.placeholder-container {
  width: 100%;
  height: 120px;
  text-align: center;
  color: #9a1313;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.red-text-content .v-input__control .v-input__slot,
.red-text-content .v-input__control .v-input__slot .v-text-field__details,
.red-text-content .v-input__control .v-input__slot input,
.red-text-content .v-input__control .v-input__slot textarea {
  color: red !important;
}

.rowClr{
  background-color: #d9d9d9;
}

.textspace{
  margin-top: -20px;
}

.headline {
  font-size: 1.5rem;
  font-weight: bold;
}
.dialog-card-style{
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
}

.red-background-text-btn {
  background-color: #f0bcbc;
  color: #BB0000;
  margin-top: -27px;
  margin-bottom: 10px;
  margin-right: 30px;
  margin-left: 30px;
}
.viewbtn{
  font-size: 12px;
}
:deep(.dataTable) th, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  text-align: center;
  max-width: 12vw;
}

:deep(.dataTable) td, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  max-width: 12vw;
}

:deep(.dataTable) {
  border: white;
}

:deep(.dataTable )  tr,  th:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}
:deep(.dataTable )  tr,  td:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}
.remove-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: white;
  border-radius: 50%; /* This will make the background round */
  padding: 4px; /* Adjust as needed to control the icon size */
}

.remove-icon .v-icon {
  color: red;
}

.product-details-card{
  height: fit-content;
  padding-bottom: 20px;
  margin-bottom: 10px;
  overflow-x: hidden;
  overflow-y: scroll !important;
  max-height: 80vh;
}
.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: black;
}
</style>
