<template>
  <v-overlay v-model="loadningScreen" :scrim="false" class="centered-overlay">
    <v-progress-circular
      color="green"
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>
  <v-container fluid>
  <v-row class="main-heading">
    <v-col cols="12" md="11">
      <v-row>
        <v-card-title>ROUTE PLAN TRACKING HISTORY</v-card-title>
      </v-row>
      <v-row>
        <v-card-subtitle>Analyze the tracking history of sales and collections based on agents.</v-card-subtitle>
      </v-row>
    </v-col>
    <v-col cols="12" md="1">
      <v-btn class="back-button" @click="backPageRoute()">BACK</v-btn>
    </v-col>
  </v-row>

  <v-row class="sub-heading">
    <v-card class="sub-heading-card d-flex align-center" variant="flat">
      <v-col cols="12" md="4">
        <v-card-subtitle class="his-sub-heading">HISTORY</v-card-subtitle>
      </v-col>
      
      <v-row  class="card-two-title" justify="end"  >
        
        <v-btn class="red-light-text" variant="outlined" @click="exportPDF">
          <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
        </v-btn>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
          <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
        </v-btn>
      </v-row>
    </v-card>
  </v-row>

  <v-card class="main-card ">
    <v-row class="text-filed-row">
      <v-col cols="12" md="4">
        <v-date-input
              v-model="dates"
              append-inner-icon="mdi-close"
              color="red"
              label="Select Date Range"
              max-width="500"
              multiple="range"
              variant="outlined"
              @click:append-inner="clearDateRange"
          ></v-date-input>
      </v-col>

      <v-col cols="12" md="4">
        <v-select
          v-model="selectedCategory"
          :items="categories"
          clearable
          label="Category"
          placeholder="Select category"
          variant="outlined"
        ></v-select>
      </v-col>


      <v-col cols="12" md="4">
        <v-text-field
        v-model="serachModel"
        class="text-black"
        label="Search"
        persistent-placeholder
        placeholder="Search"
        variant="outlined"
      ></v-text-field>
      </v-col>

      

    </v-row>

    <v-row class="table-row">
      <v-col class="table-col" cols="12">
        <v-data-table
            :headers="headers"
            :items="filteredData"
            :loading="loading"
            :search="search"
             class="data-table"

        >

        <template v-slot:[`item.user_deateails.name`]="{ item }" >
     
            <v-row class="custom-row">
              <p>{{ item.user_deateails.name }}</p>
              <p class="register-number">{{ item.registrationNumber }}</p>
            </v-row>
          
          </template>

          <template v-slot:[`item.firstOutlet`]="{ item }" >
     
            <v-row class="custom-row">
              <p>{{ item.firstOutlet }}</p>
              <p class="register-number">{{ item.trackingStartTime }}</p>
            </v-row>
          
          </template>

          <template v-slot:[`item.lastOutlet`]="{ item }" >
     
            <v-row class="custom-row">
              <p>{{ item.lastOutlet }}</p>
              <p class="register-number">{{ item.tracingEndTime }}</p>
            </v-row>
          
          </template>

        <template v-slot:[`item.visiteDateCheck`]="{ item }" >
            <v-row>
            <v-col class="mt-2" cols="12" md="12">
              <p>Visited Date : {{ item. visiteDate}}</p>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" md="12">
            <p class="text-red">Assign Date : {{ item.route_Deateails.assign_date }}</p>
            </v-col>
            </v-row>
          </template>


          <template v-slot:[`item.actions`]="{ item }" >
            <v-row>
              <v-col  cols="5">
                <v-btn
                    v-if="permissions.read"
                    class="mr-2 bg-blue-lighten-4"
                    color="blue"
                    icon
                    rounded="lg"
                    size="small"
                    variant="outlined"
                    width="210"
                    @click="mapView(item)"
                >
                  <v-icon class="mr-2">mdi-check-circle</v-icon>MAP VIEW
                </v-btn>
              </v-col>
              <v-col class=" ml-0" cols="4">
                <v-btn
                    v-if="permissions.delete"
                    class="bg-red-lighten-4"
                    color="red"
                    icon
                    rounded
                    size="small"
                    variant="outlined"
                    @click="confirmDelete(item)"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>

        <!-- Delete Confirmation Dialog -->
        <v-dialog v-model="deleteDialog" max-height="500" max-width="500" persistent>
          
          <v-card class="pa-1">
            <v-row>
              <v-col cols="12" md="10">
                <v-card-title class="headline">DELETE TRACKING DATA</v-card-title>
              </v-col>
              <v-col class="d-flex justify-end" cols="12" md="2">
                <v-btn class="mt-3" size="x-small" @click="deleteDialog = false">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this tracking record ?</v-card-text>
            <v-card-text class="font-weight-medium">
              This tracking record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>
              <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteDialog = false">CANCEL</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-col>
    </v-row>

    <!--  collection recode edit view component call to dialog box-->
    <v-dialog v-model="mapViewDialog" class="solid-dialog" fullscreen persistent>
      <v-card>
        <v-row class="first-row-dialog" >
          <v-col class="d-flex justify-end" cols="12" md="2">
            <v-toolbar-title class="mt-1 ml-6" >
              <img  :src="require('@/assets/app_bar_logo.png')" alt=""/>
            </v-toolbar-title>
          </v-col>
          <v-col class="d-flex justify-end" cols="12" md="10">
            <v-btn class="closeC bg-red mr-2 justify-center font-weight-thin mt-3 mr-3" color="white" height="30" icon rounded="lg" variant="text" width="90" @click="closeD" >
              <v-icon>mdi-close-circle-outline</v-icon> CLOSE
            </v-btn>
          </v-col>
          <v-row >
            <v-col class="fc" cols="12" >
              <v-card>
                <v-icon>mdi-view-dashboard
                </v-icon>
                <v-label class="ml-5 ">MAP VIEW</v-label>
              </v-card>
            </v-col >
          </v-row>
        </v-row>

        <v-row ref="content">
          <v-col cols="12">
            <v-card  style="height: auto;width: 100%;margin-top: 15px ">
              <v-row>
                <v-col class="d-flex justify-center align-center" cols="12" md="3">
                  <v-img
                      :src="OfficerDetails.user_image ? `${baseUrl}/SFA/public/${OfficerDetails.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')" 
                      class="rounded-circle"
                      max-width="150"
                  ></v-img>
                </v-col>
                <v-col cols="12" md="3">
                  <v-col class="mt-6 mb-3">
                    <div>Agent : {{ this.agentName  }}</div>
                    <v-divider></v-divider>
                    <div>Route : {{ this.routeName  }}</div>
                    <v-divider></v-divider>
                    <div>Assign Date : {{ this.assignDateRoute }}</div>
                    <v-divider></v-divider>
                    <div>Visited Date : {{ this.visitedDate }}</div>
                    <v-divider></v-divider>
                    <div>Total distance : {{ this.totalDistance  }} KM</div>
                    <v-divider></v-divider>
                    <div>Catagary : {{  this.routeCatagary  }}</div>
                    <v-divider></v-divider>
                  </v-col>
                </v-col>


                <!-- PDF Download Button -->
                <v-col class="d-flex justify-end align-center" cols="12" md="5">
                  <v-btn class="red-light-text" variant="outlined"  @click="downloadPdf">
                    <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;DownLoad PDF
                  </v-btn>
                  <!-- <v-btn
                    class="mr-5 bg-red-lighten-4"
                    icon
                    rounded="lg"
                    size="small"
                    style="margin-top: 30vh; margin-left: 10vw;"
                    @click="exportPDF"
                  >
                    <v-icon class="mr-2 bg-red">mdi-file-pdf</v-icon> Download PDF
                  </v-btn> -->
                </v-col>

              </v-row>
            </v-card>
          </v-col>
        </v-row>



        <v-row ref="mapElement" class="mt-0">
          <!--history map view-->
          <v-card class="pa-5" width="100%" >
            <v-row>
              <v-col cols="8">
                <GMapMap
                    :center="center"
                    :zoom="6.5"
                    style="height: 500px; width: 100%;"
                >
                  <GMapPolyline :options="polylineOptions" :path="pathCoordinates"  />

                  <!-- Start Point Marker -->
                  <GMapMarker
                      :icon="startIcon"
                      :position="startPoint"
                      :title="'Start Point'"
                  />

                  <!-- End Point Marker -->
                  <GMapMarker
                      :icon="endIcon"
                      :position="endPoint"
                      :title="'End Point'"
                  />

                  <!-- Outlet Marker -->
                  <GMapMarker
                    v-for="(coordinate, index) in pathCoordinatesOutlet"
                    :key="index"
                    :icon="outletIcon"
                    :position="coordinate"
                    :title="'OutLets'"
                  />

                </GMapMap>
              </v-col>
              <v-col cols="4">
                <v-card class="dataCard" flat style="height: auto; width: 100%;">
                  <v-card-text>
                    <!-- Agent Name -->
                    <div class="font-weight-bold mb-4" style="font-size: 14px; color: red">
                      JOURNEY DETAILS
                    </div>

                    <!-- Timeline -->
                    <v-timeline align="start" density="compact">
                      <!-- First Outlet Timeline Item -->
                      <v-timeline-item dot-color="red" size="x-small">
                        <v-row>
                          <v-col cols="1">
                            <v-icon color="red">mdi-check-circle-outline</v-icon>
                          </v-col>
                          <v-col cols="11">
                            <v-card-title class="mb-1" style="font-size: 12px;">
                              {{ startLocation}}
                            </v-card-title>
                            <v-card-subtitle class="mb-1" style="font-size: 12px;">
                              {{ fristOutletAddress }}
                            </v-card-subtitle>
                            <v-card-subtitle class="mb-1" style="font-size: 12px;">
                              {{ trackingStartTime }} | <v-chip size="small" style="background-color: blue ; color: white">Start Location</v-chip>
                            </v-card-subtitle>
                            <v-divider></v-divider>
                          </v-col>
                        </v-row>
                      </v-timeline-item>

                      <!-- Second Outlet Timeline Item -->
                      <v-timeline-item class="mt-0" dot-color="red" size="x-small">
                        <v-row>
                          <v-col cols="1">
                            <v-icon color="red">mdi-check-circle-outline</v-icon>
                          </v-col>
                          <v-col cols="11">
                            <v-card-title class="mb-1" style="font-size: 12px;">
                              {{ endLocation }}
                            </v-card-title>
                            <v-card-subtitle class="mb-1" style="font-size: 12px;">
                              {{ lastOutletAddress}}
                            </v-card-subtitle>
                            <v-card-subtitle class="mb-1" style="font-size: 12px;">
                              {{ tracingEndTime }} | <v-chip size="small" style="background-color: green ; color: white">End Location</v-chip>
                            </v-card-subtitle>
                            <v-divider></v-divider>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                  </v-card-text>
                </v-card>

                <v-card>

                </v-card>

              </v-col>

            </v-row>
          </v-card>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>

  </v-container>
</template>

<script>

// import { Map, MapMarker, MapPolyline  } from '@fawmi/vue-google-maps';
import index from "vuex";
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// import html2pdf from 'html2pdf.js';
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import {getPermissions} from "@/plugins/permissions";



export default {
  
  components: {
    // Map, MapPolyline, MapMarker
  },
  data() {
    return {
      loading:false,
      loadningScreen:false,
      agentName:"",
      totalDistance:"",
      startTime:"",
      routeName:'',
      assignDateRoute:'',
      routeCatagary:'',
      visitedDate:'',
      baseUrl:"",
      endTime:"",
      trackingStartTime:"",
      tracingEndTime:"",
      startLocation:"",
      endLocation:"",
      lastOutletAddress:"",
      fristOutletAddress:"",
      categories: ['sales', 'Collection'],
      selectedCategory:"",
      dates: [],
      serachModel:"",
      firstOutlet:"",
      secondOutlet:"",
      search: ' ',
      deleteDialog: false,
      itemToDelete: null,
      deletedDate:"",
      deletedType:"",
      routeId:"",
      mapViewDialog: false,
      
      headers: [
        {text: 'AGENT NAME', value: 'user_deateails.name', title: 'AGENT NAME',},
        {text: 'Route NAME', value: 'route_Deateails.route_name', title: 'ROUTE NAME',},
        {text: 'CATEGORY', value: 'type', title: 'CATEGORY'},
        {text: 'START LOCATION', value: 'firstOutlet', title: 'START LOCATION'},
        {text: 'END LOCATION', value: 'lastOutlet', title: 'END LOCATION'},
        {text: 'DATE', value: 'visiteDateCheck', title: 'DATE'},
        {text: 'Action', value: 'actions', sortable: false, title: 'ACTION' }
      ],
     trackingData:[],
     trackingHistary:[],
     OfficerDetails:"",

     center: { lat: 7.375783804167988, lng: 80.63752981355924 }, 
      pathCoordinates: [
        { lat: "", lng: "" },
      ],

      pathCoordinatesOutlet: [
        { lat: "", lng: "" },
      ],
      polylineOptions: {
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 3,
      },
      startPoint:"",
      endPoint:"",
      startIcon: {
        url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png', 
      },
      endIcon: {
        url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
      },
      outletIcon: {
        url: require('@/assets/ICONS/maker.png'),
        scaledSize: {
          width: 35, 
          height: 35, 
        },
      },

      outLetIcon:{
        url: require('@/assets/ICONS/maker.png'),
        scaledSize: {
          width: 35, 
          height: 35, 
        },
      },
     agents: [
        {
          location: 'Mawaramandiya',
          lastUpdated: '30 seconds ago',
          locAddress: 'bla blaa blaaaaa',
          locationType: 'startLocation',
          trips: [
            { id: 1, destination: 'Keells - Edmonton', time: '09:16 AM' },
            { id: 2, destination: 'Keells - Kohuwala', time: '10:30 AM' },
          ],
        },
      ],

      mapCenter: { lat: 6.023129913557325, lng: 80.79783798218944 },
      permissions: {},

    };
  },
  computed: {
    
    index() {
      return index
      
    },

    filteredData() {

      let filtered = this.trackingData;

     
      // Filter by category
      if (this.selectedCategory) {
        filtered = filtered.filter(item =>
          item.type.toLowerCase().includes(this.selectedCategory.toLowerCase())
        );
      }

      
     // Filter by search model (agent name or type)
     if (this.serachModel) {
        filtered = filtered.filter(item =>
          item.user_deateails.name.toLowerCase().includes(this.serachModel.toLowerCase()) ||
          item.type.toLowerCase().includes(this.serachModel.toLowerCase()) ||
          item.route_Deateails.route_name.toLowerCase().includes(this.serachModel.toLowerCase())
        );
      }

       // Filter by date range if `dates` contains two dates
       const formatDate = (date) => {
              const d = new Date(date);
              return d.toISOString().split('T')[0];
            };

            // Get start and end dates from the date picker
            const startDate = this.dates.length ? formatDate(this.dates[0]) : null;
            const endDate = this.dates.length ? formatDate(this.dates[this.dates.length - 1]) : null;

            // Apply date range filter only if startDate and endDate are set
            if (startDate && endDate) {
              filtered = filtered.filter(item => {
                return item.visiteDate >= startDate && item.visiteDate <= endDate;
              });
            }
      return filtered;
      
    }
  },
  mounted(){
    const dashboardId = 7;
    this.permissions = getPermissions(dashboardId);
    console.log("Permissions:", this.permissions);

    this.getAllTracking();
    this.getBaseUrl();
  },
  methods: {

    backPageRoute(){
      this.$router.push('/dashboard');
    },


    getBaseUrl(){
      this.baseUrl = `${process.env.VUE_APP_API_BASE_URL}`;
    },

    downloadPdf() {

      this.$nextTick(() => {
        const mapElement = this.$refs.mapElement?.$el || this.$refs.mapElement;

        if (mapElement instanceof HTMLElement) {
          html2canvas(mapElement, {
            useCORS: true, // Use CORS to allow cross-origin tiles to be captured
            logging: true,
            scale: 2 // Increase the scale to get better resolution
          }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
              orientation: 'landscape', // You can adjust this based on your map size
              unit: 'pt',
              format: [canvas.width, canvas.height] // Use the exact size of the canvas for PDF format
            });

            // Add the captured image to the PDF
            pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

            // Save the PDF
            pdf.save('routePlanTracking.pdf');
          }).catch((error) => {
            console.error('Error capturing the map:', error);
          });
        } else {
          console.error('Map element is not attached or is not a valid node.');
        }
      });

      // const element = this.$refs.content; 
      // const options = {
      //   margin: 1,
      //   filename: 'webpage.pdf',
      //   image: { type: 'jpeg', quality: 0.98 },
      //   html2canvas: { scale: 2 },
      //   jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      // };
      // html2pdf().from(element).set(options).save();
    },
    
    exportPDF() {
      const doc = new jsPDF();

      // Set the title for the document
      doc.text('Tracking report', 14, 10);

      // Prepare the data for the table
      const tableData = this.trackingData.map((item, index) => [
        index + 1,
        item.user_deateails.name,
        item.firstOutlet,
        item.lastOutlet,
        item.type,
        item.visiteDate,
      ]);

      console.log('pdfdata111', tableData);

      // Define the columns for the table
      const tableColumns = ['#', 'AGENT NAME', 'START LOCATION', 'END LOCATION', 'TRACKING TYPE', 'VISITED DATE'];

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
      });

      // Save the PDF with a name
      doc.save('RoutePlan tracking Histary.pdf');
    },

    clearDateRange() {
      this.dates = [];
      },

    exportExcel() {
      const tableData = this.trackingData.map((item) => ({
        // '#': index + 1,
        'AGENT NAME': item.user_deateails.name,
        'START LOCATION': item.firstOutlet,
        'END LOCATION': item.lastOutlet,
        'TRACKING TYPE': item.type,
        'VISITED DATE': item.visiteDate
      }));

      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Tracking');


      XLSX.writeFile(workbook, 'Tracking.xlsx');
    },
  

    // Get All Tracing Historay
        getAllTracking() {
          const userId = localStorage.getItem('userId');
          const token = localStorage.getItem('token');
          this.loading = true;

          axios.post(`api/getTrackingDetials`, {
            userId: userId,
          }, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            this.trackingData = response.data.data;
            console.log('fkfkfkTracking112222',this.trackingData);
            this.loading = false;
         
        })
        .catch(error => {
            console.error('Error fetching Agents:', error);
            this.loading = false;
        });

        },

        getOutletDeteails(firstId,lastId){

          const userId = localStorage.getItem('userId');
          const token = localStorage.getItem('token');

          axios.post(`api/getOutletDetails`, {
            userId: userId,
            firstId:firstId,
            lastId:lastId,
          }, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            this.firstOutlet = response.data.first;
            this.secondOutlet = response.data.first;
        })
        .catch(error => {
            console.error('Error fetching Agents:', error);
        });

        },

      mapView(item) {

      console.log('item', item);
      let agentId = item.agent_id;
      let routeId = item.route_id;
      let type = item.type;


      this.agentName = item.user_deateails.name;
      this.startTime = item.trackingStartTime

      this.routeName = item.route_Deateails.route_name;
      this.assignDateRoute = item.route_Deateails.assign_date;
      this.visitedDate = item.visiteDate;
      this.routeCatagary = item.type;
     
      this.endTime = item.tracingEndTime;
      this.startLocation = item.firstOutlet;
        this.endLocation = item.lastOutlet;
        this.fristOutletAddress = item.fristOutletAddress;
        this.lastOutletAddress = item.lastOutletAddress;

        this.trackingStartTime = item.trackingStartTime;
        this.tracingEndTime = item.tracingEndTime;

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`api/getTrackingHistary`, {
        userId: userId,agentId:agentId,routeId:routeId,type:type
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          
          this.trackingHistary = response.data.data;

          console.log('tracking data',this.trackingHistary);
          this.OfficerDetails= response.data.userDetails;
          console.log('userdetails',this.OfficerDetails);
          this.mapViewDialog = true;

          if (this.trackingHistary.length > 0) {
          

          this.pathCoordinates = this.trackingHistary.map(point => ({
            lat: point.latitude,
            lng: point.longitude,
          }))

          this.totalDistance = this.calculateTotalDistance(this.pathCoordinates);

          // Update the map center to the first point in the history
          this.mapCenter = {
            lat: this.pathCoordinates[0].latitude,
            lng: this.pathCoordinates[0].longitude
          };

          this.startPoint = this.pathCoordinates[0];

          this.endPoint = this.pathCoordinates[this.pathCoordinates.length - 1];

          // ---------------------------------------------------------------------------------
            let outletIds = [];
            let outlets = item.FullDetails.filter(item => item.outlet_id !== null);
            console.log('outeltData',outlets);

            this.pathCoordinatesOutlet = outlets.map(point => ({
            lat: point.latitude,
            lng: point.longitude,
            }))
            console.log('outeltData1111',this.pathCoordinatesOutlet);


              outlets.forEach(outlet => {
               outletIds.push(outlet.outlet_id);
              });

              const firstId = outletIds[0];
              const lastId = outletIds[outletIds.length - 1];
              this.getOutletDeteails(firstId,lastId)
              outletIds = []


        } else {
            console.warn('No tracking data available');
          }
          
        })
      .catch(error => {
          console.error('Error fetching Agents:', error);
      });

      

    },

    // Function to calculate total distance between the path coordinates using Haversine formula
    calculateTotalDistance(pathCoordinates) {
      // Function to convert degrees to radians
      const toRadians = (deg) => deg * (Math.PI / 180);

      // Haversine formula
      const haversineDistance = (lat1, lng1, lat2, lng2) => {
        const R = 6371; // Radius of the Earth in kilometers
        const dLat = toRadians(lat2 - lat1);
        const dLng = toRadians(lng2 - lng1);

        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                  Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
                  Math.sin(dLng / 2) * Math.sin(dLng / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
        return distance;
      };

      let totalDistance = 0;

      // Loop through pathCoordinates and sum the distance between consecutive points
      for (let i = 0; i < pathCoordinates.length - 1; i++) {
        const point1 = pathCoordinates[i];
        const point2 = pathCoordinates[i + 1];

        totalDistance += haversineDistance(point1.lat, point1.lng, point2.lat, point2.lng);
      }

      return totalDistance;
    },
    closeD(){
      this.mapViewDialog = false;
    },


    confirmDelete(item) {
        this.itemToDelete = item.agent_id;
        this.routeId = item.route_id;
        this.deletedType = item.type;
        this.deletedDate = item.visiteDate;

        this.deleteDialog = true;
      },
      deleteItem() {
       
        this.loadningScreen =true;
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        console.log('fk')

        
  
        axios.post(`api/deletRoutePlanTrackingDetails`, {
          userId: userId,trackingId:this.itemToDelete,deletedType:this.deletedType,deletedDate:this.deletedDate,routeId:this.routeId,
          }, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            this.deleteDialog = false;
            console.log("deleted",response.data);
            // this.showSnackbar("Product delete successfully!", "success");
            this.getAllTracking();
            this.loadningScreen = false;
           
          })
          .catch(error => {
            console.error('Error fetching Agents:', error);
            this.loadningScreen = false; 
          });
        
        
      },

      showSnackbar(message, color) {
        this.snackAlert.snackbar = true;
        this.snackAlert.snackMessage = message;
        this.snackAlert.snackColor = color;
      },
     
   
  },

  
  
}
</script>

<style scoped>

.centered-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.main-heading {
  height: 10vh;
  padding: 15px;
  margin: 0;
  display: flex;
}

.back-button {
  margin-right: 30px;
  padding: 5px;
}

.sub-heading {
  height: 10vh;
  padding: 10px;
  margin: 0;
}
.map {
  width: 100%;
  height: 100%;

}
.product-details-card{
    height: 100%;
  }
    


.card-two-title{
    color: black;
    height: 100%;
    width:100%;
    margin-right: 20px;
  }

.red-light-text {
    background-color: #f1cccc;
    color: #b00d0d;
  }
 .red-text {
    color: #b00d0d;
  }

.green-light-text {
    background-color: #aee8b1;
    color: #057b0a;
  }
  .green-text {
    color: #057b0a;
    background-color: #aee8b1;
  }

  .register-number {
  font-size: 14px;
  color: red; 
}

.sub-heading-card {
  width: 100vw;
  height: 100%;
  padding: 10px;
}

.main-card{
  height: 100%;
  overflow-y: scroll;
  max-height: 65vh;
  margin: 10px;
  padding-bottom: 10px;
}
.text-filed-row{
  height:13vh;
  padding: 20px;
}
.table-row{
  height: 70vh;
}
.table-col{
  height: 95%;
}
.data-table{
  height: 90%;
  border-radius: 10px;
  border: 1px solid rgb(227, 229, 231);
  margin: 20px;
  display: flex;
  justify-content: center;
  justify-items: center;

}
.fc{
  margin-left: 40px;
}
.first-row-dialog{
  height: 5vh;
}
:deep(.data-table) th , td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
}

:deep(.data-table) td, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
}

.custom-row {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
}

:deep(.data-table) {
  border: white;
}

:deep(.data-table )  tr,  th:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}
:deep(.data-table )  tr,  td:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}


</style>



