<template>
  <v-overlay v-model="centerLoading" :scrim="false" class="centered-overlay">
    <v-progress-circular
        color="green"
        indeterminate
        size="64"
    ></v-progress-circular>
  </v-overlay>

  <v-card class="mt-3 pl-3 card-size" flat>
    <v-row class="pl-2 pr-6">
      <v-col class="mt-3" cols="6">
        <h3 class="page-title">REGISTERED USER LIST</h3>
        <h5 class="light-gray-text">Analyse already registered user data.</h5>
        <v-card-title>

        </v-card-title>
      </v-col>
      <v-col class="px-4 mt-5" cols="6">
        <v-row class="tool-bar-style">
          <v-btn class="red-text" size="x-large" variant="text" @click="createUserRole()">
            <v-icon>mdi-account-star</v-icon>&nbsp;&nbsp;CREATE USER ROLE
          </v-btn>
          <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
          <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
            <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-card class="px-2 pt-2 mx-1 mb-2 leads-details-card" elevation="3">
      <!-- <h5 class="card-two-title px-3 mt-2 mb-4">USERS DATA ANALYSIS</h5> -->
      <h5 class="card-h4-title px-3 mt-2 mb-4">USERS DATA ANALYSIS
        <div class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-btn class="red-light-text mr-3" variant="outlined" @click="exportPDF">
            <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>

          <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
            <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </div>
      </h5>
      <v-divider></v-divider>
      <v-row class="px-3 pt-6">
        <v-col cols="4">
          <v-select
              v-model="userCatagory"
              :items="userCatagaryItems"
              clearable
              item-title="name"
              item-value="name"
              label="User Catagory"
              multiple
              placeholder="User Type"
              variant="outlined"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
              v-model="userRole.userType"
              :items="userTypes"
              clearable
              item-title="name"
              item-value="name"
              label="User Role Type"
              multiple
              placeholder="User Type"
              variant="outlined"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
              v-model="search"
              append-inner-icon="mdi-magnify"
              clearable
              hide-details
              label="Search"
              placeholder="Search"
              variant="outlined"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-col cols="12">
        <v-card class="mt-3 card-size" flat>
          <v-data-table
              v-model:page="page"
              :headers="headers"
              :items="filteredItems"
              :items-per-page="itemsPerPage"
              class="data-table"
              @page-count="totalPages = $event"
          >
            <!-- Template for mobile_number -->
            <template v-slot:[`item.mobile_number`]="{ item }">
              <div>
                <!-- <v-chip
                  v-for="(mobile, index) in item.mobile_number"
                  :key="index"
                  :size="x-small"
                  color="#CBAB04"
                  outlined
                  label
                  class="mp-0 my-1"
                >
                  {{ mobile.mobile_number }}
                </v-chip> -->
                <v-col
                    v-for="(mobile, index) in item.mobile_number"
                    :key="index"
                    cols="12"
                >
                  <v-chip
                      :size="x-small"
                      class="px-1"
                      label
                      variant="text"
                  >
                    {{ mobile.mobile_number }}
                  </v-chip>
                </v-col>
              </div>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-row>
                <v-col cols="4">
                  <v-btn
                      v-if="permissions.read"
                      class="mr-3 bg-blue-lighten-5 viewbtn"
                      color="blue"
                      variant="outlined"
                      @click="viewItem(item)"
                  >
                    <v-icon>mdi-eye</v-icon>&nbsp; view
                  </v-btn>
                </v-col>

                <v-col cols="2">
                  <v-btn
                      v-if="permissions.write"
                      class="bg-green-lighten-5"
                      color="green"
                      icon
                      rounded
                      size="small"
                      variant="outlined"
                      @click="editItem(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="2">
                  <v-btn
                      v-if="permissions.delete"
                      class="bg-red-lighten-5"
                      color="red"
                      icon
                      rounded
                      size="small"
                      variant="outlined"
                      @click="confirmDelete(item)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="2">
                  <v-btn
                      v-if="permissions.write"
                      class="gray-icon-button"
                      color="gray"
                      icon
                      rounded
                      size="small"
                      variant="outlined"
                      @click="lockItem(item)"
                  >
                    <v-icon>mdi-lock-reset</v-icon>
                  </v-btn>
                </v-col>

              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Delete user confirmation dialog -->
      <v-dialog v-model="delete_user_dialog" max-height="500" max-width="500" persistent>
        <v-card class="pa-1">
          <v-row class="rowClr ma-1" dense>
            <v-col cols="12" md="9">
              <v-card-title class="headline">DELETE USER DATA</v-card-title>
            </v-col>
            <v-col class="d-flex justify-end pt-3 pr-3" cols="12" md="3">
              <v-btn icon size="x-small" @click="delete_user_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-divider class="mt-3"></v-divider>

          <v-card-text class="text-grey-darken-2">
            <v-icon class="mr-2" color="orange">mdi-alert</v-icon>
            Do you want to delete this user ?
          </v-card-text>
          <v-card-text class="textspace">
            This user will be deleted immediately. Once deleted, it can no longer be used in any purpose.
          </v-card-text>
          <v-divider class="mb-3"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>&nbsp;
            &nbsp;
            <v-btn class="bg-grey-lighten-2" variant="outlined" @click="delete_user_dialog = false">CANCEL</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- VIEW USER DIALOG -->
      <v-dialog v-model="view_user_dialog" fullscreen>
        <v-card class="card-size">
          <v-toolbar class="pr-5 mb-5" color="white" dense>
            <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="#b00d0d" dark variant="elevated" @click="viewuserdialogClose">
              <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
            </v-btn>
          </v-toolbar>
          <v-divider class="divider-margin-dv"></v-divider>
          <v-row class="mt-0">
            <v-col cols="12" md="12">
              <v-card class="pa-3 mx-6">
                <v-icon>mdi-view-dashboard
                </v-icon>
                <v-label class="ml-6">USER DETAILS</v-label>
              </v-card>
              <v-card class="pa-3 mx-6 mt-3">
                <v-row class="first_row ma-0 pa-0 ">
                  <v-col class="secondC textCenter" cols="12">

                    <div class="image card-h4-title">
                      <!--                      :src="`http://127.0.0.1:8000/${user.user_image}`"-->
                      <v-img
                          :src="`http://sfa.backend.celata.org/SFA/public/${user.user_image}`"
                          aspect-ratio="1"
                          class="thumbnail-image"
                      ></v-img>
                    </div>

                    <div class="mt-6 ml-6">
                      <v-row>
                        <h3 class="dataCard">Full name: </h3>
                        <h3 class="dataCard text-red">&nbsp; {{ user.name }}</h3>
                      </v-row>
                      <v-row class="mt-6">
                        <h5>Mobile : &nbsp; {{ user.mobile_number.map(num => num.mobile_number).join(', ') }}</h5>
                      </v-row>
                      <v-row class="mt-6">
                        <v-chip class="mr-3 chip1clr" color="green" text-color="white" variant="outlined">
                          <v-icon color="green">mdi-check-circle-outline</v-icon>&nbsp;
                          USER TYPE - {{ user.user_type }}
                        </v-chip>
                      </v-row>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="justify-right align-right ml-6">
                      <v-btn
                          v-if="permissions.write"
                          class="bg-green-lighten-5 mr-4"
                          color="green"
                          icon
                          rounded
                          size="small"
                          variant="outlined"
                          @click="editItem(user)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                          v-if="permissions.delete"
                          class="bg-red-lighten-5"
                          color="red"
                          icon
                          rounded
                          size="small"
                          variant="outlined"
                          @click="confirmDelete(user)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>

                <v-divider class="my-3"></v-divider>
                <v-row class="pt-6">
                  <v-col class="px-6" cols="6">
                    <!-- <v-card flat class="pt-2 card-hight-fixed"> -->
                    <v-text-field
                        v-model="user.user_name"
                        class="mb-2"
                        label="User Name"
                        readonly
                        variant="outlined"
                    ></v-text-field>

                    <v-text-field
                        v-model="user.user_type"
                        :rules="[v => !!v || 'User role is required']"
                        class="mb-2"
                        label="User Type *"
                        outlined
                        placeholder="Select User Type Here"
                        variant="outlined"
                        @click="openViewDashBoardSeletDialog"
                    ></v-text-field>

                    <v-text-field
                        v-model="user.user_catagory.category"
                        class="mb-2"
                        label="User Catagary *"
                        outlined
                        variant="outlined"
                        readonly
                    ></v-text-field>

                    <v-text-field
                        v-model="user.province"
                        class="mb-2"
                        label="Province"
                        readonly
                        variant="outlined"
                    ></v-text-field>

                    <v-text-field
                        v-model="user.district"
                        class="mb-2"
                        label="District"
                        readonly
                        variant="outlined"
                    ></v-text-field>

                    <v-text-field
                        v-model="user.dsd"
                        class="mb-2"
                        label="DSD"
                        readonly
                        variant="outlined"
                    ></v-text-field>

                    <v-text-field
                        v-model="user.gnd"
                        class="mb-2"
                        label="GND"
                        readonly
                        variant="outlined"
                    ></v-text-field>

                    <v-text-field
                        v-model="user.email"
                        class="mb-2"
                        label="Email Address"
                        readonly
                        variant="outlined"
                    ></v-text-field>

                    <!-- </v-card> -->
                  </v-col>
                  <v-col class="px-6" cols="6">
                    <!-- <v-card flat class="pt-2 card-hight-fixed"> -->
                    <v-text-field
                        v-model="user.nic"
                        class="mb-2"
                        label="NIC"
                        readonly
                        variant="outlined"
                    ></v-text-field>

                    <v-text-field
                        v-model="user.registration_num"
                        class="mb-2"
                        label="Registration Number"
                        readonly
                        variant="outlined"
                    ></v-text-field>


                    <div v-for="(mobile, index) in user.mobile_number" :key="index">
                      <!-- <v-col cols="12"> -->
                      <v-text-field
                          v-model="mobile.mobile_number"
                          :label="'Mobile Number ' + (index + 1)"
                          class="mb-2"
                          readonly
                          variant="outlined"
                      ></v-text-field>
                      <!-- </v-col> -->
                    </div>

                    <v-card-text style="size: 10px;">
                      <div class="font-weight-bold ms-1 mb-2">GEOFENCE AVAILABILITY</div>

                      <v-timeline v-if="user.route_plan_geofence === 1" align="start" density="compact">
                        <v-timeline-item
                            dot-color="red"
                            size="x-small"
                        >
                          <div class="mb-4">
                            <div class="font-weight-normal">
                              <strong> Route Plan Geofence</strong>
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>

                      <v-timeline v-if="user.my_route_geofence === 1" align="start" density="compact">
                        <v-timeline-item
                            dot-color="red"
                            size="x-small"
                        >
                          <div class="mb-4">
                            <div class="font-weight-normal">
                              <strong> My Route GeoFence</strong>
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>

                      <v-timeline v-if="user.collection_geofence === 1" align="start" density="compact">
                        <v-timeline-item
                            dot-color="red"
                            size="x-small"
                        >
                          <div class="mb-4">
                            <div class="font-weight-normal">
                              <strong> Collection Geofance</strong>
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>

                      <v-timeline v-if="user.sales_geofence === 1" align="start" density="compact">
                        <v-timeline-item
                            dot-color="red"
                            size="x-small"
                        >
                          <div class="mb-4">
                            <div class="font-weight-normal">
                              <strong> Sales Geofence</strong>
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>

                      <v-timeline v-if="user.overall_tracking === 1" align="start" density="compact">
                        <v-timeline-item
                            dot-color="red"
                            size="x-small"
                        >
                          <div class="mb-4">
                            <div class="font-weight-normal">
                              <strong> Overall Tracking</strong>
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>

                      <v-timeline v-if="user.my_route_tracking === 1" align="start" density="compact">
                        <v-timeline-item
                            dot-color="red"
                            size="x-small"
                        >
                          <div class="mb-4">
                            <div class="font-weight-normal">
                              <strong>My Route Tracking</strong>
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>

                      <v-timeline v-if="user.route_plan_tracking === 1" align="start" density="compact">
                        <v-timeline-item
                            dot-color="red"
                            size="x-small"
                        >
                          <div class="mb-4">
                            <div class="font-weight-normal">
                              <strong>Route Plan Tracking</strong>
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>

                    </v-card-text>


                    <v-text-field
                        v-model="user.gender"
                        class="mb-2"
                        label="Gender"
                        readonly
                        variant="outlined"
                    ></v-text-field>

                    <v-textarea
                        v-model="user.address"
                        class="mb-2"
                        label="Address"
                        readonly
                        rows="3"
                        variant="outlined"
                    ></v-textarea>
                    <!-- </v-card> -->
                  </v-col>
                </v-row>
              </v-card>

            </v-col>
          </v-row>

        </v-card>
      </v-dialog>

      <!-- EDIT USER DIALOG -->
      <v-dialog v-model="edit_user_dialog" fullscreen>
        <v-card class="card-size">
          <v-toolbar class="pr-5 mb-5" color="white" dense>
            <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="#b00d0d" dark variant="elevated" @click="edit_user_dialog = false">
              <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
            </v-btn>
          </v-toolbar>
          <v-divider class="divider-margin-dv"></v-divider>
          <v-row class="mt-0">
            <v-col cols="12" md="12">
              <v-card class="pa-3 mx-6">
                <v-icon>mdi-view-dashboard
                </v-icon>
                <v-label class="ml-6">UPDATE USER DETAILS</v-label>
              </v-card>
              <v-card class="pa-3 mx-6 mt-3">
                <v-row class="first_row ma-0 pa-0">
                  <v-col class="secondC textCenter" cols="12">
                    <div class="image card-h4-title" style="position: relative;">

                      <!--                      :src="user.user_image ? `http://127.0.0.1:8000/${user.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')"-->
                      <img
                          :src="user.user_image ? `http://sfa.backend.celata.org/SFA/public/${user.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')"
                          class="avatar-image"
                          style="
                          border-radius: 50%;
                          width: 120px;
                          height: 120px;
                          object-fit: cover;
                          display: block;
                        "
                      />

                      <v-icon
                          class="edit-icon"
                          color="white"
                          style="
                          position: absolute;
                          bottom: 12px;
                          right: 16px;
                          background-color: rgba(255, 0, 0, 0.9);
                          border-radius: 50%;
                          padding: 10px;
                          cursor: pointer;
                          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                          font-size: 24px;
                          z-index: 10;
                        "
                          @click="dialog_image_upload=true"
                      >
                        mdi-image-edit
                      </v-icon>
                    </div>

                    <div class="mt-6 ml-6">
                      <v-row>
                        <h3 class="dataCard">Full name: </h3>
                        <h3 class="dataCard text-red">&nbsp; {{ user.name }}</h3>
                      </v-row>
                      <v-row class="mt-6">
                        <h5>Mobile : &nbsp; {{ user.mobile_number.map(num => num.mobile_number).join(', ') }}</h5>
                      </v-row>
                      <v-row class="mt-6">
                        <v-chip class="mr-3 chip1clr" color="green" text-color="white" variant="outlined">
                          <v-icon color="green">mdi-check-circle-outline</v-icon>&nbsp;
                          USE TYPE - {{ user.user_type }}
                        </v-chip>
                      </v-row>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="justify-right align-right ml-6">
                      <v-btn class="red-background-text" size="x-large" variant="outlined" @click="updateUser">UPDATE
                        USER DATA
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>

                <v-divider class="my-3"></v-divider>
                <v-row class="pt-6">
                  <v-col class="px-6" cols="6">
                    <v-card class="pt-2 card-hight-fixed" flat>
                      <v-col cols="11">

                        <v-text-field
                            v-model="user.name"
                            class="mb-2"
                            label="User Full Name *"
                            required
                            variant="outlined"
                        ></v-text-field>

                        <v-text-field
                            v-model="user.user_name"
                            class="mb-2"
                            label="User Name *"
                            required
                            variant="outlined"
                        ></v-text-field>

                        <!-- <v-text-field
                          class="mb-2"
                          label="User Type *"
                          v-model="user.user_type"
                          variant="outlined"
                          required
                        ></v-text-field> -->

                        <!-- <v-select
                          v-model="user.user_type"
                          :rules="[v => !!v || 'User role is required']"
                          :items="userTypes"
                          class="mb-2"
                          label="User Type *"
                          outlined
                          placeholder="Select User Type Here"
                          variant="outlined"
                          @click="openDashBoardSeletDialog"
                      ></v-select> -->

                        <v-select
                            v-model="user.user_type"
                            :items="userTypes"
                            :rules="[v => !!v || 'User role is required']"
                            class="mb-2"
                            clearable
                            item-title="name"
                            item-value="id"
                            label="User Type *"
                            placeholder="Select User Type Here"
                            required
                            variant="outlined"
                            @update:modelValue="openDashBoardSeletDialog"
                        ></v-select>

                        <!-- <v-text-field
                          class="mb-2"
                          label="Province *"
                          v-model="user.province"
                          variant="outlined"
                          required
                        ></v-text-field>

                        <v-text-field
                          class="mb-2"
                          label="District *"
                          v-model="user.district"
                          variant="outlined"
                          required
                        ></v-text-field>

                        <v-text-field
                          class="mb-2"
                          label="DSD *"
                          v-model="user.dsd"
                          variant="outlined"
                          required
                        ></v-text-field>

                        <v-text-field
                          class="mb-2"
                          label="GND *"
                          v-model="user.gnd"
                          variant="outlined"
                          required
                        ></v-text-field> -->
                        <v-select
                            v-model="user.province"
                            :clearable="user.province !== null && user.province !== ''"
                            :items="provinces"
                            :loading="mProvinceLoading"
                            class="mb-2"
                            item-text="label"
                            item-value="value"
                            label="Province"
                            placeholder="Select province"
                            variant="outlined"
                            @update:modelValue="(value) => {
                        user.province = value;
                        if (!value) {
                          user.district = null;
                          user.dsd = null;
                          user.gnd = null;
                          districts = [];
                          dsdList = [];
                          gndList = [];
                        } else {
                          getAllDistrictAccProv(value);
                        }
                      }"
                        ></v-select>

                        <v-select
                            v-model="user.district"
                            :items="districts"
                            :loading="mDistrictLoading"
                            class="mb-2"
                            item-text="label"
                            item-value="value"
                            label="District"
                            placeholder="Select district"
                            variant="outlined"
                            @update:modelValue="getAllDsd"
                        ></v-select>

                        <v-select
                            v-model="user.dsd"
                            :items="dsdList"
                            :loading="mDSDLoading"
                            class="mb-2"
                            item-text="label"
                            item-value="value"
                            label="DSD *"
                            placeholder="Select DSD"
                            variant="outlined"
                            @update:modelValue="getAllGnd"
                        ></v-select>

                        <v-select
                            v-model="user.gnd"
                            :items="gndList"
                            :loading="mGNDLoading"
                            class="mb-2"
                            label="GND *"
                            placeholder="Select GND"
                            required
                            variant="outlined"
                        ></v-select>


                        <!-- <v-text-field
                          class="mb-2"
                          label="Password *"
                          type="password"
                          variant="outlined"
                          placeholder="Type password here"
                          v-model="user.password"
                          required
                          append-inner-icon="mdi-eye"
                        ></v-text-field> -->

                        <v-text-field
                            v-model="user.email"
                            class="mb-2"
                            label="Email Address *"
                            placeholder="Type Email Here"
                            required
                            variant="outlined"
                        ></v-text-field>

                        <!-- <v-text-field
                          class="mb-2"
                          label="Confirm Password *"
                          type="password"
                          variant="outlined"
                          placeholder="Confirm your password here"
                          v-model="user.confirmPassword"
                          required
                          append-inner-icon="mdi-eye"
                        ></v-text-field> -->
                      </v-col>
                    </v-card>
                  </v-col>
                  <v-col class="px-6" cols="6">
                    <v-card class="pt-2 card-hight-fixed" flat>
                      <v-col cols="11">

                        <v-text-field
                            v-model="user.registration_num"
                            class="mb-2"
                            label="Registration Number *"
                            placeholder="Type Mobile Number Here"
                            readonly
                            variant="outlined"
                        ></v-text-field>

                        <div v-for="(phoneNo, index) in user.mobile_number" :key="index">
                          <v-row>
                            <v-col cols="8">
                              <v-text-field
                                  v-model="phoneNo.mobile_number"
                                  :label="'Mobile Number ' + (index + 1)"
                                  :rules="phoneRules"
                                  variant="outlined"
                              ></v-text-field>
                            </v-col>
                            <v-col class="align-center justify-center" cols="auto">
                              <v-btn
                                  class="green-text"
                                  density="default"
                                  icon="mdi-plus-thick"
                                  variant="outlined"
                                  @click="addPhoneNo"
                              ></v-btn>
                            </v-col>
                            <v-col class="align-center justify-center" cols="auto">
                              <v-btn
                                  v-if="user.mobile_number.length > 1"
                                  class="red-text-btn"
                                  density="default"
                                  icon="mdi-minus-thick"
                                  variant="outlined"
                                  @click="removePhoneNo(index)"
                              ></v-btn>
                            </v-col>
                          </v-row>
                        </div>

                        <v-select
                            v-model="geofence"
                            :items="geoFences"
                            class="mb-2"
                            label="Select Geofence *"
                            multiple
                            placeholder="Select geofence access"
                            variant="outlined"
                        ></v-select>

                        <v-text-field
                            v-model="user.nic"
                            :rules="nicRules"
                            class="mb-2"
                            label="NIC *"
                            placeholder="Type NIC"
                            required
                            variant="outlined"
                        ></v-text-field>

                        <!-- <v-text-field
                          class="mb-2 mt-1"
                          label="Gender *"
                          v-model="user.gender"
                          variant="outlined"
                          required
                        ></v-text-field> -->
                        <v-select
                            v-model="user.gender"
                            :items="gender"
                            class="mb-2"
                            label="Gender *"
                            placeholder="Select Gender"
                            required
                            variant="outlined"
                        ></v-select>

                        <v-textarea
                            v-model="user.address"
                            class="mb-2"
                            label="Address *"
                            placeholder="Type Address Here"
                            required
                            rows="3"
                            variant="outlined"
                        ></v-textarea>
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>

            </v-col>
          </v-row>

        </v-card>
      </v-dialog>

      <v-dialog v-model="dashboardSelectDialog" max-height="750" max-width="500" persistent>
        <v-card class="pa-1">
          <v-row class="rowClr mb-1 mt-3 mx-3" dense>
            <v-col cols="12" md="9">
              <v-card-title v-if="this.premissionStatus !== 'viewPremission'">SET PERMISSIONS</v-card-title>
              <v-card-title v-if="this.premissionStatus == 'viewPremission'">PERMISSIONS</v-card-title>
            </v-col>
            <v-col class="d-flex justify-end pt-3 pr-3" cols="12" md="3">
              <v-btn icon size="x-small" @click="dashboardSelectDialogClose">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-3"></v-divider>

          <v-col cols="12" md="12">
            <h5 v-if="this.premissionStatus !== 'viewPremission'" class="card-two-title px-3 mt-6 mb-4">Setup Permissions</h5>
            <h5 v-if="this.premissionStatus == 'viewPremission'" class="card-two-title px-3 mt-6 mb-4">Permissions</h5>

            <!-- Select All and Cancel All -->
            <v-row class="px-3 mb-2">
              <v-btn v-if="this.premissionStatus !== 'viewPremission'" class="mr-2" color="primary" @click="selectAll">Select All</v-btn>
              <v-btn v-if="this.premissionStatus !== 'viewPremission'" color="secondary" @click="cancelAll">Cancel All</v-btn>
            </v-row>

            <v-card class="pt-2 mb-3 mx-1 mt-1 card-hight-fixed">
              <v-row v-for="(item, index) in permissionsItems" :key="item.id" class="align-center no-gutters">
                <v-row class="ml-3">
                  <v-col class="d-flex margin-up-down-two" cols="auto">
                    <v-switch
                        v-model="item.isOn"
                        :color="item.isOn ? 'red' : 'grey'"
                        class="mr-1"
                        @change="handleSwitchChange(item)"
                    ></v-switch>
                  </v-col>
                  <v-col class="d-flex" cols="auto">
                    <v-card-text :class="{ 'red-text': item.isOn }" class="ml-3 mr-1">{{
                        item.premission_dashboard
                      }}
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-col v-if="item.isOn" class="pl-10" cols="12">
                  <v-row class="margin-up-down">
                    <v-col class="d-flex" cols="auto">
                      <v-switch
                          v-model="item.permissions.read"
                          class="mr-1"
                          color="red"
                          @change="handlePermissionChange(item, 'read')"
                      ></v-switch>
                      <v-card-text class="mx-1">Read</v-card-text>
                    </v-col>
                    <v-col class="d-flex" cols="auto">
                      <v-switch
                          v-model="item.permissions.write"
                          class="mx-1"
                          color="red"
                          @change="handlePermissionChange(item, 'write')"
                      ></v-switch>
                      <v-card-text class="mr-1">Write</v-card-text>
                    </v-col>
                    <v-col class="d-flex" cols="auto">
                      <v-switch
                          v-model="item.permissions.delete"
                          class="mx-1"
                          color="red"
                          @change="handlePermissionChange(item, 'delete')"
                      ></v-switch>
                      <v-card-text class="mr-1">Delete</v-card-text>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider v-if="index < permissionsItems.length - 1"></v-divider>
              </v-row>
            </v-card>
          </v-col>

          <v-card>
            <v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="mr-1">
                <v-spacer></v-spacer>
                <v-btn class="bg-grey-lighten-2" variant="outlined" v-if="this.premissionStatus !== 'viewPremission'" @click="dashboardSelectDialog = false">CONFIRM
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-card>
      </v-dialog>


      <!--      <v-dialog v-model="dashboardSelectDialog"  max-height="750" max-width="500" persistent>-->

      <!--        <v-card class="pa-1">-->
      <!--          <v-row class="rowClr mb-1 mt-3 mx-3" dense>-->
      <!--            <v-col cols="12" md="9">-->
      <!--              <v-card-title>SET PREMISSION</v-card-title>-->
      <!--            </v-col>-->
      <!--            <v-col class="d-flex justify-end pt-3 pr-3" cols="12" md="3">-->
      <!--              <v-btn icon size="x-small" @click="dashboardSelectDialog = false">-->
      <!--                <v-icon>mdi-close</v-icon>-->
      <!--              </v-btn>-->
      <!--            </v-col>-->
      <!--          </v-row>-->
      <!--          <v-divider class="mt-3"></v-divider>-->

      <!--          <v-col cols="12" md="12">-->

      <!--            <h5 class="card-two-title px-3 mt-6 mb-4">Setup Permissions</h5>-->
      <!--            <v-card class="pt-2 mb-3 mx-1 mt-1 card-hight-fixed">-->
      <!--              <v-row v-for="(item, index) in permissionsItems" :key="item.id" class="align-center no-gutters">-->
      <!--                <v-row class="ml-3">-->
      <!--                  <v-col class="d-flex margin-up-down-two" cols="auto">-->
      <!--                    <v-switch-->
      <!--                        v-model="item.isOn"-->
      <!--                        :color="item.isOn ? 'red' : 'grey'"-->
      <!--                        class="mr-1"-->
      <!--                        @change="handleSwitchChange(item)"-->
      <!--                    ></v-switch>-->
      <!--                  </v-col>-->
      <!--                  <v-col class="d-flex" cols="auto">-->
      <!--                    <v-card-text :class="{ 'red-text': item.isOn }" class="ml-3 mr-1">{{ item.premission_dashboard }}</v-card-text>-->
      <!--                  </v-col>-->
      <!--                </v-row>-->
      <!--                <v-col v-if="item.isOn" class="pl-10" cols="12">-->
      <!--                  <v-row class="margin-up-down">-->
      <!--                    <v-col class="d-flex" cols="auto">-->
      <!--                      <v-switch-->
      <!--                          v-model="item.permissions.read"-->
      <!--                          class="mr-1"-->
      <!--                          color="red"-->
      <!--                          @change="handlePermissionChange(item, 'read')"-->
      <!--                      ></v-switch>-->
      <!--                      <v-card-text class="mx-1">Read</v-card-text>-->
      <!--                    </v-col>-->
      <!--                    <v-col class="d-flex" cols="auto">-->
      <!--                      <v-switch-->
      <!--                          v-model="item.permissions.write"-->
      <!--                          class="mx-1"-->
      <!--                          color="red"-->
      <!--                          @change="handlePermissionChange(item, 'write')"-->
      <!--                      ></v-switch>-->
      <!--                      <v-card-text class="mr-1">Write</v-card-text>-->
      <!--                    </v-col>-->
      <!--                    <v-col class="d-flex" cols="auto">-->
      <!--                      <v-switch-->
      <!--                          v-model="item.permissions.delete"-->
      <!--                          class="mx-1"-->
      <!--                          color="red"-->
      <!--                          @change="handlePermissionChange(item, 'delete')"-->
      <!--                      ></v-switch>-->
      <!--                      <v-card-text class="mr-1">Delete</v-card-text>-->
      <!--                    </v-col>-->
      <!--                  </v-row>-->
      <!--                </v-col>-->
      <!--                <v-divider v-if="index < permissionsItems.length - 1"></v-divider>-->
      <!--              </v-row>-->
      <!--            </v-card>-->
      <!--          </v-col>-->


      <!--          <v-card>-->

      <!--            <v-card-text>-->

      <!--              <v-divider></v-divider>-->
      <!--              <v-card-actions class="mr-1">-->
      <!--                <v-spacer></v-spacer>-->
      <!--                <v-btn class="bg-grey-lighten-2" variant="outlined" @click="dashboardSelectDialog = false">CONFIRM</v-btn>-->
      <!--              </v-card-actions>-->
      <!--            </v-card-text>-->
      <!--          </v-card>-->

      <!--        </v-card>-->

      <!--      </v-dialog>-->

      <!-- reset user password dialog -->
      <v-dialog v-model="reset_password_dialog" fullscreen>
        <v-card class="card-size">
          <v-toolbar class="pr-5 mb-5" color="white" dense>
            <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="#b00d0d" dark variant="elevated" @click="reset_password_dialog = false">
              <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
            </v-btn>
          </v-toolbar>
          <v-divider class="divider-margin-dv"></v-divider>
          <v-row class="mt-0">
            <v-col cols="12" md="12">
              <v-card class="pa-3 mx-6">
                <v-icon>mdi-view-dashboard
                </v-icon>
                <v-label class="ml-6">RESET PASSWORD</v-label>
              </v-card>
              <v-card class="pa-3 mx-6 mt-3">
                <v-row class="first_row ma-0 pa-0 ">
                  <v-col class="secondC textCenter" cols="12">

                    <div class="image card-h4-title">
                      <!-- <img :src="require('@/assets/ASSETS/PERSONS/person-3.jpg')" class="avatar-image"/> -->
                      <img
                          :src="user.user_image ? `http://sfa.backend.celata.org/SFA/public/${user.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')"
                          class="avatar-image"/>
                    </div>

                    <div class="mt-6 ml-6">
                      <v-row>
                        <h3 class="dataCard">Agent name: </h3>
                        <h3 class="dataCard text-red">&nbsp; {{ user.name }}</h3>
                      </v-row>
                      <v-row class="mt-6">
                        <h5>Mobile : &nbsp; {{ user.mobile_number.map(num => num.mobile_number).join(', ') }}</h5>
                      </v-row>
                      <v-row class="mt-6">
                        <v-chip class="mr-3 chip1clr" color="green" text-color="white" variant="outlined">
                          <v-icon color="green">mdi-check-circle-outline</v-icon>&nbsp;
                          USE TYPE - {{ user.user_type }}
                        </v-chip>
                      </v-row>
                    </div>
                    <v-spacer></v-spacer>

                  </v-col>
                </v-row>

                <v-divider class="my-3"></v-divider>
                <v-row class="pt-6">
                  <v-col class="px-6" cols="7">
                    <v-card class="card-hight-fixed" flat>

                      <v-tabs
                          v-model="tab"
                          grow
                      >
                        <v-tab :class="{ 'red-light-text': tab === 'one' }" value="one">GENERATE RESET LINK</v-tab>
                        <v-tab :class="{ 'red-light-text': tab === 'two' }" value="two">AUTO GENERATE PASSWORD</v-tab>
                        <v-tab :class="{ 'red-light-text': tab === 'three' }" value="three">MANUAL PASSWORD CHANGE
                        </v-tab>
                      </v-tabs>

                      <v-tabs-window v-model="tab">
                        <v-tabs-window-item class="mt-9" value="one">
                          <v-btn class="red-white-text" size="x-large" variant="outlined"
                                 @click="generateResetLink(user)">GENERATE & SEND RESET LINK
                          </v-btn>
                        </v-tabs-window-item>

                        <v-tabs-window-item class="mt-9" value="two">
                          <v-btn class="red-white-text" size="x-large" variant="outlined"
                                 @click="generateAndSendPassword(user)">GENERATE PASSWORD & SEND PASSWORD
                          </v-btn>
                        </v-tabs-window-item>

                        <v-tabs-window-item class="mt-9" value="three">
                          <v-text-field
                              v-model="manualPassword"
                              :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                              :rules="[
                          v => !!v || 'Password is required',
                          v => (v && v.length >= 8) || 'Password must be at least 8 characters',
                          v => /[A-Za-z]/.test(v) || 'Password must contain at least one letter',
                          v => /\d/.test(v) || 'Password must contain at least one number',
                        ]"
                              :type="showPassword ? 'text' : 'password'"
                              autocomplete="new-password"
                              class="mb-2"
                              label="Password *"
                              placeholder="Type password here"
                              required
                              variant="outlined"
                              @click:append-inner="togglePasswordVisibility"
                          ></v-text-field>
                          <v-text-field
                              v-model="manualConfirmPassword"
                              :append-inner-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                              :type="showConfirmPassword ? 'text' : 'password'"
                              autocomplete="new-password"
                              class="mb-2"
                              label="Confirm Password *"
                              placeholder="Confirm your password here"
                              required
                              variant="outlined"
                              @click:append-inner="toggleConfirmPasswordVisibility"
                          ></v-text-field>
                          <v-row class="margin-bottomn-fix pa-3 mb-2">
                            <v-btn class="red-background-text mr-3" variant="outlined"
                                   @click="updatePasswordRest(user)">
                              UPDATE
                            </v-btn>
                            <v-btn class="gray-background-text" variant="outlined"
                                   @click="reset_password_dialog = false">
                              CANCEL
                            </v-btn>
                          </v-row>
                        </v-tabs-window-item>

                      </v-tabs-window>
                    </v-card>

                  </v-col>
                  <v-col class="d-flex flex-column justify-end" cols="5">
                    <img
                        :src="require('@/assets/ASSETS/ILLUSTRATOR/reset_passwoard.png')"
                        class="custom-create-product-category"

                    />
                  </v-col>
                </v-row>
              </v-card>

            </v-col>
          </v-row>

        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_image_upload" max-width="500px">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="10">
                <span class="headline">Change Profile Image</span>
                <v-spacer></v-spacer>
              </v-col>
              <v-col cols="2">
                <v-btn icon @click="dialog_image_upload = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <div
                class="upload-area"
                style="border: 2px dashed #d3d3d3; border-radius: 8px; padding: 20px; text-align: center;"
                @drop.prevent="handleDrop"
                @dragover.prevent
            >
              <p>Drag your photo here <strong>OR</strong> <a href="#" @click.prevent="triggerFileInput">Browse from
                device</a></p>
              <v-icon size="48">mdi-file-image-outline</v-icon>
            </div>

            <input
                ref="fileInput"
                accept="image/*"
                style="display: none;"
                type="file"
                @change="handleImageUpload"
            />

            <!-- Image Preview -->
            <div v-if="previewImage" class="image-preview" style="text-align: center; margin-top: 20px;">
              <img :src="previewImage" alt="Image Preview"
                   style="max-width: 100%; max-height: 200px; border-radius: 8px;"/>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="uploadImage">Upload</v-btn>
            <v-btn text @click="dialog_image_upload = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


    </v-card>
    <!-- Snackbar to show notifications -->
    <v-snackbar
        v-model="snackAlert.snackbar"
        :color="snackAlert.snackColor"
        :right="snackAlert.snackBarRight"
        :top="snackAlert.snackBarTop"
        timeout="3000"
    >
      {{ snackAlert.snackMessage }}

    </v-snackbar>
  </v-card>
</template>

<script>
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// import _ from 'lodash';
import * as XLSX from "xlsx";
import {getPermissions} from "@/plugins/permissions";

export default {
  name: 'UserRoleList',
  data() {
    return {
      previewImage: null,
      imageBase64: null,
      premissionStatus:'',
      centerLoading: false,
      permissionsItems: [],
      userRoleID: '',
      dashboardSelectDialog: false,
      dialog_image_upload: false,
      selectedFile: null,
      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },
      // Dialog state
      delete_user_dialog: false,
      view_user_dialog: false,
      edit_user_dialog: false,
      reset_password_dialog: false,

      showPassword: false,
      showConfirmPassword: false,

      //reset password three options tabs
      tab: null,
      autoGenaratePassword: null,
      geoFences: ["Route Plan Geofence", "My Route Geofence", "Collection Geofence", "Sales Geofence", "Overall Tracking", "Route Plan", "Myroute"],
      nicRules: [
        v => !!v || 'NIC is required',
        v => /^[0-9]{9}[vVxX]$|^[0-9]{12}$/.test(v) || 'Must be a valid NIC number'
      ],
      search: '',
      headers: [
        {text: 'REG: NUMBER', value: 'registration_num', title: 'REG: NUMBER', sortable: true},
        {text: 'USER NAME', value: 'user_name', title: 'USER NAME', sortable: true},
        {text: 'USER TYPE', value: 'user_type', title: 'USER TYPE', sortable: false},
        {text: 'MOBILE', value: 'mobile_number', title: 'MOBILE', sortable: true},
        {text: 'NIC', value: 'nic', title: 'NIC', sortable: true},
        {text: 'ACTION', value: 'action', title: 'ACTION'}
      ],
      items: [],
      phoneRules: [
        v => !!v || 'Phone number is required',
        v => /^\+\d{1,3}\d{10}$/.test(v) || 'Must be a valid phone number with country code (e.g., +123123456789)',
      ],
      userTypes: [],
      gender: ['Male', 'Female'],

      userCatagory: '',
      userCatagaryItems: ['Web User', 'Mobile User'],

      itemsPerPage: 10,
      page: 1,
      totalPages: 1,

      userRole: {
        userType: '',

      },

      createdUserId: '',
      manualPassword: "",
      manualConfirmPassword: '',

      user: {},
      geofence: [],

      mDistrictLoading: false,
      mProvinceLoading: false,
      mDSDLoading: false,
      mGNDLoading: false,
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
      // user.mobile_number: [""],
      // user: {},
      deleteItemId: null, // Store the ID of the item to be deleted
      permissions: {},
    };
  },

  mounted() {
    const dashboardId = 4;
    this.permissions = getPermissions(dashboardId);
    console.log("Permissions:", this.permissions);

    this.getAllProvinces();
    this.getAllUserRoles();

  },
  created() {
    this.fetchDataintoTable();
  },
  computed: {
    // filteredItems() {
    //   return this.items.filter(item => {
    //     const matchesUserRole = !this.userRole.userType || item.user_type === this.userRole.userType;
    //     const matchesSearch = !this.search || item.user_name.toLowerCase().includes(this.search.toLowerCase());
    //     return matchesUserRole && matchesSearch;
    //   });
    // }
    filteredItems() {
      const searchLower = this.search ? this.search.toLowerCase() : '';

      return this.items.filter(item => {
        const itemUserType = item.user_type || '';
        const selectedUserTypes = this.userRole.userType || [];
        const itemUserCatagory = item.user_catagory?.category || ''; // Use optional chaining
        const selectedUserCatagory = this.userCatagory || [];

        const matchesUserRole =
          selectedUserTypes.length === 0 ||
          (Array.isArray(selectedUserTypes) && selectedUserTypes.includes(itemUserType));

        const matchesUserCatagory =
          selectedUserCatagory.length === 0 ||
          (Array.isArray(selectedUserCatagory) && selectedUserCatagory.includes(itemUserCatagory));

        const registrationNum = item.registration_num ? item.registration_num.toLowerCase() : '';
        const userName = item.user_name ? item.user_name.toLowerCase() : '';
        const userType = item.user_type ? item.user_type.toLowerCase() : '';
        const nic = item.nic ? item.nic.toLowerCase() : '';
        const catagory = item.user_catagory?.category ? item.user_catagory.category.toLowerCase() : ''; // Safe access

        // Check if the search term matches any of the fields
        const matchesSearch =
          !this.search ||
          userName.includes(searchLower) ||
          registrationNum.includes(searchLower) ||
          userType.includes(searchLower) ||
          catagory.includes(searchLower) ||
          nic.includes(searchLower);

        // Return true only if both filters match
        return matchesUserRole && matchesSearch && matchesUserCatagory;
      });
    }


  },
  methods: {

    validateForm() {
      // Validate form fields
      if (!this.manualPassword || this.manualPassword.length < 8) {
        this.showSnackbar("Password must be at least 8 characters long.", "error");
        return false;
      }
      if (!/[A-Za-z]/.test(this.manualPassword)) {
        this.showSnackbar("Password must contain at least one letter.", "error");
        return false;
      }
      if (!/\d/.test(this.manualPassword)) {
        this.showSnackbar("Password must contain at least one number.", "error");
        return false;
      }
      if (this.manualPassword !== this.manualConfirmPassword) {
        this.showSnackbar("Passwords do not match.", "error");
        return false;
      }
      return true;
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },

    handleSwitchChange(item) {
      if (!item.isOn) {
        // Reset the permissions when the switch is turned off
        item.permissions.read = false;
        item.permissions.write = false;
        item.permissions.delete = false;
      }
    },

    handlePermissionChange(item) {
      if (!item.permissions.read && !item.permissions.write) {
        item.permissions.delete = false;
      }
    },
    selectAll() {
      this.permissionsItems.forEach((item) => {
        item.isOn = true;
        item.permissions.read = true;
        item.permissions.write = true;
        item.permissions.delete = true;
      });
    },
    cancelAll() {
      this.permissionsItems.forEach((item) => {
        item.isOn = false;
        item.permissions.read = false;
        item.permissions.write = false;
        item.permissions.delete = false;
      });
    },

    getDashboardPermissions(item) {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`/api/getDashboard?userId=${userId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the Authorization header with the Bearer token
        }
      })
          .then(response => {
            const dashboards = response.data.data;
            const permissions = item; // The permissions array from the backend

            this.permissionsItems = dashboards.map(dashboard => {
              // Find the corresponding permission for this dashboard
              const matchingPermission = permissions.find(permission => permission.dashboard_id === dashboard.id);

              if (matchingPermission) {
                return {
                  id: dashboard.id,
                  premission_dashboard: dashboard.premission_dashboard,
                  isOn: true,
                  permissions: {
                    read: matchingPermission.read_premission === 1,
                    write: matchingPermission.write_premission === 1,
                    delete: matchingPermission.delete_premission === 1
                  }
                };
              } else {
                // Return default permission structure if no matching permission is found
                return {
                  id: dashboard.id,
                  premission_dashboard: dashboard.premission_dashboard,
                  isOn: false,
                  permissions: {read: false, write: false, delete: false}
                };
              }
            });

            console.log('Dashboard permissions:', this.permissionsItems);
          })
          .catch(error => {
            console.error('Error fetching dashboard permissions:', error);
          });
    },


    // Open DashBoard Seletec dialog
    openDashBoardSeletDialog(selectedId) {

      const selectedItem = this.userTypes.find(type => type.id === selectedId);
      if (selectedItem) {
        this.selectedRoleId = selectedItem.id;
        this.selectedRoleName = selectedItem.name;
        console.log('Selected ID:', selectedItem.id);
        console.log('Selected Name:', selectedItem.name);

        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        axios.post('/api/checkUserRole', {
          userId: userId,
          userType: selectedItem.name
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
            .then(response => {
              let usrRole = response.data.data;
              console.log('User role types:', usrRole);

              // Open dialog only if the role is 'Web User'
              if (usrRole === 'Web User') {
                this.dashboardSelectDialog = true;
              } else {
                this.dashboardSelectDialog = false;
              }
            })
            .catch(error => {
              console.error('Error fetching user roles:', error);
              this.dashboardSelectDialog = false; // Close dialog in case of error
            });
      }
      // this.dashboardSelectDialog = true;
      // }
      // this.dashboardSelectDialog = true;
    },

    openViewDashBoardSeletDialog(){
      this.premissionStatus = 'viewPremission'
      // this.dashboardSelectDialog = true;


        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        axios.post('/api/checkUserRole', {
          userId: userId,
          userType: this.user.user_type
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
            .then(response => {
              let usrRole = response.data.data;
              this.checkPremissionGeoFence = usrRole;
              console.log('User role types:', usrRole);

              // Open dialog only if the role is 'Web User'
              if (usrRole === 'Web User') {
                this.dashboardSelectDialog = true;
              } else {
                this.dashboardSelectDialog = false;
              }
            })
            .catch(error => {
              console.error('Error fetching user roles:', error);
              this.dashboardSelectDialog = false; // Close dialog in case of error
            });
      

    },

    viewuserdialogClose(){
      this.view_user_dialog = false;
      this.premissionStatus = null;
    },

    dashboardSelectDialogClose() {
      this.dashboardSelectDialog = false; 
      setTimeout(() => {
        this.premissionStatus = null; 
      }, 10000); 
    },

    triggerFileInput() {
      this.$refs.fileInput.click();  // Triggers file input dialog
    },
    // handleImageUpload(event) {
    //   const file = event.target.files[0];
    //   if (file) {
    //     this.selectedFile = file;
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //       this.user.user_image = e.target.result;  // Preview the uploaded image
    //     };
    //     reader.readAsDataURL(file);
    //   }
    // },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result; // Set the previewImage to the base64 data URL

          const fullBase64 = e.target.result; // Full Base64 string
          this.imageBase64 = fullBase64.split(',')[1]; // Extract only the data portion
          // this.user.user_image = this.imageBase64;
        };
        reader.readAsDataURL(file); // Convert the file to a base64 string
      }
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.handleImageUpload({target: {files: [file]}});
    },

    uploadImage() {
      if (this.imageBase64) {
        // Send the extracted Base64 string to the server
        console.log("Image Base64 string:", this.imageBase64);
        this.dialog_image_upload = false;
      } else {
        console.error("No image to upload");
      }
    },
    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },


    exportExcel() {
      // Prepare the data for Excel export
      const tableData = this.filteredItems.map((item) => ({
        'USER NAME': item.name,
        'REG NUM': item.registration_num,
        'TYPE': item.user_type,
        'MOBILE NUMBER': item.mobile_number
          ? item.mobile_number.map((numberObj) => numberObj.mobile_number).join(', ')
          : 'N/A',
        'USER CATAGARY':item.user_catagory.category,
        'EMAIL': item.email,
        'NIC': item.nic,
        'GENDER': item.gender,
        'ADDRESS': item.address,
        'PROVINCE': item.province,
        'DISTRICT': item.district,
        'DSD': item.dsd,
        'GND': item.gnd,
        'MY ROUTE PLAN':
          item.user_catagory.category !== 'Web User'
            ? item.my_route_geofence == 1
              ? 'Active'
              : 'Inactive'
            : null,
        'ROUTE PLAN':  item.user_catagory.category !== 'Web User'
            ? item.route_plan_geofence == 1
              ? 'Active'
              : 'Inactive'
            : null,
        'SALES GEOFENCE':item.user_catagory.category !== 'Web User'
            ? item.sales_geofence == 1
              ? 'Active'
              : 'Inactive'
            : null,
        'MY ROUTE TRACLING':item.user_catagory.category !== 'Web User'
            ? item.my_route_tracking == 1
              ? 'Active'
              : 'Inactive'
            : null,
        'ROUTE PLAN TRACLING':item.user_catagory.category !== 'Web User'
            ? item.route_plan_tracking == 1
              ? 'Active'
              : 'Inactive'
            : null,
        'OVERALL TRACLING':item.user_catagory.category !== 'Web User'
            ? item.overall_tracking == 1
              ? 'Active'
              : 'Inactive'
            : null,
        'COLLECTION GEOFENCE':item.user_catagory.category !== 'Web User'
            ? item.collection_geofence == 1
              ? 'Active'
              : 'Inactive'
            : null,
      }));

      // Create worksheet from JSON data
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Set custom column widths
      worksheet['!cols'] = [
        {wch: 20},
        {wch: 15},
        {wch: 10},
        {wch: 25},
        {wch: 25},
        {wch: 15},
        {wch: 10},
        {wch: 30},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 20},
        {wch: 15},
        {wch: 15},
        {wch: 20},
        
      ];

      // Freeze the header row
      worksheet['!freeze'] = {ySplit: 1};

      const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({r: 0, c: C});
        if (!worksheet[cellAddress]) continue;
        worksheet[cellAddress].s = {
          font: {bold: true},
          alignment: {horizontal: 'center'},
        };
      }

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'All Agents Reports');

      // Save the workbook as an Excel file
      XLSX.writeFile(workbook, 'All_Agents_Reports.xlsx');
    },

    exportPDF() {
      const doc = new jsPDF();

      // Set title with improved styling
      doc.setFontSize(16);
      doc.text('User Report', 14, 30);

      // Subtitle or additional information
      doc.setFontSize(10);
      doc.text('User report with geofence details', 14, 38);

      // Define the columns with better alignment
      const tableColumns = [
        'User Name', 'Reg Num', 'Type', 'Email', 'NIC', 'Gender',
        'Address', 'Province', 'District', 'DSD', 'GND',
        'MyRoute', 'RoutePlan', 'SalesGeofence', 'CollectionGeofence'
      ];

      // Prepare data for the table
      const tableData = this.filteredItems.map((item) => [
        item.name || '',
        item.registration_num || '',
        item.user_type || '',
        item.email || '',
        item.nic || '',
        item.gender || '',
        item.address || '',
        item.province || '',
        item.district|| '',
        item.dsd || '',
        item.gnd || '',
        item.user_catagory.category !== 'Web User'
            ? item.my_route_geofence == 1
              ? 'Active'
              : 'Inactive'
            : null,
        item.user_catagory.category !== 'Web User'
            ? item.route_plan_geofence == 1
              ? 'Active'
              : 'Inactive'
            : null,
        item.user_catagory.category !== 'Web User'
            ? item.sales_geofence == 1
              ? 'Active'
              : 'Inactive'
            : null,
        item.user_catagory.category !== 'Web User'
            ? item.collection_geofence == 1
              ? 'Active'
              : 'Inactive'
            : null,

        item.user_catagory.category !== 'Web User'
            ? item.my_route_geofence == 1
              ? 'Active'
              : 'Inactive'
            : null,
      ]);

      doc.autoTable({
        head: [tableColumns],
        body: tableData,
        startX: 35,
        theme: 'grid',
        headStyles: {
          fillColor: [40, 40, 200],
          textColor: [255, 255, 255],
          fontSize: 5,
          halign: 'center',
          valign: 'middle',
          cellPadding: 0.5,
        },
        bodyStyles: {
          fontSize: 5,
          textColor: [50, 50, 50],
          halign: 'center',
          valign: 'middle',
          cellPadding: 0.5,
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240],
        },
        columnStyles: {
          0: {cellWidth: 12},
          1: {cellWidth: 12},
          2: {cellWidth: 12},
          3: {cellWidth: 20},
          4: {cellWidth: 12},
          5: {cellWidth: 12},
          6: {cellWidth: 12},
          7: {cellWidth: 12},
        },
        margin: {top: 40}, // Adjust top margin for the table
      });

      // Add content below the table

      // Footer with date
      doc.setFontSize(10);
      doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 14, doc.internal.pageSize.height - 10);

      // Save the PDF
      doc.save('User Details.pdf');
    },


    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mProvinceLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'province'}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mProvinceLoading = false;
            }
          })
          .catch(() => {
            this.mProvinceLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.user.district = null;
      this.user.dsd = null;
      this.user.gnd = null;
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'district', value: province}, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'dsd', value: district}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', {type: 'gnd', value: dsd}, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGNDLoading = false;
            }
          })
          .catch(() => {
            this.mGNDLoading = false;
          });
    },

    async updateUser() {
      this.centerLoading = true;
      try {
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem('userId');

        if (!this.user.id) {
          console.error("User ID is missing");
          return;
        }

        // Form validation
        // if (this.$refs.form && this.$refs.form.validate()) {
        const formData = new FormData();
        formData.append("userId", this.user.id);
        formData.append("logUserId", userId);
        formData.append("userName", this.user.user_name);
        formData.append("fullName", this.user.name);

        // formData.append("userType", this.selectedRoleName);

        if (this.selectedRoleName !== undefined && this.selectedRoleName !== null) {
          formData.append('userType', this.selectedRoleName);
        } else {
          formData.append('userType', this.user.user_type);
        }


        console.log('user type fuck',this.selectedRoleName);
        formData.append("userRoleId", this.user.user_role_id);
        formData.append("province", this.user.province);
        formData.append("district", this.user.district);
        formData.append("dsd", this.user.dsd);
        formData.append("gnd", this.user.gnd);
        formData.append("password", this.user.password);
        formData.append("confirmPassword", this.user.confirmPassword);
        formData.append("emailAddress", this.user.email);
        formData.append("nic", this.user.nic);
        formData.append("registrationNumber", this.user.registration_num);
        formData.append("gender", this.user.gender);
        formData.append("address", this.user.address);
        formData.append("image", this.imageBase64);


        formData.append("routePlane", this.geofence.includes("Route Plan Geofence") ? 1 : 0);
        formData.append("myRoute", this.geofence.includes("My Route Geofence") ? 1 : 0);
        formData.append("collection", this.geofence.includes("Collection Geofence") ? 1 : 0);
        formData.append("sales", this.geofence.includes("Sales Geofence") ? 1 : 0);
        formData.append("OverallTracking", this.geofence.includes("Overall Tracking") ? 1 : 0);
        formData.append("RoutePlan", this.geofence.includes("Route Plan") ? 1 : 0);
        formData.append("Myroute", this.geofence.includes("Myroute") ? 1 : 0);

        // formData.append("mobileNumber", this.user.mobile_number);

        // let mobileNumbersArray = [];
        // if (Array.isArray(this.user.mobile_number)) {
        //   this.user.mobile_number.forEach((phoneNo) => {
        //     console.log('sasas',phoneNo.mobile_number);
        //    mobileNumbersArray.push(phoneNo.mobile_number);
        //    console.log('num',mobileNumbersArray);
        //   });
        // }
        // formData.append(`mobileNumber`, mobileNumbersArray);

        // Handle mobile numbers
        const mobileNumbersArray = this.user.mobile_number.map(phoneNo => phoneNo.mobile_number);
        // formData.append("mobileNumber", JSON.stringify(mobileNumbersArray));
        formData.append("mobileNumber", mobileNumbersArray);
        const dashboards = this.permissionsItems
            .filter(item => item.isOn) // Only include dashboards where the switch is ON
            .map(item => ({
              dashboardId: item.id, // Assuming item.id represents the dashboard ID
              readPremission: item.permissions.read,
              writePremission: item.permissions.write,
              deletePremission: item.permissions.delete,
            }));


        const payload = {
          userRoleId: this.userRoleID,
          accessDashboard: dashboards,
          userId,
        };


        const jsonPayload = JSON.stringify(payload);

        console.log('fuckingdata', jsonPayload);
        formData.append("accessDashboard", jsonPayload);

        // Append image if available
        if (this.user.image && this.user.image.length > 0) {
          const base64String = await this.convertToBase64(this.user.image[0]);
          formData.append("image", base64String);
        }
        console.log([...formData]);
        // API call
        const response = await axios.post(
            "/api/createdUserUpdate",
            formData,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
              }
            }
        );

        if (response.status === 201 || response.status === 200) {
          this.showSnackbar("User updated successfully", "success");
          this.edit_user_dialog = false;
          this.centerLoading = false;
          this.fetchDataintoTable();
        } else {
          this.showSnackbar(response.data.message || "Failed to update user", "error");
          this.centerLoading = false;
        }
        // } else {
        //   this.showSnackbar("Please fill all required fields", "warning");
        // }
      } catch (error) {
        console.error("Error updating user:", error);
        this.showSnackbar("An error occurred while updating user", "error");
        this.centerLoading = false;
      }
    },

    // Convert image to base64 string (if necessary)
    async convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    getAllUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post('/api/getUserRolesType', {
        userId: userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            let usrRole = response.data.data;
            this.userTypes = usrRole.map(item => {
              return {
                id: item.id,
                name: item.role_type
              };
            });
            console.log('User role types:', this.userTypes);
          })
          .catch(error => {
            console.error('Error fetching user roles:', error);
          });
    },

    fetchDataintoTable() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem("token");

      const formData = new FormData();
      formData.append("userId", userId);


      axios.post("/api/createdUserList", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => {
        //  this.items= response.data.data;
        // Sort the data by created_at in descending order (newest first)
        this.items = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        console.log('table data', this.items);

        // this.userCatagaryItems = _.uniq(_.map(this.items, (user) => user.user_catagory.category));
        // console.log('catagary',this.userCatagaryItems);


      })
          .catch((error) => {
            // if (error.response.status === 401) {

            // } else {

            // }

            console.log(error);

          });
    },


    backPageRoute() {
      this.$router.back();
    },

    viewItem(item) {
      this.user = item;
      this.view_user_dialog = true;
      console.log("View item:", item);
      this.permissionsItems = item.user_dashboards
      console.log('permission items', this.permissionsItems);
      this.getDashboardPermissions(item.user_dashboards);
    },
    editItem(item) {
      this.user = {...item};
      this.edit_user_dialog = true;
      console.log("Edit item:", item);
      this.permissionsItems = item.user_dashboards
      this.userRoleID = item.user_role_id;

      this.createdUserId = item.id;

      const selectedGeofences = [];

      if (item.route_plan_geofence === 1) {
        selectedGeofences.push("Route Plan Geofence");
      }
      if (item.my_route_geofence === 1) {
        selectedGeofences.push("My Route Geofence");
      }
      if (item.collection_geofence === 1) {
        selectedGeofences.push("Collection Geofence");
      }
      if (item.sales_geofence === 1) {
        selectedGeofences.push("Sales Geofence");
      }
      if (item.overall_tracking === 1) {
        selectedGeofences.push("Overall Tracking");
      }

      if (item.route_plan_tracking === 1) {
        selectedGeofences.push("Route Plan");
      }

      if (item.my_route_tracking === 1) {
        selectedGeofences.push("Myroute");
      }

      // Assign the selected geofences to the v-select model
      this.geofence = selectedGeofences;

      // console.log('array',this.geofence);


      this.getDashboardPermissions(item.user_dashboards);
    },

    lockItem(item) {
      console.log("Lock item:", item);
      this.user = item;
      this.reset_password_dialog = true;
    },
    async generateResetLink(user) {

      this.centerLoading = true;
      const emails = user.email;

      try {
        const response = await axios.post('/api/forgetPassword', {email: emails});
        if (response.status === 200) {
          this.showSnackbar("Reset Link successfully sent.Check user mail", "success");
          this.centerLoading = false;
          console.log("Reset link generated and sent to:", emails);
        } else {
          this.showSnackbar("Failed to send reset email. Please try again.", "error");
          console.error("Error generating reset link:", response.statusText);
          this.centerLoading = false;
        }
      } catch (error) {
        if (error.response) {
          const errorMsg = error.response.data.message || "Failed to send reset email.";
          this.showSnackbar(errorMsg, "error");
          this.centerLoading = false;
        } else {
          this.showSnackbar("An error occurred. Please try again later.", "error");
          this.centerLoading = false;
        }
      }
    },
    generateAndSendPassword(user) {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token')?.trim();
      const email = user.email;
      this.centerLoading = true;

      if (!token) {
        console.error("No token found. Please log in again.");
        return;
      }

      console.log("Token from localStorage:", token);
      console.log("User ID:", userId);
      console.log("Email:", email);

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/autogenaratePassword`,
          {
            userId: userId,
            email: email,
            token: token
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            if (response.status === 200) {
              console.log("Password generated and sent to:", email);
              this.centerLoading = false;
              this.snackAlert.snackbar = true;
              this.snackAlert.snackColor = "success";
              this.snackAlert.snackMessage = "Password generated and sent to user";
            } else {
              console.error("Error generating password:", response.statusText);
              this.centerLoading = false;
            }
          })
          .catch(error => {
            console.error("There was an error generating the password!", error);
            this.centerLoading = false;
            if (error.response && error.response.data) {
              console.error("Error details:", error.response.data);
              this.centerLoading = false;
            }
          });
    },
    confirmDelete(item) {
      this.delete_user_dialog = true;
      this.deleteItemId = item.id;
    },
    deleteItem() {
      this.centerLoading = true;
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      const userListId = this.deleteItemId; // Use the stored ID
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/createdUserdelete`, {
        userId: userId,
        userListId: userListId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.fetchDataintoTable(); // Refresh the user list
            this.delete_user_dialog = false;
            this.edit_user_dialog = false;
            this.view_user_dialog = false;
            this.centerLoading = false;
            this.showSnackbar("Deleted Successfully", "success");
          })
          .catch(error => {
            console.error('Error deleting users:', error);
            this.delete_user_dialog = false;
            this.edit_user_dialog = false;
            this.view_user_dialog = false;
            this.centerLoading = false;
            this.showSnackbar("Delete Failed", "error");
          });
    },
    homeReload() {
      window.location.href = '/dashboard';
    },
    // addPhoneNo() {
    //   this.user.mobile_number.push('');
    //   // this.user.mobile_number.push({ mobile_number: '' });
    // },
    // removePhoneNo(index) {
    //   // if (this.user.mobile_number.length > 1) {
    //     this.user.mobile_number.splice(index, 1);
    //   // }
    // },

    addPhoneNo() {
      this.user.mobile_number.push({mobile_number: ''});
    },
    removePhoneNo(index) {
      if (this.user.mobile_number.length > 1) {
        this.user.mobile_number.splice(index, 1);
      }
    },


    // generateResetLink(item){
    //   this.user = item;
    //   console.log("resentlink password item:", item);
    // },
    // generateAndSendPassword(item){
    //   this.user = item;
    //   console.log("autoGenarateLink password item:", item);
    // },
    updatePasswordRest(user) {
      console.log("update password item:", user);

      if (!this.validateForm()) {
        console.log("Form validation failed. Fix the errors and try again.");
        return;
      }

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token')?.trim();
      const email = user.email;
      this.centerLoading = true;

      if (!token) {
        console.error("No token found. Please log in again.");
        this.centerLoading = false;
        return;
      }

      // Check if password and confirm password match
      if (this.manualPassword !== this.manualConfirmPassword) {
        console.error("Password and Confirm Password do not match.");
        this.showSnackbar("Password and Confirm Password do not match.", 'error');
        this.centerLoading = false;
        return;
      }

      axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/api/manualEnterPassword`,
          {
            userId: userId,
            email: email,
            password: this.manualPassword,
            confirmPassword: this.manualConfirmPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          }
      )
          .then(response => {
            if (response.status === 200) {
              console.log("Password generated and sent to:", email);
              this.showSnackbar("Password updated successfully.", 'success');
              this.manualPassword = '';
              this.manualConfirmPassword = '';
              this.centerLoading = false;
              this.snackAlert.snackbar = true;
              this.snackAlert.snackColor = "success";
              this.snackAlert.snackMessage = "Password Updated";
            } else {
              console.error("Error generating password:", response.statusText);
              this.centerLoading = false;
              this.snackAlert.snackbar = true;
              this.snackAlert.snackColor = "error";
              this.snackAlert.snackMessage = "Password Updated failed";
            }
          })
          .catch(error => {
            console.error("There was an error generating the password!", error);
            this.centerLoading = false;
            if (error.response && error.response.data) {
              console.error("Error details:", error.response.data);
              this.showSnackbar(error.response.data.error || "An error occurred.", 'error');
              this.centerLoading = false;
              this.snackAlert.snackbar = true;
              this.snackAlert.snackColor = "error";
              this.snackAlert.snackMessage = "Password Updated failed";
            }
          });
    },

    createUserRole() {
      this.$router.push({path: '/create-user-role'});
    },
  }
};
</script>

<style scoped>
.centered-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-card-title {
  font-weight: bold;
}

.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: black;
}

.red-text {
  color: #b00d0d;
}

.red-light-text {
  background-color: #f1cccc;
  color: #b00d0d;
}

.red-white-text {
  background-color: #b00d0d;
  color: white;
}

.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}

.green-text {
  color: #057b0a;
  background-color: #aee8b1;
}

.gray-icon-button {
  color: gray;
  background-color: #e2e2e2;
}

.card-size {
  width: 100% !important;
  padding: 0;
  margin-bottom: 0;
  /* height: auto;
  overflow: scroll; */
}

.page-title {
  font-weight: bold;
}

.light-gray-text {
  color: #a09e9e !important;
}

.data-table {
  width: 100% !important;
  height: 100%;
}

.btn-border {
  border-block-end-color: #9a1313;
}

.tool-bar-style {
  display: flex;
  justify-content: end;
}

.divider-vertical {
  color: #3c3b3b !important;
}

.card-two-title {
  color: black;
}

.rowClr {
  background-color: #d9d9d9;
}

.textspace {
  margin-top: -20px;
}

.headline {
  font-size: 1.5rem;
  font-weight: bold;
}

.divider-margin-dv {
  margin-top: -20px;
}

.secondC {
  display: flex;
}

.textCenter {
  text-align: center;
}

.avatar-image {
  object-fit: cover;
  width: 100px;
  height: 100px;
  display: block;
}

.image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.dataCard {
  display: flex;
  justify-content: left;
}

.first_row {
  display: flex;
  justify-content: flex-end;
}

.chip1clr {
  background-color: #e4ffe5;
}

.mobile-number-item {
  padding: 0 !important;
}

.mobile-number-field {
  margin: 0 !important;
  width: 100%;
}

.custom-create-product-category {
  width: 400px;
  height: 400px;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}

.red-background-text {
  background-color: #f0bcbc;
  color: #BB0000;
}

.gray-background-text {
  background-color: rgb(202, 202, 202);
  color: black;
}

.viewbtn {
  font-size: 12px;
}

:deep(.dataTable) th, td {
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  text-align: center;
  overflow: hidden;
}

:deep(.dataTable) td, td {
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  overflow: hidden;
}

:deep(.dataTable) {
  border: white;
  overflow: hidden;
}

:deep(.dataTable ) tr, th:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}

:deep(.dataTable ) tr, td:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}

.leads-details-card {
  height: fit-content;
  padding-bottom: 20px;
  margin-bottom: 10px;
  overflow-x: hidden;
  overflow-y: scroll !important;
  max-height: 75vh;
}

.upload-area {
  transition: all 0.2s ease-in-out;
}

.upload-area:hover {
  background-color: #f1f1f1;
}
</style>
    